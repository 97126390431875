import styled from "styled-components";

export const Container = styled.div`
    min-height: 20rem;
    margin-top: 2rem;
    background-color: #FFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 6px;
    padding-bottom: 15px;

    .MuiTableContainer-root {
        width: auto;
        margin: 20px 20px;
        overflow-x: hidden;
        border: 1px solid #DEE3EA;
        border-radius: 5px;
    }

    .MuiMenuItem-root {
        font-family: 'Lato';
        align-items: center;
    } 

    .MuiIconButton-root {
        color: #0080FC;
    }

    .MuiTableHead-root {
        background-color: #F7F8FC;
        border-radius: 5px 5px 0px 0px;
    }

    .MuiPaper-elevation1 {
        box-shadow: none; 
    }

    .MuiTableCell-root {
        text-align: center;
        border-bottom: 1px solid #DEE3EA;
        width: 20%;
    }

    .MuiTableCell-head {
        font: normal normal bold 1rem Mulish;
        letter-spacing: 0px;
        color: #333333;
        text-transform: capitalize;
        opacity: 1;
    }

    .MuiTableCell-body {
        color: rgba(51, 51, 51, .7);
        font: 600 16px/30px Mulish;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCCCCC;
`;

export const Title = styled.h5`
   font: normal normal 800 1.1rem Mulish;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    padding-top: 11px;
    padding-right: 15px;
`;

export const Search = styled.div`
    display: flex;
    flex-direction: row;

    .input-group{
        .input-group-text{
            background-color: #FFFFFF;
            border-right: 0;
        }

        .form-control{
            border-left: 0;
        }
    }

`;

export const DivStatus = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DivName = styled.div`
    margin: auto;
    width: 70%;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DivFlex = styled.div`
    display: flex;
`;


