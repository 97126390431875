import styled from "styled-components";

export const Container = styled.div`
    width: fit-content;
    margin-left: 10px;
    font-size: 10px ;
    cursor: pointer;

    > img {
        width: 10;
    }
`;

