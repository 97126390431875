import TestConnectionImage from "../../../Assets/CommunIcons/test connection.svg";
import DelImage from "../../../Assets/CommunIcons/Delete this Number.svg";
import ConnectionError from "../../../Assets/icon/Connection Error.svg";
import NumberWithCampaign from "../../../Assets/CommunIcons/number with campaign.svg";
import {BtnRed, cancelBtn2, BtnBlue2} from "../../../Styles/buttons";
import {ModalBody, ModalFooter} from "reactstrap";
import {ButtonsDiv, CustomModal} from "./styles";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import React from "react";

const NumberModals = ({isOpen, toggle, deleteNumber, numberID, modalInfoController}) => {
    const {t} = useTranslation();
    const CancelBtn = cancelBtn2();
    const BlueBtn = BtnBlue2();
    const RedBtn = BtnRed();


    return (
        <CustomModal isOpen={isOpen} toggle={toggle}>
            <ModalBody>

                {modalInfoController === "delete" &&
                    <>
                        <img src={DelImage} alt="delete number"/>
                        <div className="TextDivs">
                            <h4>{t("words.delete")}</h4>
                            <h5>{t("words.delete_number_question")}</h5>
                        </div>
                    </>
                }

                {modalInfoController === "test_connection" &&
                    <>
                        <img src={TestConnectionImage} alt="test connection"/>
                        <div className="TextDivs">
                            <h4>{t("words.send_msg_success")}</h4>
                            <h5>{t("words.test_successful")}</h5>
                        </div>
                    </>
                }

                {modalInfoController === "connection_error" &&
                    <>
                        <img src={ConnectionError} alt="connection error"/>
                        <div className="TextDivs">
                            <h4>{t("words.disconnected")}</h4>
                            <h5>{t("words.test_failed")}</h5>
                        </div>
                    </>
                }

                {modalInfoController === "campaign_exists" &&
                    <>
                        <img src={NumberWithCampaign} alt="connection error"/>
                        <div className="TextDivs">
                            <h4>{t("words.number_with_campaign")}</h4>
                            <h5>{t("words.cant_delete")}</h5>
                        </div>
                    </>
                }

                {modalInfoController === "operators_sectors" &&
                    <>
                        <img src={NumberWithCampaign} alt="connection error"/>
                        <div className="TextDivs">
                            <h5>{t("words.number_with_sectors")}</h5>
                        </div>
                    </>
                }

            </ModalBody>
            <ModalFooter>
                <ButtonsDiv>
                    {modalInfoController === "delete" ?
                        <>
                            <Button classes={{root: CancelBtn.root}} onClick={toggle}>{t("words.cancel")}</Button>
                            <Button classes={{root: RedBtn.root}}
                                    onClick={() => deleteNumber(numberID)}>{t("words.delete")}</Button>
                        </>
                        :
                        <>
                            <Button classes={{root: BlueBtn.root}} onClick={toggle}>{t("words.close")}</Button>
                        </>
                    }

                </ButtonsDiv>
            </ModalFooter>
        </CustomModal>
    );
};

export default NumberModals;