import React from "react";
import WS from "../../../ws";
import axios_base from "../../../axios_base";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {Container} from "./styled";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#0294FF",
    },
}));

const Login = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();

    const {token} = useParams();

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            try {
                const search = new URLSearchParams(window.location.search);
                const redir = search.get("redir");

                localStorage.setItem("primaryToken", token);
                const {data: auth} = await axios_base.post("/user/login", {token});

                localStorage.setItem("token", auth.token);

                WS.init(auth.token);


                const {data: numbers} = await axios({
                    method: "get",
                    url: `${process.env.REACT_APP_LINK_API}/number`,
                    headers: {
                        "Authorization": `Bearer ${auth.token}`,
                    },
                });
                if (redir) {
                    window.open(`/${redir}`, "_self");
                } else {
                    window.open(numbers.length > 0 ? "/campaigns/dashboard" : "/authenticate", "_self");
                }
            } catch (error) {
                setError(error);
            }
        })();
    }, [token, origin]);

    return (
        <Container>
            {error
                ? <Alert variant="filled" severity="error">
                    {t("something_wrong")}
                </Alert>
                : <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
        </Container>
    );
};

export default Login;
