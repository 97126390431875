import styled from "styled-components";
import {Modal} from "reactstrap";

export const ArchiveModal = styled(Modal)`
    .modal-content {
        width: 37.5rem;
        height: 12.5rem;
        background-color: #FFFFFF;
        border: 0px;
    }

    .modal-footer {
        border-top: 0px;
    }
    
    .modal-header{
        border-bottom: 0;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        > h5 {
            font-family: 'Mulish';
            font-weight: bold;
            font-size: 16px;
            color: #333333;
        }
    }
`;

export const DeleteModal = styled(Modal)`
    .modal-content {
        width: 37.5rem;
        height: 22rem;
        background-color: #FFFFFF;
        border: 0px;
    }

    .modal-footer {
        border-top: 0px;
    }
    
    .modal-header{
        border-bottom: 0;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        font-family: 'Mulish';
        font-weight: bold;

        > h5 {
            font-family: 'Mulish';
            font-weight: bold;
            font-size: 16px;
            color: #333333;
        }
    }
`;