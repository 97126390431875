import {createMessage, deleteMessage, getMessageDetail, updateMessage} from "../../../Services/campaignFunctions";
import TyperecordDesactive from "../../../Assets/attendance/TyperecordDesactive.svg";
import ImageActive from "../../../Assets/attendance/Image type  Active - Hover.svg";
import TyperecordActive from "../../../Assets/attendance/TyperecordActive.svg";
import TrashSvh from "../../../Assets/attendance/Trash  Active - Hover.svg";
import VideoDesactive from "../../../Assets/attendance/VideoDesactive.svg";
import MessageDesactive from "../../../Assets/attendance/Message type.svg";
import TyperecordWith from "../../../Assets/attendance/TyperecordWith.svg";
import {BtnBlue2, BtnRed, cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import MessageActive from "../../../Assets/attendance/MessageActive.svg";
import {Input, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AudioDesactive from "../../../Assets/attendance/Audio type.svg";
import Trash from "../../../Assets/attendance/Trash  Active - Hover.svg";
import ImageDesactive from "../../../Assets/attendance/Image type.svg";
import AudioActive from "../../../Assets/attendance/AudioActive.svg";
import Selectvideo from "../../../Assets/attendance/Selectvideo.svg";
import Selectimage from "../../../Assets/attendance/Selectimage.svg";
import VideoActive from "../../../Assets/attendance/VideoActive.svg";
import AlertNotify from "../../../Common/Components/Alert";
import SoundWave from "../../../Animations/SoundWaves";
import {useTranslation} from "react-i18next";
import StopIcon from "@material-ui/icons/Stop";
import MessageEmoji from "../MessageEmoji";
import {Button} from "@material-ui/core";
import ReactDOM from "react-dom";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/eu";
import "dayjs/locale/es";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ne";
import "dayjs/locale/pl";
import React from "react";
import {
    CustomModal,
    Label,
    OutsideDiv,
    InsideDiv,
    MsgOptionsDiv,
    IconDiv,
    RegularDiv,
    StyledDiv,
    StyledTextArea,
    MediaSelectDiv,
    StartRecordDiv,
    FileLabel,
    DeleteBtnDiv,
    RecordingDiv,
    FileLabelVideo,
} from "./styles";

const MessageModal = ({buttonLabel, campaignID, getMessages, origin, messageID, groups}) => {

    const [dateFormaState, setDateFormatState] = React.useState();

    const changeDataFormat = () => {
        setDateFormatState(localStorage.getItem("i18nextLng"));
    };

    React.useEffect(() => {
        changeDataFormat();
    }, [localStorage.getItem("i18nextLng")]);

    React.useEffect(() => {
        changeDataFormat();
    }, []);

    dayjs.locale(dateFormaState);
    dayjs.extend(LocalizedFormat);

    const [msgCreationError, setMsgCreationError] = React.useState(false);
    const [messageStatus, setMessageStatus] = React.useState(null);
    const [backgroudImage, setBackgroundImage] = React.useState();
    const [creatingInfo, setCreatingInfo] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [messageType, setMessageType] = React.useState("text");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [recordedAudio, setRecordedAudio] = React.useState();
    const [mediaRecorder, setMediaRecorder] = React.useState();
    const [emptyError, setEmptyError] = React.useState(false);
    const [recording, setRecording] = React.useState(false);
    const [sizeError, setSizeError] = React.useState(false);
    const [messageName, setMessageName] = React.useState();
    const [messageDate, setMessageDate] = React.useState();
    const [messageTime, setMessageTime] = React.useState();
    const [permission, setpermission] = React.useState();
    const [modal, setModal] = React.useState(false);
    const [message, setMessage] = React.useState();
    const [audio, setAudio] = React.useState();
    const [videoUrl, setVideoUrl] = React.useState();

    const BlueBtn = BtnBlue2();
    const BtnCancel = cancelBtn2();
    const BtnDel = BtnRed();
    const BtnSave = saveBtn2();
    const {t} = useTranslation();

    const toggleModal = () => {
        if (!modal) {
            setMsgCreationError(false);
            setSuccessAlert(false);
            setCreatingInfo(false);
            setEmptyError(false);
            setSizeError(false);
            if (origin === "timeline") {
                getMsgDetail();
            }
        }
        setModal(!modal);
        resetStates();
    };

    const toggleDeleteModal = () => {
        if (!deleteModal) {
            setMsgCreationError(false);
            setSuccessAlert(false);
            setCreatingInfo(false);
            setEmptyError(false);
            setSizeError(false);
        }
        setDeleteModal(!deleteModal);
    };

    const resetStates = () => {
        setMessage();
        setMessageType("text");
        setBackgroundImage();
        setRecording(false);
        setMessageName("");
        setMessageDate("");
        setMessageTime("");
        setRecordedAudio();
        setAudio();
    };

    const resetMessageContent = () => {
        setBackgroundImage("");
        setRecording(false);
        setRecordedAudio();
        setAudio();
        setMessage();
    };

    const createMsg = async (messageName, message, messageDate, messageTime, messageType) => {
        if (!messageName || !message || !messageDate || !messageTime || !messageType) {
            setEmptyError(true);
        } else {
            setCreatingInfo(true);
            if (messageType === "recorderAudio") {
                messageType = "audio";
            }
            try {
                let date = new Date(messageDate + " " + messageTime);
                let dateFormated = date.toISOString();
                let data = new FormData();
                data.append("name", messageName);
                data.append("send_at", dateFormated);
                data.append("content_type", messageType);
                data.append("content", message);
                await createMessage(campaignID, data);
                setCreatingInfo(false);
                setSuccessAlert(true);
                resetStates();
                setTimeout(() => {
                    if (getMessages) getMessages(campaignID);
                    setModal(!modal);
                }, 1000);
            } catch (error) {
                console.log(error, "error at message creation");
                setMsgCreationError(true);
                setCreatingInfo(false);
            }
        }
    };

    const updateMsg = async (messageName, message, messageDate, messageTime, messageType) => {
        if (!messageName || !message || !messageDate || !messageTime || !messageType) {
            setEmptyError(true);
        } else {
            setCreatingInfo(true);
            if (messageType === "recorderAudio") {
                messageType = "audio";
            }
            try {
                let date = new Date(messageDate + " " + messageTime);
                let dateFormated = date.toISOString();
                let data = new FormData();
                data.append("name", messageName);
                data.append("send_at", dateFormated);
                data.append("content_type", messageType);
                data.append("content", message);
                await updateMessage(campaignID, messageID, data);
                setCreatingInfo(false);
                setSuccessAlert(true);
                resetStates();
                if (getMessages) getMessages(campaignID);
                setTimeout(() => {
                    setModal(!modal);
                }, 1000);
            } catch (error) {
                console.log(error, "error at message edition");
                setMsgCreationError(true);
                setCreatingInfo(false);
            }
        }
    };

    const uploadImg = (event) => {
        let roundTo = null;
        let converted = event?.target?.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        setMessage();
        if (roundTo > 12) {
            event.target.value = null;
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        } else {
            try {
                setMessage(event?.target?.files[0]);
                setBackgroundImage(URL?.createObjectURL(event?.target?.files[0]));
            } catch (error) {
                return;
            }
        }
    };

    const uploadVideo = (event) => {
        var roundTo = null;
        var converted = event?.target?.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo > 12) {
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        } else {
            try {
                setMessage(event.target?.files[0]);
                setVideoUrl(URL.createObjectURL(event?.target?.files[0]));
            } catch (error) {
                return;
            }
        }
    };

    const startRecorder = () => {
        setRecording(true);
        if (permission === "denied") {
            setRecording(false);
            alert(`${t("words.erro_microphone_use")}`);
        } else {
            mediaRecorder.start();
            ReactDOM.render(<Button onClick={stopRecorder} variant="contained" startIcon={<StopIcon
                fontSize="large"/>}>{t("words.stop")}</Button>, document.getElementById("control"));
            ReactDOM.render(<SoundWave/>, document.getElementById("audioControls"));
        }
    };
    const stopRecorder = () => {
        if (permission === "denied") {
            alert(`${t("words.erro_microphone_use")}`);
        } else {
            if (mediaRecorder.state !== "recording") {
                return;
            } else {
                mediaRecorder.stop();
                ReactDOM.render(<div></div>, document.getElementById("control"));
                ReactDOM.render(<div></div>, document.getElementById("audioControls"));
            }
        }

    };
    const cancelRecordAudio = () => {
        setRecording(false);
        setMessage();
        setRecordedAudio();
    };

    const uploadAudio = (event) => {
        var roundTo = null;
        var converted = event.target.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo > 12) {
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        } else {
            setMessage(event.target?.files[0]);
            setAudio(URL.createObjectURL(event.target.files[0]));
        }
    };
    const cancelAudio = () => {
        setAudio();
        setMessage();
    };

    const getMsgDetail = async () => {
        const details = await getMessageDetail(campaignID, messageID);
        setMessageType(details.type);
        setMessageName(details.name);
        setMessageDate(dayjs(details.send_at).format("YYYY-MM-DD"));
        setMessageTime(dayjs(details.send_at).format("HH:mm"));
        setMessage(details.type === `text` ? details.content : `${process.env.REACT_APP_LINK_API}/storage/${details?.content}`);
        details.type === "image" && setBackgroundImage(`${process.env.REACT_APP_LINK_API}/storage/${details?.content}`);
        details.type === "video" && setVideoUrl(`${process.env.REACT_APP_LINK_API}/storage/${details?.content}`);
        details.type === "audio" && setAudio(`${process.env.REACT_APP_LINK_API}/storage/${details?.content}`);
        const status = details.groups?.some(g => g.status === "failed")
            ? "failed" : details.status;
        setMessageStatus(status);
    };

    const deleteMsg = async messageID => {
        if (messageStatus === "pending" || messageStatus === "failed") {
            await deleteMessage(campaignID, messageID);
            setSuccessAlert(true);
        }
        setTimeout(() => {
            if (getMessages) getMessages(campaignID);
        }, 1500);
    };

    React.useEffect(() => {
        let isWaiting = true;
        (async () => {
            try {
                const stream = await navigator?.mediaDevices?.getUserMedia({audio: true});
                if (!isWaiting) return;

                setpermission("allowed");
                setMediaRecorder(new MediaRecorder(stream));
            } catch (err) {
                if (!isWaiting) return;

                setpermission("denied");
                console.log(err);
            }
        })();
        return () => isWaiting = false;
    }, []);

    React.useEffect(() => {
        if (!mediaRecorder) return;

        let chunks = [];
        mediaRecorder.ondataavailable = data => {
            chunks.push(data.data);
        };
        mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, {type: "audio/mp3; codecs=opus"});
            setMessage(blob);
            const reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const audio = document.createElement("audio");
                audio.src = reader.result;
                audio.controls = true;
                setRecordedAudio(reader.result);
            };
        };
    }, [mediaRecorder]);

    return (
        <>
            <div onClick={toggleModal}>{buttonLabel}</div>

            <CustomModal isOpen={modal} toggle={toggleModal}>
                <ModalHeader>{t("words.message")}</ModalHeader>
                <ModalBody>
                    <Label>{t("words.message_name")}</Label>
                    <Input type="text" onChange={(e) => setMessageName(e.target.value)} value={messageName}/>

                    <Label style={{marginTop: "1.5rem"}}>{t("words.date_sending")}</Label>
                    <OutsideDiv style={{display: "flex"}}>
                        <InsideDiv>
                            <Label>{t("words.date")}</Label>
                            <Input onChange={(e) => setMessageDate(e.target.value)} value={messageDate} type="date"
                                   name="date" required/>
                        </InsideDiv>
                        <InsideDiv>
                            <Label>{t("words.hour")}</Label>
                            <Input onChange={(e) => setMessageTime(e.target.value)} value={messageTime} type="time"
                                   name="time" required/>
                        </InsideDiv>
                    </OutsideDiv>

                    <Label style={{marginTop: "1.5rem"}}>{t("words.message_type")}</Label>
                    <OutsideDiv>
                        <div style={{padding: "10px"}}>
                            <MsgOptionsDiv>
                                <IconDiv onClick={() => {
                                    setMessageType("text");
                                    resetMessageContent();
                                }}>
                                    <img src={messageType !== "text" ? MessageDesactive : MessageActive} alt="text"/>
                                    {messageType === "text" ? <StyledDiv/> : <RegularDiv/>}
                                </IconDiv>
                                <IconDiv onClick={() => {
                                    setMessageType("image");
                                    resetMessageContent();
                                }}>
                                    <img src={messageType !== "image" ? ImageDesactive : ImageActive} alt="img"/>
                                    {messageType === "image" ? <StyledDiv/> : <RegularDiv/>}
                                </IconDiv>
                                <IconDiv onClick={() => {
                                    setMessageType("video");
                                    resetMessageContent();
                                }}>
                                    <img src={messageType !== "video" ? VideoDesactive : VideoActive} alt="video"/>
                                    {messageType === "video" ? <StyledDiv/> : <RegularDiv/>}
                                </IconDiv>
                                <IconDiv onClick={() => {
                                    setMessageType("recorderAudio");
                                    resetMessageContent();
                                }}>
                                    <img src={messageType !== "recorderAudio" ? TyperecordDesactive : TyperecordActive}
                                         alt="auto"/>
                                    {messageType === "recorderAudio" ? <StyledDiv/> : <RegularDiv/>}
                                </IconDiv>
                                <IconDiv onClick={() => {
                                    setMessageType("audio");
                                    resetMessageContent();
                                }}>
                                    <img src={messageType !== "audio" ? AudioDesactive : AudioActive} alt="auto"/>
                                    {messageType === "audio" ? <StyledDiv/> : <RegularDiv/>}
                                </IconDiv>
                            </MsgOptionsDiv>
                        </div>
                        <div style={{padding: "15px"}}>
                            {messageType === "text" &&
                                <>
                                    <StyledTextArea id="text_mensagem" placeholder={t("words.message")} rows="6"
                                                    onChange={e => setMessage(e.target.value)} value={message}/>
                                    <MessageEmoji onChange={e => setMessage(e)}/>
                                </>
                            }
                            {messageType === "image" &&
                                <div>
                                    <MediaSelectDiv>
                                        <FileLabel>
                                            {!message ? <img src={Selectimage} alt="select image"
                                                             style={{paddingTop: "20px"}}/> : null}
                                            {!message ? <h6 htmlFor="fileImage">{t("words.selct_img")}</h6> :
                                                <div style={{display: "flex"}}>
                                                    <div style={{width: "30%"}}><label htmlFor="fileImage"></label>
                                                    </div>
                                                    <div style={{flex: "1"}}><img src={backgroudImage} width={280}
                                                                                  height={165}
                                                                                  style={{borderRadius: "6px"}}
                                                                                  alt="select img"/></div>
                                                    <div style={{width: "20%"}}><label htmlFor="fileImage"></label>
                                                    </div>
                                                    <div style={{width: "10%", padding: "1%"}}><img alt="" src={Trash}
                                                                                                    onClick={resetMessageContent}/>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                <input onChange={uploadImg} accept="image/*"
                                                       style={{opacity: "0", width: "100%", height: "50%"}}
                                                       id="fileImage" type="file"/>
                                                {!message && <small>{t("words.warning_12mb")}</small>}
                                            </div>

                                        </FileLabel>
                                    </MediaSelectDiv>
                                </div>
                            }
                            {messageType === "video" &&
                                <div>
                                    <MediaSelectDiv>
                                        <FileLabelVideo>
                                            {!message ? <img src={Selectvideo} alt="select video"
                                                             style={{paddingTop: "20px"}}/> : null}
                                            {!message ? <h6 htmlFor="fileVideo">{t("words.selct_video")}</h6> :
                                                <div style={{display: "flex"}}>
                                                    <div style={{width: "30%"}}><label htmlFor="fileVideo"></label>
                                                    </div>
                                                    <div style={{flex: "1"}}>
                                                        <video id="video" width={290} height={165}
                                                               style={{borderRadius: "6px"}} src={videoUrl}
                                                               className="withBackground" controls/>
                                                    </div>
                                                    <div style={{width: "20%"}}><label htmlFor="fileVideo"></label>
                                                    </div>
                                                    <div style={{width: "10%", padding: "1%"}}><img alt="" src={Trash}
                                                                                                    onClick={resetMessageContent}/>
                                                    </div>
                                                </div>}
                                            <input onInput={uploadVideo} accept="video/mp4"
                                                   style={{opacity: "0", width: "100%", height: "5px"}} id="fileVideo"
                                                   type="file"/>
                                            {!message && <small>{t("words.warning_12mb")}</small>}

                                        </FileLabelVideo>

                                    </MediaSelectDiv>
                                </div>
                            }
                            {messageType === "recorderAudio" &&

                                <div>
                                    <MediaSelectDiv>
                                        {!recordedAudio &&
                                            <>
                                                {!recording &&
                                                    <StartRecordDiv>
                                                        <h6>{t("words.click_recorder")}</h6>
                                                        <Button onClick={startRecorder}
                                                                classes={{root: BlueBtn.root, label: BlueBtn.label}}>
                                                            <img src={TyperecordWith} alt="audio"
                                                                 style={{marginRight: "10px", width: "16px"}}/>
                                                            {t("words.start")}
                                                        </Button>
                                                        <div style={{paddingTop: "10px"}}>
                                                            <small>{t("words.warning_12mb")}</small>
                                                        </div>
                                                    </StartRecordDiv>
                                                }
                                                <RecordingDiv>
                                                    <div id="control"></div>
                                                    <div id="audioControls"></div>
                                                </RecordingDiv>
                                            </>
                                        }
                                        {recordedAudio &&
                                            <div>
                                                <DeleteBtnDiv>
                                                    <img src={TrashSvh} alt="trash" onClick={cancelRecordAudio}/>
                                                </DeleteBtnDiv>
                                                <audio style={{marginTop: "5px"}} controls src={recordedAudio}></audio>
                                            </div>
                                        }
                                    </MediaSelectDiv>
                                </div>
                            }
                            {messageType === "audio" &&
                                <div>
                                    <MediaSelectDiv>
                                        {!message ?
                                            <FileLabel>
                                                <img src={AudioActive} alt="select audio"/>
                                                <h6 htmlFor="fileAudio"> {t("words.select_audio")}</h6>
                                                <div style={{paddingTop: "10px"}}>
                                                    <small>{t("words.warning_12mb")}</small>
                                                </div>
                                                <input onChange={uploadAudio} accept="audio/mp3" style={{
                                                    opacity: "0",
                                                    width: "100%",
                                                    height: "60%",
                                                    cursor: "pointer",
                                                }} id="fileAudio" type="file"/>
                                            </FileLabel>

                                            :
                                            <div>
                                                <DeleteBtnDiv>
                                                    <img src={TrashSvh} alt="trash" onClick={cancelAudio}/>
                                                </DeleteBtnDiv>
                                                <audio style={{marginTop: "5px"}} controls src={audio}/>
                                            </div>
                                        }
                                    </MediaSelectDiv>
                                </div>
                            }
                        </div>
                    </OutsideDiv>


                </ModalBody>
                {!messageStatus &&
                    <ModalFooter>
                        <div>
                            <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleModal}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            {!creatingInfo ?
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        onClick={() => createMsg(messageName, message, messageDate, messageTime, messageType)}
                                >
                                    {t("words.save")}
                                </Button>
                                :
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        disabled
                                >
                                    {t("words.save")}
                                </Button>
                            }
                        </div>
                    </ModalFooter>
                }
                {messageStatus === "failed" &&
                    <ModalFooter>
                        <div>
                            <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleModal}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            {!creatingInfo ?
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        onClick={() => updateMsg(messageName, message, messageDate, messageTime, messageType)}
                                >
                                    {t("words.resend")}
                                </Button>
                                :
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        disabled
                                >
                                    {t("words.resend")}
                                </Button>
                            }
                        </div>
                    </ModalFooter>
                }

                {messageStatus === "pending" &&
                    <ModalFooter>
                        <div>
                            <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleModal}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            <Button classes={{root: BtnDel.root, label: BtnDel.label}} onClick={toggleDeleteModal}>
                                {t("words.delete")}
                            </Button>
                        </div>
                        <div>
                            {!creatingInfo ?
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        onClick={() => updateMsg(messageName, message, messageDate, messageTime, messageType)}
                                >
                                    {t("words.edit")}
                                </Button>
                                :
                                <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                        disabled
                                >
                                    {t("words.edit")}
                                </Button>
                            }
                        </div>
                    </ModalFooter>
                }

                {messageStatus === "completed" &&
                    <ModalFooter>
                        <div>
                            <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleModal}>
                                {t("words.close")}
                            </Button>
                        </div>
                    </ModalFooter>
                }

                {msgCreationError === true &&
                    <AlertNotify type="error" time={6000} message={t("words.unable_create_message")} state={true}/>}
                {emptyError === true &&
                    <AlertNotify type="error" time={6000} message={t("words.fields_empty")} state={true}/>}
                {creatingInfo === true && <AlertNotify type="info" message={t("words.creating")} state={true}/>}
                {successAlert === true &&
                    <AlertNotify type="success" time={3000} message={t("words.done")} state={true}/>}
                {sizeError !== false &&
                    <AlertNotify type="error" time={6000} message={t("words.larger_size_alert")} state={true}/>}
            </CustomModal>

            <CustomModal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader>{t("words.delete")}</ModalHeader>
                <Label>{t("words.delete_message_question")}</Label>
                <ModalFooter>
                    <div>
                        <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleDeleteModal}>
                            {t("words.cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button classes={{root: BtnDel.root, label: BtnDel.label}}
                                onClick={() => deleteMsg(messageID)}>
                            {t("words.delete")}
                        </Button>
                    </div>
                </ModalFooter>
                {successAlert === true &&
                    <AlertNotify type="success" time={3000} message={t("words.done")} state={true}/>}
            </CustomModal>
        </>
    );
};

export default MessageModal;