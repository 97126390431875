import styled from "styled-components";

export const Container = styled.div`
    background: #f8f9fa;
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 6px;
   /*  border-bottom: 6px solid #4adf83 ; */

    >img {
        width: 45%;
        height: auto;
    }

    > div {
        padding-top: 35px;
        padding-bottom: 50px ;
        > h3 {
            font-family: 'Mulish';
            font-size: 25x;
            font-weight: 900;
            color: #0080FC;
        }
    }
    
    
    @media(max-width: 600px){
       width: 100vw;
       
    }
`;