import React from "react";
import axios_base_chat from "../../../axios_base_chat";
import ProfileIcon from "../../../Common/Components/ProfileIcon";
import useApp from "../../../Providers/app";
import {Add, Container, Logo, Notify, Right} from "./styles";


const MainHeader = () => {
    const app = useApp();

    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState();

    React.useEffect(() => {
        axios_base_chat.get(`/chat/status`)
            .then(res => {
                setName(res.data.operator.name);
                setPhone(res.data.status.wid);
            })
            .catch(err => {
            });

    }, []);

    return (
        <Container>
            <Logo src={app.logo} alt={app.name}/>

            <Right>
                <Add></Add>
                <Notify></Notify>
                <ProfileIcon name={name} fone={phone} info="chat" image="avatar"/>
            </Right>


        </Container>
    );
};

export default MainHeader;