import OnlineAttendantsModal from "../../Components/OnlineAttendantsModal";
import {BtnWhite, BtnWhiteSmall} from "../../../Styles/buttons";
import {LightTooltip} from "../../../Styles/styles";
import ContentHeader from "../../../Common/Components/ContentHeader";
import AdminConfigModal from "../../Components/AdminConfigModal";
import SupportIcon from "../../../Assets/admin_img/support.svg";
import WelcomeBanner from "../../../Common/Components/WelcomeBanner";
import ContentAdmin from "../../Components/ContentAdmin";
import SettingsIcon from "@material-ui/icons/Settings";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Button, Snackbar} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import MuiAlert from "@material-ui/lab/Alert";
import {useParams} from "react-router-dom";
import axios_base from "../../../axios_base";
import React from "react";
// eslint-disable-next-line no-unused-vars
import GraphicArea from "../../Components/GraphicArea";
import {useCommun} from "../../../Providers/commun";
import {BtnConfigs, Container} from "./styles";
import GraphicAreaMobile from "../../Components/Mobile/GraphicAreaMobile";
import {Helmet} from "react-helmet";


const AdminDashboard = () => {
    const {t} = useTranslation();
    const btnWhite = BtnWhite();
    const btnWhiteSmall = BtnWhiteSmall();
    let {id: numberID} = useParams();
    const {isMobileState} = useCommun();

    const [onlineAttModal, setOnlineAttModal] = React.useState(false);
    const [configModal, setConfigModal] = React.useState(false);
    const [linkSuccess, setLinkSuccess] = React.useState(false);
    const [number, setNumber] = React.useState(null);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        setLinkSuccess(false);
    };

    const toggleOnlineAtt = () => {
        setOnlineAttModal(!onlineAttModal);
    };

    const toggleAdmConfig = () => {
        setConfigModal(!configModal);
    };

    const copyLink = () => {
        const whatsLink = `https://wa.me/${number.number.split("@")[0]}`;
        const el = document.createElement("textarea");
        el.value = whatsLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setLinkSuccess(true);
    };

    React.useEffect(() => {
        async function fetchData() {
            const {data: number} = await axios_base.get(`/number/${numberID}`);
            setNumber(number);
        }

        fetchData();
    }, [numberID]);

    React.useEffect(() => {
        if (number) {
            const fetchAttendance = () => {
                axios_base.get(`/number/${number?.id}/attendance`)
                    .then(({data}) => {
                        if (!data.is_active) {
                            setConfigModal(true);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data?.message === "record not found") {
                            setConfigModal(true);
                        }
                    });
            };
            fetchAttendance();
        }
    }, [number]);

    const [width, setWidth] = React.useState(window.innerWidth);

    function recarregarPagina() {
        setWidth(window.innerWidth);
    }

    var TimeOut;

    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };

    React.useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    return (
        <Container>
            <Helmet>
                <title> Attendance | Builderall</title>
            </Helmet>
            <ContentHeader title={t("words.attendance")} subTitle={t("words.dashboard_description")}>
                <BtnConfigs>
                    <Button classes={{root: btnWhite.root, label: btnWhite.label}} onClick={toggleOnlineAtt}>
                        <img src={SupportIcon} alt="suporte"/>
                    </Button>
                    <LightTooltip title={t("words.send_whats_link")} style={{marginLeft: "20px"}}>
                        <Button className="btnWhats" classes={{root: btnWhiteSmall.root, label: btnWhiteSmall.label}}
                                onClick={() => copyLink()}>
                            <WhatsAppIcon className="whatsIcon"/>
                        </Button>
                    </LightTooltip>
                    <Button classes={{root: btnWhiteSmall.root, label: btnWhiteSmall.label}}
                            style={{background: "#5A5959"}} onClick={toggleAdmConfig}>
                        <SettingsIcon/>
                    </Button>
                </BtnConfigs>
            </ContentHeader>

            <OnlineAttendantsModal number={number} isOpen={onlineAttModal} toggle={toggleOnlineAtt}/>

            <AdminConfigModal number={number} isOpen={configModal} toggle={toggleAdmConfig}/>

            {isMobileState || width <= 800 ? <></> : <WelcomeBanner></WelcomeBanner>}

            {isMobileState || width <= 800 ? <GraphicAreaMobile/> : <GraphicArea number={number}/>}

            <ContentAdmin number={number}/>

            {/* <ShareContact /> */}

            <Snackbar open={linkSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t("words.copied_link")}
                </Alert>
            </Snackbar>

        </Container>
    );
};

export default AdminDashboard;