import styled from "styled-components";

export const Container = styled.div`
    padding: 14px;
    min-height: 24.063rem;
    width: 75%;
    margin-top: 10%;

    background: #FDFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    border-radius: 8px;
    opacity: 1;

    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 600;
    color: rgba(78, 78, 78, .65);
`;

export const Body = styled.div`
    
    
    > small {
        display: block;
        text-align: center;
        font: normal normal 600 0.8rem Mulish;
        color: #4E4E4E;
        opacity: 0.65;
        line-height: 1.2rem;
    }

    > h4 {
        text-align: center;
        font: normal normal 600 15px Mulish;
        letter-spacing: 0px;
        color: #0080FC;
        opacity: 1;
    }
`;

export const Image = styled.div`
    display: flex;
    margin-top: 10px;
    padding-bottom: 15px;
  
    justify-content: space-around;

    > div {
        
        width: 157px;
        height: 141px;
    }
`;