import {BtnGray2, BtnWhite2, saveBtn2, BootstrapButton, ButtonBlue} from "../../../Styles/buttons";
import {getCampaign, getMembers} from "../../../Services/campaignFunctions";
import WelcomeBanner from "../../../Common/Components/WelcomeBanner";
import ContentHeader from "../../../Common/Components/ContentHeader";
import CampMenu from "../../../Assets/icon/Campaign - Settings.svg";
import CampaignGraphics from "../../Components/CampaignGraphics";
import MessageTimeline from "../../Components/MessageTimeline";
import CampaignOptions from "../../Components/CampaignOptions";
import AlertNotify from "../../../Common/Components/Alert";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import LinkIcon from "../../../Assets/icon/link.svg";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MenuItem} from "@material-ui/core";
import {Button} from "@material-ui/core";
import React from "react";
import CampaignMembers from "../../Components/CampaignMembers";
import {fetchNumber} from "../../../Services/numberFunctions";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import AuthenticateModal from "../../../Common/Components/ModalQRCode";
import NoMatch from "../../../Common/Pages/404";
import CampaignGroups from "../../Components/CampaignGroups";
import CampaignsStepByStep from "../../Components/CampaignsStepByStep";
import ReconnectIcon from "../../../Assets/CommunIcons/Reconnect icon.svg";
import {Helmet} from "react-helmet";

import {
    Container,
    HeaderBtns,
    StyledMenu,
    BackBtn,
    DivItemMenu,
} from "./styles";
import isMobile from "../../../Services/isMobile";
import CampaignGraphicsMobile from "../../Components/CampaignGraphicsMobile";

const CampaignScreen = () => {

    const [archiveModal, setArchiveModal] = React.useState(false);
    const [copySuccess, setCopySucccess] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [memberList, setMemberList] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [groupLink, setGroupLink] = React.useState("");
    const [campaign, setCampaign] = React.useState([]);

    const [number, setNumber] = React.useState();
    const [connected, setConnected] = React.useState("false");

    const [mobile, setMobile] = React.useState(false);

    const {t} = useTranslation();
    const WhiteBtn = BtnWhite2();
    const GrayBtn = BtnGray2();
    const SaveBtn = saveBtn2();

    let {id} = useParams();

    const copy = () => {
        const el = document.createElement("textarea");
        el.value = groupLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopySucccess(true);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleArchiveModal = () => {
        setAnchorEl(null);
        setArchiveModal(!archiveModal);
    };

    const toggleDeleteModal = () => {
        setAnchorEl(null);
        setDeleteModal(!deleteModal);
    };

    const getCampaignDetails = async () => {
        const [campaign, members] = await Promise.all([
            getCampaign(id),
            getMembers(id),
        ]);
        setCampaign(campaign);
        setGroupLink(`${window.location.origin}/campaign/${campaign?.invite_code}/invite`);
        setMemberList(members);
        getNumberDetail(campaign?.number_id);
    };

    const getNumberDetail = async (id) => {
        const number = await fetchNumber(id);
        setConnected(number.connected);
        setNumber(number.number);
    };

    React.useEffect(() => {
        getCampaignDetails();
    }, []);

    React.useEffect(() => {
        setMobile(isMobile());
    }, []);

    function recarregarPagina() {
        setMobile(isMobile());
    }

    var boraLa;
    window.onresize = function () {
        clearTimeout(boraLa);
        boraLa = setTimeout(recarregarPagina, 100);
    };

    return (
        <Container>
            <Helmet>
                <title> Campaign | Builderall</title>
            </Helmet>
            {!campaign ? <NoMatch from="campaign"/> :
                <div>
                    <ContentHeader title={campaign?.name} origin="campaigns"
                                   num={formatPhoneNumberIntl("+" + number?.split("@")[0])} status={connected}
                                   image={`${process.env.REACT_APP_LINK_API}/storage/${campaign?.group_image}`}
                                   subTitle={`${t("words.created_at")}`} date={campaign?.created_at}>
                        {!mobile &&
                            <BackBtn>
                                <Link to="/campaigns/dashboard" className="DashLink">
                                    <BootstrapButton classes={{root: WhiteBtn.root, label: WhiteBtn.label}}>
                                        {t("words.back")}
                                    </BootstrapButton>
                                </Link>
                            </BackBtn>}
                        <HeaderBtns>
                            {campaign?.archived ?
                                <Button classes={{root: SaveBtn.root, label: SaveBtn.label}} style={{height: "42px"}}
                                        className="CopyBtn" onClick={toggleArchiveModal}>
                                    <UnarchiveIcon className="UnarchiveIcon"/>
                                    {t("words.unarchive")}
                                </Button>
                                :
                                connected !== true ?
                                    <AuthenticateModal
                                        title="MyNumbers"
                                        Parameter="authenticate"
                                        Dash={`/campaign/${campaign.id}/detail`}
                                        IdNumber={campaign?.number_id}
                                        button={<ButtonBlue className="CopyBtn"><img src={ReconnectIcon}
                                                                                     alt="reconectImg"
                                                                                     style={{paddingRight: "10px"}}/> {t("words.reassign")}
                                        </ButtonBlue>}
                                        onClose={() => getCampaignDetails()}
                                    /> :
                                    <ButtonBlue className="CopyBtn" onClick={copy} style={{width: "100%"}}>
                                        <img src={LinkIcon} alt="Campaign link" className="CampIcon"/>
                                        {t("words.group_link")}
                                    </ButtonBlue>
                            }
                            <Button classes={{root: GrayBtn.root, label: GrayBtn.label}} onClick={handleClick}
                                    className="SettingsBtn">
                                <img src={CampMenu} alt="Campaign settings"/>
                            </Button>
                            <StyledMenu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => setAnchorEl(null)}><DivItemMenu><CampaignsStepByStep
                                    campaignNum={formatPhoneNumberIntl("+" + number?.split("@")[0])}
                                    numberStatus={connected} leadsInfo={memberList} campaignInfo={campaign}
                                    id={campaign.id} reload={getCampaignDetails} action="edit"
                                    title={t("words.campaigns")}
                                    buttonLabel={<span>{t("words.edit")}</span>}/></DivItemMenu></MenuItem>
                                {campaign?.archived ? null :
                                    <MenuItem
                                        onClick={toggleArchiveModal}><DivItemMenu>{t("words.archive")}</DivItemMenu></MenuItem>
                                }
                                <MenuItem
                                    onClick={toggleDeleteModal}><DivItemMenu>{t("words.delete")}</DivItemMenu></MenuItem>
                            </StyledMenu>
                        </HeaderBtns>
                    </ContentHeader>

                    {!mobile && <WelcomeBanner/>}

                    {mobile ? <div style={{}}><CampaignGraphicsMobile campaignID={id}/></div> :
                        <CampaignGraphics campaignID={id}/>}


                    <MessageTimeline campaignID={id} campaign={campaign}/>

                    <CampaignGroups image={`${process.env.REACT_APP_LINK_API}/storage/${campaign?.group_image}`}
                                    campaign={campaign} id={id}/>

                    <CampaignMembers memberList={memberList} campaignID={id}/>

                    <CampaignOptions
                        campaign={campaign}
                        getCampaignDetails={getCampaignDetails}
                        toggleArchiveModal={toggleArchiveModal}
                        archiveModal={archiveModal}
                        toggleDeleteModal={toggleDeleteModal}
                        deleteModal={deleteModal}
                    />

                    {copySuccess === true &&
                        <AlertNotify message={t("words.copied_link")} state={true} time={3000} type="success"/>}</div>}
        </Container>
    );
};

export default CampaignScreen;