import React from "react";
import Help_Active from "../../../Assets/CommunIcons/Help  Active - Hover.svg";
import Help_Inactive from "../../../Assets/CommunIcons/Help  Inactive.svg";
import Attendance_Active from "../../../Assets/CommunIcons/Icon menu Attendance _ Active.svg";
import Attendance_Inactive from "../../../Assets/CommunIcons/Icon menu Attendance _ Inactive.svg";
import Campaign_Active from "../../../Assets/CommunIcons/Icon menu Campaign _ Active.svg";
import Campaign_Inactive from "../../../Assets/CommunIcons/Icon menu Campaign _ Inactive.svg";
import Menu_Active from "../../../Assets/CommunIcons/icon_menu_buger_Open.svg";
import Number_Active from "../../../Assets/CommunIcons/Icon menu My Numbers_ _ Active.svg";
import Backoffice_Active from "../../../Assets/CommunIcons/Backoffice  Active - Hover.svg";
import Backoffice_Inactive from "../../../Assets/CommunIcons/backoffice  Inactive.svg";
import Menu_Inactive from "../../../Assets/CommunIcons/icon_menu_buger_Close.svg";
import Number_Inactive from "../../../Assets/CommunIcons/Icon menu My Numbers _ Inactive.svg";
import NewDash from "../../../Assets/icon/NewDash.svg";
import NewDashActive from "../../../Assets/icon/NewDashActive.svg";
import {useTranslation} from "react-i18next";
import {useCommun} from "../../../Providers/commun";
import {Link, useLocation} from "react-router-dom";
import AttendanceMenu from "./AttendanceMenu";

import {
    Container,
    Iten,
    Line,
    Menu,
    Btn,
} from "./styles";
import useApp from "../../../Providers/app";


function Aside() {

    const location = useLocation();
    const {t} = useTranslation();
    const {setIsAsideOpen} = useCommun();
    const app = useApp();

    const [asideOpen, setAsideOpen] = React.useState(false);

    const [launch, setLaunch] = React.useState(false);
    const [attendance, setAttendance] = React.useState(false);
    const [phone, setphone] = React.useState(false);
    const [back, setBack] = React.useState(false);
    const [help, setHelp] = React.useState(false);
    const [dash, setDash] = React.useState(false);

    const open = () => {
        setAsideOpen(!asideOpen);
        setIsAsideOpen(!asideOpen);
    };

    const url = window.location.href.split("/")[3];

    React.useEffect(() => {
        setLaunch(false);
        setAttendance(false);
        setphone(false);
        if (url === "campaigns") {
            setLaunch(true);
        }
        if (url === "campaign") {
            setLaunch(true);
        }
        if (url === "attendance") {
            setAttendance(true);
        }
        if (url === "numbers") {
            setphone(true);
        }
    }, [location, url]);

    return (
        <Container>

            <Menu style={{width: asideOpen === true ? window.innerWidth > "1920" ? "230px" : "230px" : "66px"}}>

                <Line>
                    <Btn>
                        {asideOpen === true ?
                            <img src={Menu_Active} onClick={open} style={{cursor: "pointer", margin: "auto"}} alt=""/>
                            :
                            <img src={Menu_Inactive} onClick={open} style={{cursor: "pointer", margin: "auto"}} alt=""/>
                        }
                    </Btn>
                    <Link to="/campaigns/dashboard" style={{textDecoration: "none"}}>
                        <Iten style={{
                            justifyContent: asideOpen !== true && "center",
                            background: url === "campaigns" || url === "campaign" ? "#F4F7FC" : "",
                            color: url === "campaigns" || url === "campaign" ? "#0F7BFF" : "",
                            borderLeft: url === "campaigns" || url === "campaign" ? "3px solid #0F7BFF" : "",
                        }} onMouseOver={() => setLaunch(true)}
                              onMouseLeave={() => url === "campaigns" || url === "campaign" ? setLaunch(false) : ""}>
                            <img alt="campaing" src={launch !== true ? Campaign_Inactive : Campaign_Active}/>
                            {asideOpen === true && <div>{t("words.campaigns")}</div>}
                        </Iten>
                    </Link>

                    <AttendanceMenu>
                        <Iten style={{
                            justifyContent: asideOpen !== true && "center",
                            background: url === "attendance" && "#F4F7FC",
                            color: url === "attendance" && "#0F7BFF",
                            borderLeft: url === "attendance" && "3px solid #0F7BFF",
                        }} onMouseOver={() => setAttendance(true)}
                              onMouseLeave={() => url !== "attendance" && setAttendance(false)}>
                            <img alt="Attendance" src={attendance !== true ? Attendance_Inactive : Attendance_Active}/>
                            {asideOpen === true && <div>{t("words.attendance")}</div>}
                        </Iten>
                    </AttendanceMenu>

                    <Link to="/numbers" style={{textDecoration: "none"}}>
                        <Iten style={{
                            justifyContent: asideOpen !== true && "center",
                            background: url === "numbers" && "#F4F7FC",
                            color: url === "numbers" && "#0F7BFF",
                            borderLeft: url === "numbers" && "3px solid #0F7BFF",
                        }} onMouseOver={() => setphone(true)} onMouseLeave={() => url !== "numbers" && setphone(false)}>
                            <img alt="my_number" src={phone !== true ? Number_Inactive : Number_Active}/>
                            {asideOpen === true && <div>{t("words.my_numbers")}</div>}
                        </Iten>
                    </Link>

                    {/*  <div onClick={() => window.location.replace(process.env.REACT_APP_CENTRAL)}>
                        <Iten style={{ justifyContent: asideOpen !== true && 'center' }} onMouseOver={() => setDash(true)} onMouseLeave={() => url !== "numbers" && setDash(false)}>
                            <img alt="my_number" src={dash ? NewDashActive : NewDash} />
                            {asideOpen === true && <div >{t("words.whatsapp_central")}</div>}
                        </Iten>
                    </div> */}
                </Line>

                <Line style={{paddingBottom: "2rem"}}>
                    <a href={app.business.url} target="_blank" rel="noreferrer"
                       style={{textDecoration: "none", color: "white"}}>
                        <Iten style={{justifyContent: asideOpen !== true && "center"}} onMouseOver={() => setBack(true)}
                              onMouseLeave={() => setBack(false)}>
                            <img width={30} alt={app.business.name}
                                 src={back !== true ? Backoffice_Inactive : Backoffice_Active}/>
                            {asideOpen === true && <div>{app.business.name}</div>}
                        </Iten>
                    </a>

                    {app.support && <a href={app.support} target="_blank" rel="noreferrer"
                                       style={{textDecoration: "none", color: "white"}}>
                        <Iten style={{justifyContent: asideOpen !== true && "center"}} onMouseOver={() => setHelp(true)}
                              onMouseLeave={() => setHelp(false)}>
                            <img alt="help" src={help !== true ? Help_Inactive : Help_Active}/>
                            {asideOpen === true && <div>{t("words.help")}</div>}
                        </Iten>
                    </a>}
                </Line>
            </Menu>
        </Container>
    );
}

export default Aside;