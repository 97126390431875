import React from "react";
import {
    BottomCards,
    Card,
    CardContainer,
    Circle,
    Container,
    Div1,
    Div2,
    DivNumber,
    GraphicContainer,
    GraphicTitle,
    SubTitle,
    Title,
    TopCards,
} from "./styles";
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Collaborator from "../../../Assets/icon/Online Operators.svg";
import OpenAttendance from "../../../Assets/icon/Open Attendances.svg";
import FinishedAttendance from "../../../Assets/icon/Finished Attendances.svg";
import Contacts from "../../../Assets/icon/Contacts.svg";
import axios_base from "../../../axios_base";
import CountUp from "react-countup";
import moment from "moment";
import {useTranslation} from "react-i18next";

const graphicColors = [
    "#00a8ff", "#9c88ff", "#00b894", "#487eb0", "#58B19F", "#00cec9", "#0984e3", "#9b59b6", "#ffeaa7", "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055", "#5f27cd", "#222f3e",
];

const GraphicArea = ({number}) => {
    const {t} = useTranslation();

    const [stats, setStats] = React.useState(null);


    React.useEffect(() => {
        if (number) {
            const getStatistics = async () => {
                try {
                    const res = await axios_base.get(`/number/${number?.id}/attendance/statistics`);
                    setStats(res.data);
                } catch (error) {
                    console.log(error);
                }
            };
            getStatistics();
        }
    }, [number]);

    return (
        <Container>
            <CardContainer>
                <TopCards>
                    <Card>
                        <Div1>
                            <Circle colors={["#0AFFF7", "#0294FF"]}>
                                <img src={OpenAttendance} alt="open attendance"/>
                            </Circle>
                            <DivNumber>
                                <SubTitle><CountUp end={stats?.total.open_attendances} duration={1.75}/></SubTitle>
                            </DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.open_attendances")}</Title>
                        </Div2>
                    </Card>
                    <Card>
                        <Div1>
                            <Circle colors={["#FD1A7A", "#F69534"]}>
                                <img src={FinishedAttendance} alt="finished attendance"/>
                            </Circle>
                            <DivNumber>
                                <SubTitle><CountUp end={stats?.total.finished_attendances} duration={1.75}/></SubTitle>
                            </DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.finished_attendances")}</Title>
                        </Div2>
                    </Card>
                </TopCards>
                <BottomCards>
                    <Card>
                        <Div1>
                            <Circle colors={["#0294FF", "#7E1AFD"]}>
                                <img src={Collaborator} alt="collaborator"/>
                            </Circle>
                            <DivNumber>
                                <SubTitle><CountUp end={stats?.total.online_operators} duration={1.75}/></SubTitle>
                            </DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.online_operators")}</Title>
                        </Div2>
                    </Card>
                    <Card>
                        <Div1>
                            <Circle colors={["#FCDC74", "#FC9115"]}>
                                <img src={Contacts} alt="contacs"/>
                            </Circle>
                            <DivNumber>
                                <SubTitle><CountUp end={stats?.total.contacts} duration={1.75}/></SubTitle>
                            </DivNumber>
                        </Div1>
                        <Div2>
                            <Title>{t("words.contacts")}</Title>
                        </Div2>
                    </Card>
                </BottomCards>
            </CardContainer>
            <GraphicContainer>
                <GraphicTitle><h4>{t("words.attendances_per_month")}</h4></GraphicTitle>
                <ResponsiveContainer width="100%" height="90%">
                    <BarChart
                        width={1200}
                        height={400}
                        data={stats?.attendances.map(v => ({
                            ...v,
                            label: moment().subtract(v.index, "days").format(localStorage.getItem("i18nextLng") === "en" ? "MM/DD" : "DD/MM"),
                        })).reverse() || []}
                        margin={{
                            top: 20,
                            right: 30,
                            left: -10,
                            bottom: 10,
                        }}
                    >
                        <XAxis dataKey="label" minTickGap={20}/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        {stats?.attendances && Object.keys(stats?.attendances
                            .reduce((a, b) => ({...a, ...b}), {}))
                            .filter(d => d !== "index")
                            .map((d, i) => (
                                <Bar key={d} dataKey={d} stackId="a" fill={graphicColors[i]}/>
                            ))}
                    </BarChart>
                </ResponsiveContainer>
            </GraphicContainer>
        </Container>
    );
};

export default GraphicArea;