import styled from "styled-components";
import {makeStyles} from "@material-ui/core";


export const Container = styled.div`
    width: 100%;
    text-align:center;
    align-items: center;
`;

export const Image = styled.div`
   
    width: 100%;
    align-items: center;
    text-align:center;
    > img{
        width: 20%;
        margin-bottom: -1%;
    }
`;

export const Texts = styled.div`
        width: 100%;
        margin: auto;
 
        > h1 {
            padding-top: 3rem;
            font-size: 2rem;
            font-weight: 700;
            color: #0080FC;
        }
        > h3 {
            padding-top: 30px;
            font-size: 2rem;
            font-weight: 800;
            padding-bottom: 5px;
            color: #0080FC;
        }
        > p {
            text-align: center;
            letter-spacing: 0px;
            color: #6E6E6E;
                    }

        > button {
            padding: 12px;
            box-shadow: 0.62px 0.79px 2px #1E120D1A;
            border-radius: 5px;
            opacity: 1;
            text-decoration: none;
            color: #fff;
            font-weight: 600;
        }
`;

export const WarningArea = styled.div`
    width: 70%;
    margin: auto;
    align-items: center;
    text-align: center;

    @media(max-width: 600px){
        width: 20rem;
    }

    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;
    font-family: 'Mulish';

    .Inputs {
        width: 100%;
        padding-bottom: 25px;
        margin: auto;
        justify-content: center;
        display:flex;
    }
    .Input {
        padding: 5px ;
        > input {
            background: #E0E7FF26 0% 0% no-repeat padding-box;
            border: 2px solid #E0E7FF;
            border-radius: 5px;
            opacity: 1;
            width: 50px;
            height: 50px;
            text-align: center;
            @media(max-width: 600px){
                width: 40px;
            }
        }
        input::-webkit-inner-spin-button {
         -webkit-appearance: none;
            margin: 0;
        }

/* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
    }

    > p {
        text-decoration: none;
        color: #0080FC;
        font-weight: 700;
        cursor: pointer;
        padding: 20px 0;
    }

`;

export const Load = styled.div`
    text-align: center;
`;

export const buttonStyled = {
    background: "#0080FC",
};
export const buttonComun = {
    background: "#E0E7FF",
};

export const UseStyle = makeStyles((theme) => ({
    modal: {
        backgroundColor: "#F3F7FF ",
        boxShadow: "0px 14px 32px #00000040",
        borderRadius: "10px",
        opacity: "1",
        maxWidth: "100vw",
        alignContent: "center",
        alignItems: "center",

        "& .modal-header": {
            borderBottom: "0",
            marginBottom: "-10px",

            "& span": {
                textTransform: "uppercase",
                color: "#434343",
                marginLeft: "23px",
                fontFamily: "Mulish",
                fontWeight: "600",
            },
        },

        "& .modal-footer": {
            borderTop: "0",
            paddingRight: "35px",
            paddingTop: "50px",
        },

    },
}));
