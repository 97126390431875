import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    margin-top: 50px ;
    
`;

export const Image = styled.div`
    @media(max-width: 600px){
        > img{
        width:2%;
    }
       
    }
`;


export const WarningArea = styled.div`
    width: 70%;
    height: 20rem;
    margin: auto;
    align-items: center;
    text-align: center;

    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;
    color: #0080FC;

    .Texts {
        width: 50%;
        height: 100%;
        margin: auto;

        > h1 {
            padding-top: 3rem; 
            padding-bottom: 3rem; 
            font-size: 30px;
            font-weight: 700;
        }
    }

`;
