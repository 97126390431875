import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Menu, MenuItem} from "@material-ui/core";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import axios_base from "../../../axios_base";
import {useSession} from "../../../Providers/session";
import {useCommun} from "../../../Providers/commun";
import {ReactComponent as Redirect} from "../../../Assets/icon/redirect.svg";
import {useTranslation} from "react-i18next";
import {
    SmallTitle,
} from "./styles";

function AttendanceMenu({children}) {
    const location = useLocation();
    const session = useSession();
    const {setInformations} = useCommun();
    const {t} = useTranslation();
    const [numbers, setNumbers] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => (async () => {
        if (!localStorage.getItem("token")) return;

        const {data: numbers} = await axios_base.get("/number");
        setNumbers(numbers.map(n => ({
            ...n,
            number_parsed: formatPhoneNumberIntl(`+${n.number.split("@")[0]}`),
        })));
    })(), [location]);

    if (numbers.length === 1) {
        return (
            <Link style={{textDecoration: "none"}}
                  onClick={() => setInformations({phoneNumber: numbers[0].number_parsed})}
                  to={`/attendance/dashboard/${numbers[0].id}`}>{children}</Link>
        );
    }

    return (
        <>
            <div
                onClick={handleClick}>
                {children}
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div style={{textAlign: "center"}}>
                    <SmallTitle>{t("words.select_num_helpdesk")}</SmallTitle>
                </div>

                {numbers.map(n => (
                    <MenuItem key={n.id} onClick={handleClose}>
                        <Link style={{textDecoration: "none"}}
                              onClick={() => setInformations({phoneNumber: n.number_parsed})}
                              to={`/attendance/dashboard/${n.id}`}>{n.number_parsed} <Redirect/> </Link>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default AttendanceMenu;