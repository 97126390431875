import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import {useTranslation} from "react-i18next";
import Arrowdropdown from "../../../../Assets/IconesChat/Arrow drop down.svg";

import {Container, GlobalCss, TopDiv, useStyles} from "./styles";
import ChatSchedulingMessage from "../../ChatSchedulingMessage";
import ChatQuicklyAnswer from "../../ChatQuicklyAnswer";

const DrawerChatMEssagesOptions = ({sectorList, selctFunc}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState({bottom: false});

    const toggleDrawer = (anchor, open, cont) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({...state, [anchor]: open});
        const draw = document?.getElementById("Drawer");
        if (!draw) {
            return;
        } else {
            draw.hidden = false;
        }
    };
    const hiddenFunc = () => {
        const draw = document?.getElementById("Drawer");
        draw.hidden = true;
    };
    return (
        <Container>
            <Button onClick={toggleDrawer("bottom", true)}><img src={Arrowdropdown} alt=""/></Button>

            <Drawer anchor="bottom" open={state["bottom"]} onClose={toggleDrawer("bottom", false)} id="Drawer">
                <GlobalCss/>
                <div className={clsx(classes.list, classes.fullList)}
                     role="presentation" /* onClick={toggleDrawer("bottom", false)} */ /* onKeyDown={toggleDrawer("bottom", false)} */>
                    <TopDiv>
                        <div style={{
                            background: "#DCE4EF 0% 0% no-repeat padding-box",
                            borderRadius: "2px",
                            width: "31px",
                            height: "3px",
                            marginBottom: "15px",
                        }}></div>
                        <small>{t("words.sectors")}</small>
                    </TopDiv>
                    <Divider style={{margin: "0 3%"}}/>
                    <List>

                        <div onClick={() => {
                            toggleDrawer("bottom", true, true);
                            hiddenFunc();
                        }}>
                            <ChatSchedulingMessage from="mobile"/>
                        </div>
                        <div onClick={() => {
                            toggleDrawer("bottom", true, true);
                            hiddenFunc();
                        }}>
                            <ChatQuicklyAnswer from="mobile"/>
                        </div>
                    </List>
                </div>
            </Drawer>
        </Container>
    );
};

export default DrawerChatMEssagesOptions;