import styled from "styled-components";

export const Container = styled.div`
`;

export const CloseDiv = styled.div`
    display: flex;
    align-content: right;
    align-items: right;
    justify-content: right;
    padding: 10px;
    cursor: pointer;

`;

export const TextDiv = styled.div`
    text-align: center;
    padding-top: 10px;

    > h1 {
        font: normal normal bold 2rem Mulish;
        letter-spacing: 0;
        color: #373F4E;
        text-transform: uppercase;
    }

    > h3 {
        font: normal normal bold 1.8rem Mulish;
        letter-spacing: 0;
        color: #4E4E4E;
    }

`;

export const ImgDiv = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
`;

export const SubtitleDiv = styled.div`
    text-align: left;
    font: normal normal bold 1rem Mulish;
    color: #4E4E4E;
    margin: 0px 40px 0px 40px;
`;

export const BtnDiv = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
`;