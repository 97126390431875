import styled from "styled-components";

export const Container = styled.div`
    width: 80vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media(min-width: 1300px){
            width: 100%;
        }
    >img {
        width: 50%;
        @media(min-width: 1300px){
            width: 20%;
        }
    }
`;
export const Input = styled.div`
    padding: 15px;
    width: 100%;
    justify-content: center;
    display: flex;
    > input {
        background: #E0E7FF26 0% 0% no-repeat padding-box;
            border: 2px solid #E0E7FF;
            border-radius: 5px;
            opacity: 1;
            height: 50px;
            text-align: center;
            @media(max-width: 600px){
                width: 100%;
            }
            @media(min-width: 1300px){
                width: 50%;
            }

    }
`;
export const Texts = styled.div`
        width: 100%;
        margin: auto;
        text-align: center;
        padding: 0 15px;
        > h1 {
            padding-top: 3rem;
            font-size: 2rem;
            font-weight: 700;
            color: #0080FC;
        }
        > h3 {
            padding-top: 30px;
            font-size: 2rem;
            font-weight: 800;
            padding-bottom: 5px;
            color: #0080FC;
        }
        > p {
            text-align: center;
            letter-spacing: 0px;
            color: #6E6E6E;
        }

        > button1 {
            width: 100%;
            padding: 12px;
            box-shadow: 0.62px 0.79px 2px #1E120D1A;
            border-radius: 5px;
            opacity: 1;
            text-decoration: none;
            color: #fff;
            font-weight: 600;
            background: #0080FC;
            cursor: pointer;
            @media(min-width: 1300px){
                width: 50%;
            }
        }

        > button {
            padding: 12px;
            box-shadow: 0.62px 0.79px 2px #1E120D1A;
            border-radius: 5px;
            opacity: 1;
            text-decoration: none;
            color: #fff;
            font-weight: 600;
            background: #E0E7FF;
            width: 100%;
            @media(min-width: 1300px){
                width: 50%;
            }

            
        }
`;

export const Board = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    height: 60%;

    @media(min-width: 1300px){
        width: 60%;
        height: 60%;
    }
`;

export const P = styled.p`
    text-align: center;
    font: normal normal bold 15px/22px Mulish;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
    padding: 0 5px;
`;