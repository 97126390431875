import styled from "styled-components";

export const Container = styled.div`
    align-items: center;
    
`;
export const Content = styled.div`
    margin-top: 2rem;
    align-items: center;
    background-color: #E9EDF2;
    border-radius: 6px;
    min-height: 15em;
    max-height: 75rem;
`;