import React from "react";
import {InputGroup, InputGroupText, ModalFooter, ModalBody, ModalHeader, Modal, Input} from "reactstrap";
import {BsSearch} from "react-icons/bs";
import Grid from "@material-ui/core/Grid";
import {Snackbar} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import {useStyles} from "../../../Styles/styles";
import {FixedSizeList as List} from "react-window";
import {Link, useParams, useLocation, useHistory} from "react-router-dom";
import {Button} from "@material-ui/core";
import axios_base from "../../../axios_base";
import ContentHeader from "../../../Common/Components/ContentHeader";
import {RiArrowGoBackFill} from "react-icons/ri";
import ImportGroup from "../../Components/ImportGroup";
import Trash from "../../../Assets/attendance/Trash  Active - Hover.svg";
import MuiAlert from "@material-ui/lab/Alert";
import DateFormat from "../../../Common/Components/DateFormat";
import {
    Container,
    Card8,
    TitleBtn,
    ContentC7,
    Line,
    Right,
    Left,
} from "./style";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Groups = () => {


    const history = useHistory();
    const {t} = useTranslation();
    const classes = useStyles();
    const BtnSave = saveBtn2();
    const CancelBtn = cancelBtn2();
    let {id} = useParams();
    var language = navigator.language.substring(0, 2);
    let location = useLocation();
    const [listGroups, setListGroups] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [campaignId, setCampaignId] = React.useState();
    const [groupId, setGroupId] = React.useState();
    const [jid, setJid] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [campaign, setCampaign] = React.useState();

    const handleClose = () => {
        setOpen(!open);
    };
    const handleClose2 = () => {
        setOpen2(!open2);
    };
    const toggle = () => {
        setModal(!modal);
    };

    const getGroups = async (id_) => {
        try {
            const {data: res} = await axios_base.get(`campaign/${id_}/group`);
            setListGroups(res);
        } catch (error) {

        }
    };
    const getDetailsCampaing = (id) => {
        axios_base.get(`/campaign/${id}`)
            .then(res => {
                if (res.data) {
                    setCampaign(res.data);
                }
            });
    };

    React.useEffect(() => {
        getDetailsCampaing(id);
        getGroups(id);
    }, [id, location]);


    const groupsOptions = (campaignId, groupId, jid, name) => {
        setCampaignId(campaignId);
        setGroupId(groupId);
        setJid(jid);
        toggle();
    };

    const groupDelete = () => {
        try {
            var data = {
                "gid": jid,
            };
            axios_base.delete(`campaign/${campaignId}/group/${groupId}`, data)
                .then(res => {
                    toggle();
                    handleClose();
                    history.push(`/campaign/${id}/groups`);
                })
                .catch(err => {
                    handleClose2();
                });
        } catch (error) {

        }
    };

    //Filtro de grupos
    const [search, setSearch] = React.useState("");
    const filtered = listGroups.filter(v => v?.gid.split("@")[0].includes(search));


    return (
        <Container>
            <ContentHeader title={`${t("words.groups")}`} /* subTitle={camp} */>
                <Link to={`/campaign/${id}/detail`}>
                    <Button classes={{root: classes.root, label: classes.label}}>
                        <RiArrowGoBackFill/>
                    </Button>
                </Link>
            </ContentHeader>

            <Grid item xs={12} className={classes.container}>
                <Card8>
                    <TitleBtn>
                        <Left>
                            <InputGroup>
                                <InputGroupText><BsSearch/></InputGroupText>
                                <Input className="form-input" onChange={e => setSearch(e.target.value)} value={search}
                                       id="input-table" placeholder={t("words.search")}/>
                            </InputGroup>
                        </Left>
                        <Right>
                            <ImportGroup campaign={campaign}></ImportGroup>
                        </Right>
                    </TitleBtn>
                    <ContentC7>
                        <Line>
                            {/* <div style={{ width: '10%', textAlign: 'center', marginRight: '40px' }}></div> */}
                            <div style={{width: "30%", textAlign: "left"}}><h6>{t("words.name")}</h6></div>
                            <div style={{width: "30%", textAlign: "center"}}><h6>{t("words.created")}</h6></div>
                            <div style={{width: "20%", textAlign: "center"}}><h6>{t("words.leads")}</h6></div>
                            <div style={{width: "20%", textAlign: "right"}}><h6>{t("words.options")}</h6></div>
                        </Line>

                        <List
                            height={350}
                            itemCount={filtered?.length}
                            itemSize={56}

                        >
                            {({index, style}) => {
                                const group = filtered[index];
                                return (

                                    <Line key={group.id}>
                                        <div style={{width: "30%"}}>
                                            {group?.gid !== "" && group?.metadata?.name && group?.metadata?.name}
                                            {group?.metadata === "whatsapp error: Error: item-not-found" &&
                                                <Alert severity="warning">{t("words.group_error")}</Alert>}
                                        </div>
                                        <div style={{width: "30%", textAlign: "center"}}>
                                            <DateFormat date3L={false} date={group.created_at}/>
                                        </div>
                                        <div style={{
                                            width: "20%",
                                            textAlign: "center",
                                        }}>{group?.metadata?.participants?.length}</div>
                                        <div style={{width: "20%", textAlign: "right"}}>
                                            <div style={{cursor: "pointer", paddingRight: "15px"}}
                                                 onClick={() => groupsOptions(group.campaign_id, group.id, group.jid, group?.metadata?.name)}>
                                                <img src={Trash} alt="trash"/>
                                            </div>
                                        </div>
                                    </Line>


                                );
                            }}
                        </List>
                    </ContentC7>
                </Card8>
            </Grid>
            <Modal isOpen={modal} toggle={toggle} size="lg" style={{maxWidth: "700px", width: "100%"}}>
                <ModalHeader>{t("words.groups")}</ModalHeader>
                <ModalBody>
                    <h6>{t("words.delete_group")}</h6>
                    <h6>{t("words.delete_gp2")}</h6>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{root: CancelBtn.root, label: CancelBtn.label}}
                                onClick={toggle}>{t("words.cancel")}</Button>
                    </div>
                    <div>
                        <Button classes={{root: BtnSave.root, label: BtnSave.label}}
                                onClick={groupDelete}>{t("words.delete")}</Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose2} severity="success">
                    {t("words.done")}
                </Alert>
            </Snackbar>
            <Snackbar open={open2} autoHideDuration={2000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="error">
                    {t("words.unable_complete")}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Groups;