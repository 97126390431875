import {
    Button as ButtonBootstrap,
    Card,
    CardBody,
    CardTitle,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {
    CardButtons,
    CardsSectors,
    CardTitleAvatar,
    Container,
    RelatedOperators,
    useStyles,
    WithoutOperators,
} from "./styles";
import {cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import {Button, Divider, Snackbar} from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import {ReactComponent as Trash} from "../../../Assets/admin_img/Trash.svg";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import PersonIcon from "@material-ui/icons/Person";
import {useTranslation} from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import MuiAlert from "@material-ui/lab/Alert";
import axios_base from "../../../axios_base";
import React from "react";

const SectorCards = ({sectors, onEdit, onDelete, search, number}) => {

    const {t} = useTranslation();
    const CancelBtn = cancelBtn2();
    const SaveBtn = saveBtn2();
    const classes = useStyles();

    const [deleteModal, setDeleteModal] = React.useState(false);

    const [excludeSuccess, setExcludeSuccess] = React.useState(false);
    const [generalError, setGeneralError] = React.useState(false);
    const [errorDefaultSector, setErrorDefaultSector] = React.useState(false);

    const [selectedSector, setSelectedSector] = React.useState(null);
    const [sectorNameTest, setSectorNameTest] = React.useState("");

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        setExcludeSuccess(false);
        setGeneralError(false);
        setErrorDefaultSector(false);
    };

    const toggle = (sector) => {
        setDeleteModal(!deleteModal);
        setSelectedSector(sector);
        setSectorNameTest("");
    };

    const deleteSector = async () => {
        try {
            await axios_base.delete(`/number/${number?.id}/department/${selectedSector?.id}`);
            setExcludeSuccess(true);
            setDeleteModal(false);
            toggle();
            onDelete();
        } catch (error) {
            if (error?.response?.data?.message === "ERROR: update or delete on table \"departments\" violates foreign key constraint \"fk_number_attendances_default_department\" on table \"number_attendances\" (SQLSTATE 23503)") {
                setErrorDefaultSector(true);
            } else {
                setGeneralError(true);
            }
        }
    };

    return (
        <Container>
            <CardsSectors>
                {sectors.filter((sector) => search === "" || sector.name.toLowerCase().includes(search.toLowerCase()))
                    .map(sector => (
                        <Card className="cardOutline" key={sector.id}>
                            <CardBody className="cardBody1">
                                <CardTitleAvatar variant={sector.is_active === true ? "blue" : "gray"}>
                                    <div className="avatarExclusiveDiv">
                                        <ApartmentIcon fontSize="large"/>
                                    </div>
                                    <CardTitle className="cardTitle">{sector.name.toUpperCase()}</CardTitle>
                                </CardTitleAvatar>

                                <RelatedOperators>
                                    <PersonIcon style={{marginTop: "1px", color: "#B7CFEB", marginRight: ".3rem"}}/>
                                    {
                                        sector.operators?.length > 0
                                            ?
                                            <AvatarGroup max={4} classes={{avatar: classes.avatar}}>
                                                {sector.operators?.map(({operator}) => (
                                                    <Avatar title={operator.name}>{operator.name.charAt(0)}</Avatar>
                                                ))}
                                            </AvatarGroup>
                                            :
                                            <WithoutOperators>{t("words.without_operators")}</WithoutOperators>
                                    }
                                </RelatedOperators>
                            </CardBody>
                            <Divider style={{opacity: "1"}}/>
                            <CardButtons>
                                <CardBody>
                                    <ButtonBootstrap variant="primary" className="editButton"
                                                     onClick={() => onEdit(sector.id, sector.name)}>{t("words.atend_edit")}</ButtonBootstrap>
                                    <Trash className="trashButton" onClick={() => toggle(sector)}/>
                                </CardBody>
                            </CardButtons>
                        </Card>
                    ))}
            </CardsSectors>

            <Modal isOpen={deleteModal} toggle={toggle}>
                <ModalHeader>
                    {t("words.delete")}
                </ModalHeader>
                <ModalBody>
                    <h6>{t("words.really_delete")} <b style={{color: "red"}}>{selectedSector?.name}</b></h6>
                    <Input className="form-input" onChange={e => setSectorNameTest(e.target.value)}
                           value={sectorNameTest} id="input-delete"/>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{root: CancelBtn.root, label: CancelBtn.label}} onClick={toggle}
                                className="cancelButton" style={{marginRight: "10px"}}>{t("words.cancel")}</Button>
                        {selectedSector?.name === sectorNameTest ?
                            <Button classes={{root: SaveBtn.root, label: SaveBtn.label}}
                                    onClick={() => deleteSector()}>{t("words.delete")}</Button>
                            : null}
                    </div>
                </ModalFooter>
            </Modal>

            <Snackbar open={excludeSuccess} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t("words.sector_deleted")}
                </Alert>
            </Snackbar>
            <Snackbar open={generalError} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t("words.delete_error")}
                </Alert>
            </Snackbar>
            <Snackbar open={errorDefaultSector} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t("words.error_default_sector")}
                </Alert>
            </Snackbar>

        </Container>
    );
};

export default SectorCards;