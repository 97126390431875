import axios_base_chat from "../axios_base_chat";

export const getDepartaments = async () => {
    try {
        const {data: res} = await axios_base_chat.get("/chat/department");
        return res;
    } catch (error) {
        return "erro";
    }
};

export const getContacts = async () => {
    try {
        const {data: res} = await axios_base_chat.get("/chat/contact");
        return res;
    } catch (error) {
        return "error";
    }
};

export const getMyAttendsList = async (id) => {
    try {
        const {data: res} = await axios_base_chat.get(`/chat/department/${id}/attendance`);
        return res;
    } catch (error) {
        return "error";
    }
};

export const getOthersAttensList = async (id) => {
    try {
        const {data: res} = await axios_base_chat.get(`/chat/department/${id}/attendance?others=true`);
        return res;
    } catch (error) {
        return "error";
    }
};

export const getMyAttendsFinished = async (id) => {
    try {
        const {data: res} = await axios_base_chat.get(`/chat/department/${id}/attendance?finished=true`);
        return res;
    } catch (error) {
        return "error";
    }
};

export const getOperatorsList = async (id) => {
    try {
        const {data: res} = await axios_base_chat.get(`/chat/department/${id}/operator`);
        return res;
    } catch (error) {
        return "error";
    }
};