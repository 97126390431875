import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div`

`;

export const Body = styled.div`
    background-color: #F3F7FF;
    padding: 0px 40px;
    max-height: 610px;
    overflow: auto;
`;

export const Line = styled.div`
    display: flex;
`;

export const BTN = styled.div`
    padding: 0 20px;
    padding-bottom: 10px ;
    display: flex;
    align-items: right;
    justify-content: flex-end;
`;
export const Text = styled.div`
    padding-top: 25px;
    padding-bottom: 10px;
    text-align: left;
    font: normal normal 600 16px Mulish;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
`;
export const Btn = styled.div`
 width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.2s;
    cursor: pointer;
    color: #0072FF;
    &:hover{
        color: #0072FF;
    }
`;
export const UseStyle = makeStyles((theme) => ({
    modal: {
        backgroundColor: "#F3F7FF ",
        boxShadow: "0px 14px 32px #00000040",
        borderRadius: "10px",
        opacity: "1",
        maxWidth: "649px",
        maxHeight: "960px",
        alignContent: "center",
        alignItems: "center",

        "& .modal-header": {
            borderBottom: "0",
            marginBottom: "-10px",

            "& span": {
                textTransform: "uppercase",
                color: "#434343",
                marginLeft: "23px",
                fontFamily: "Mulish",
                fontWeight: "600",
            },
        },

        "& .modal-footer": {
            borderTop: "0",
            paddingRight: "35px",
            paddingTop: "50px",
        },

    },
    backgroundColor: {
        backgroundColor: "#F3F7FF ",
    },
    label: {
        width: "50%",
        height: "100%",
        cursor: "pointer",
        textAlign: "center",
    },
    icomRed: {
        textAlign: "left",
        font: "normal normal 800 14px/16px Mulish",
        letterSpacing: "0px",
        color: "#FA4B57",
        background: "#F4F7FC 0% 0% no-repeat padding-box",
        boxShadow: "0.62px 0.79px 2px #1E120D1A",
        borderRadius: " 5px",
        opacity: "1",
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
            background: "#F4F7FC 0% 0% no-repeat padding-box",
        },
    },
    icom: {
        textAlign: "left",
        font: "normal normal 800 14px/16px Mulish",
        letterSpacing: "0px",
        color: "#FFFFFF",
        background: "#0080FC 0% 0% no-repeat padding-box",
        boxShadow: "0.62px 0.79px 2px #1E120D1A",
        borderRadius: " 5px",
        opacity: "1",
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
            background: "#0080FC 0% 0% no-repeat padding-box",
        },
    },
    textArea: {
        width: "100%",
        height: "100%",
        border: "1px solid #D0DAED",
        outline: 0,
        backgroundColor: "#FFFFFF ",
        fontSize: "14px",
        color: "#4a4a4a",
        padding: "15px",
        overflow: "scroll",
        borderRadius: "3px",
    },
    divStyled: {
        borderBottom: "3px solid #0080FC",
        color: "#0080FC",
        paddingBottom: "15px",
        font: "normal normal 14px 800 Mulish",
        width: "100%",
    },

    regularDiv: {
        paddingBottom: "15px",
        font: "normal normal 14px 800 Mulish",
        color: "#434343",
        width: "0",
    },
    title: {
        cursor: "pointer",
        textAlign: "left",
        font: "normal normal normal 16px Mulish",
        color: "#434343",
        opacity: "1",
    },
    icon: {
        padding: "0 20px",
        cursor: "pointer",
    },
    selectImagefull: {
        background: "#FAFBFC80 0% 0 % no - repeat padding- box",
        border: "2px dashed #D0DAED",
        borderRadius: "3px",
        opacity: "1",
        height: "176px",
        textAlign: "center",
        margin: "auto",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        paddingTop: "10px",
    },
    divFileSelect: {
        background: "#FFFFFF 0% 0 % no - repeat padding- box",
        border: "1px solid #D0DAED",
        borderRadius: "3px",
        opacity: " 1",
        display: "flex",
        padding: "10px",
        textAlign: "center",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",

    },
    textLabel: {
        textAlign: "left",
        font: "normal normal 600 16px Mulish",
        letterSpacing: "0px",
        color: "#6E6E6E",
        opacity: "1",
        paddingTop: "5px",
        paddingBottom: "15px",
    },

}));

export const StyledDiv = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 10px",
    paddingTop: "5px",
    textAlign: "left",
    letterSpacing: "0.8px",
    color: "#434343",
    opacity: "1",
    font: "normal normal bold Mulish",
    borderBottom: "1px solid #CAD4E6",

};

export const StyledDivF = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    padding-top: 5px;
    text-align: left;
    letter-spacing: 0.8px;
    color: #434343;
    opacity: 1;
    font: normal normal bold Mulish;
    border-bottom: 1px solid #CAD4E6;

    @media(max-width: 800px){
        justify-content: space-around;
    }
`;

export const messagList = {
    background: " #FFFFFF",
    opacity: "1",
    width: "100%",
    height: "530px",
    overflowY: "auto",

};

export const Block = styled.div`
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;
    opacity: 1;
    width: 100%;

    > p {
        font: normal normal 600 15px Mulish;
        color: #6E6E6E;
        padding-left: 20px;
        padding-top: 20px;
    }

    .radioSign {
        padding: 10px;
        padding-left: 20px;
    }

    .MuiTypography-body1 {
        font: normal normal 600 15px Mulish;
        color: #6E6E6E;
    }
`;

export const DivLogo = styled.div`
    @media(max-width: 800px){
        width: calc(100% / 5);
    }
`;