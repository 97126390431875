import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Avatar} from "@material-ui/core";
import DateFormat from "../../../../Common/Components/DateFormat";

import {
    Container,
    Small,
    Small2,
} from "./styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        display: "flex",
        alignItems: "center",
    },
}));


const CardInfo = ({img, name, createdAt, desc, info, translate}) => {

    const classes = useStyles();
    return (
        <Container>
            <div>
                <Accordion style={{border: "1px solid #DEE3EA"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}><Avatar src={img}/> &nbsp; &nbsp; &nbsp;
                            <Small>{name}</Small></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{flexDirection: "column"}}>
                            <Small2>{translate}</Small2>
                            <br/>
                            <Small><DateFormat date={createdAt}/></Small>
                            <br/>
                            <Small2>{desc}</Small2>
                            <br/>
                            <Small>{info}</Small>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    );
};

export default CardInfo;