import React from "react";
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LiquidLoader from "../../../Animations/LiquidLoader";

import {Body, Container, Line, Text, UseStyle} from "./styles";
import axios_base_chat from "../../../axios_base_chat";
import axios from "axios";
import AlertNotify from "../../../Common/Components/Alert";


const ImportContact = () => {

    const {t} = useTranslation();
    const btnCancel = cancelBtn2();
    const btnSave = saveBtn2();
    const [file, setFile] = React.useState();
    const [fileName, setFileName] = React.useState();
    const [importing, setImporting] = React.useState(false);
    const [error, setError] = React.useState(false);

    const classes = UseStyle();
    const [importChat, setImportChat] = React.useState(false);

    const importToggle = () => {
        setImportChat(!importChat);
    };

    const getTemplate = async () => {
        const {data: res} = await axios_base_chat(`/chat/contact/import`);
        axios({
            url: `${process.env.REACT_APP_LINK_API}/storage/${res.filename}`,
            method: "GET",
            responseType: "blob",
        })
            .then((response) => {
                const url = window.URL
                    .createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url.split(",").join(";");
                link.setAttribute("download", "template.csv");
                document.body.appendChild(link);
                link.click();
            });
    };

    const uploadTemplate = (event) => {
        setFile(event.target?.files[0]);
        setFileName(event.target?.files[0]?.name);
    };

    const saveContacts = async () => {
        try {
            setImporting(true);
            var data = new FormData();
            data.append("contacts", file);
            const options = {
                method: "post",
                url: `${process.env.REACT_APP_LINK_API}/chat/contact/import`,
                headers: {
                    "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
                    Authorization: `Bearer ${localStorage.getItem("operator_token")}`,
                },
                data: data,
            };
            const {data: res} = await axios.request(options);
            setImporting(false);
            setFile("");
            setFileName("");
            importToggle();
        } catch (error) {
            setImporting(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
    };

    return (
        <Container>
            <Button className={classes.btn} onClick={importToggle}><CloudUploadIcon/></Button>
            <Modal isOpen={importChat} toggle={importToggle} className={classes.modal}>
                <ModalHeader className={classes.backgroundColor}><span style={{
                    font: "normal normal bold 20px/26px",
                    color: "#434343",
                    textTransform: "uppercase",
                }}>{t("words.new_contact")}</span></ModalHeader>
                <Body>
                    <Text>
                        {t("words.download_template")}
                    </Text>
                    <Line>
                        <p>{t("words.to_import_contact_list")}</p>
                        <Button className={classes.btn} onClick={getTemplate}> <GetAppIcon/> </Button>
                    </Line>
                    <Text>
                        {t("words.upload_template")}
                    </Text>
                    <Line>
                        {importing === false ? <label className={classes.inputFileCsv} htmlFor="csv">
                                <input type="file" id="csv" accept=".csv" style={{opacity: "0", width: "50%"}}
                                       onChange={uploadTemplate}/>
                                <CloudUploadIcon fontSize="large" style={{color: "#fff"}}/>
                            </label> :
                            <div style={{width: "100%", height: "80px", justifyContent: "center", display: "flex"}}>
                                <LiquidLoader/>
                            </div>
                        }
                    </Line>
                    <Text>{fileName}</Text>
                </Body>

                <ModalFooter className={classes.backgroundColor}>
                    <div>
                        <Button classes={{root: btnCancel.root, label: btnCancel.label}} onClick={importToggle}>
                            {t("words.cancel")}
                        </Button>
                    </div>
                    <div id="update">

                        <Button classes={{root: btnSave.root, label: btnSave.label}} onClick={saveContacts}>
                            {t("words.save")}
                        </Button>

                    </div>

                </ModalFooter>
                {error === true &&
                    <AlertNotify type="error" time={3000} message={t("words.something_wrong")} state={true}/>}
            </Modal>
        </Container>
    );
};

export default ImportContact;