import {createNewTag, fetchTags, getMemberDetail, updateMemberDetail} from "../../../Services/campaignFunctions";
import {ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {
    CustomModal,
    DetailsDiv,
    LeftDiv,
    Infos,
    RightDiv,
    TagsDiv,
    Title,
    SubTitle,
    TagBtnDiv,
    NewTagDiv,
} from "./styles";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import EditImg from "../../../Assets/attendance/edit.svg";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import DateFormat from "../../../Common/Components/DateFormat";
import {Button} from "@material-ui/core";
import {cancelBtn2, saveBtn2, NewTag} from "../../../Styles/buttons";
import Select from "react-select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AlertNotify from "../../../Common/Components/Alert";


const MemberModal = ({selectedMember, campaignID}) => {

    const [modalMemberDetail, setModalMemberDetail] = React.useState(false);
    const [doneAlert, setDoneAlert] = React.useState(false);
    const [generalError, setGeneralError] = React.useState(false);
    const [newTagModal, setNewTagModal] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [listTags, setListTags] = React.useState([]);
    const [leadTags, setLeadTags] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [tagName, setTagName] = React.useState();
    const [obs, setObs] = React.useState("");

    const BtnCancel = cancelBtn2();
    const BtnSave = saveBtn2();
    const BtnBlue = NewTag();

    const {t} = useTranslation();

    const memberDetail = async () => {
        try {
            const detail = await getMemberDetail(campaignID, selectedMember);
            setDetails(detail);
            setLeadTags(detail.tags?.map(tags => ({label: tags.tag?.name, value: tags.tag?.id})));
            setObs(detail.observation);
            getTags();
        } catch (error) {
            console.log(error);
            setGeneralError(true);
        }
    };

    const getTags = async () => {
        const tags = await fetchTags(campaignID);
        setListTags(tags);
    };

    const createTag = async (tagName) => {
        try {
            if (tagName.split(" ").length > 1) {
                setNameError(true);
            }
            var data = {
                "name": tagName,
            };
            await createNewTag(campaignID, data);
            setDoneAlert(true);
            setTagName("");
            getTags();
        } catch (error) {
            if (error?.response?.data?.message === "Name alphanum") {
                setNameError(true);
            }
            setNameError(true);
        }
    };

    const updateMember = async (obs, leadTags) => {
        try {
            if (!obs) {
                obs = details.observation;
            }
            var data = {
                "observation": obs,
                "tag_ids": leadTags?.map(tagsId => tagsId.value),
            };
            await updateMemberDetail(campaignID, selectedMember, data);
            setDoneAlert(true);
            memberDetail();
            setObs("");
        } catch (error) {
            console.log(error);
            setGeneralError(true);
        }

    };

    const toggleMemberModal = () => {
        setModalMemberDetail(!modalMemberDetail);
        memberDetail();
        setDoneAlert(false);
        setNameError(false);
        setGeneralError(false);
    };

    const toggleNewTagModal = () => {
        setNewTagModal(!newTagModal);
        memberDetail();
        setDoneAlert(false);
        setNameError(false);
        setGeneralError(false);

    };

    const customStyles = {
        control: base => ({
            ...base,
            maxHeight: 100,
            overflow: "auto",
        }),
    };

    let options = listTags.map(tags => ({label: tags?.name, value: tags?.id}));

    return (
        <>
            <img src={EditImg} alt="edit" style={{cursor: "pointer"}} onClick={toggleMemberModal}/>

            <CustomModal isOpen={modalMemberDetail} toggle={toggleMemberModal}>
                <ModalHeader>{t("words.details")}</ModalHeader>
                <ModalBody>
                    <DetailsDiv>
                        <LeftDiv>
                            <Avatar src={details?.image}/>
                        </LeftDiv>
                        <RightDiv>
                            <Infos>
                                <span>{details?.name !== "" && details?.name}{details?.name === "" && t("words.name_unavailable")}</span>
                                <span>{formatPhoneNumberIntl("+" + details.number?.split("@")[0])}</span>
                            </Infos>
                        </RightDiv>
                        <span class=" css-1okebmr-indicatorSeparator"></span>
                        <RightDiv>
                            <Infos>
                                <span>{t("words.joined")}</span>
                                <span>
                                    <DateFormat date3L={false} date={details.created_at}/>
                                </span>
                            </Infos>
                        </RightDiv>
                    </DetailsDiv>
                    <Title>{t("words.tags")}</Title>
                    <TagsDiv>
                        <SubTitle>{t("words.your_tags")}</SubTitle>
                        <Select options={options} onChange={opt => setLeadTags(opt)} value={leadTags} isMulti id="tags"
                                styles={customStyles}/>
                        <TagBtnDiv>
                            <Button classes={{root: BtnBlue.root, label: BtnBlue.label}} onClick={toggleNewTagModal}>
                                <AddCircleIcon style={{paddingRight: "5px"}}/>
                                {t("words.tags")}
                            </Button>
                        </TagBtnDiv>

                    </TagsDiv>
                    <Title>{t("words.observation")}</Title>
                    <Input type="textarea" name="text" id="observationArea" className="textFields" value={obs}
                           onChange={(e) => setObs(e.target.value)}/>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleMemberModal}>
                            {t("words.cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                onClick={() => updateMember(obs, leadTags)}>
                            {t("words.save")}
                        </Button>
                    </div>
                </ModalFooter>

                {nameError === true &&
                    <AlertNotify type="error" time={6000} message={t("words.tag_required")} state={true}/>}
                {doneAlert === true && <AlertNotify type="success" time={3000} message={t("words.done")} state={true}/>}
                {generalError === true &&
                    <AlertNotify type="error" time={3000} message={t("words.unable_complete")} state={true}/>}
            </CustomModal>

            <CustomModal isOpen={newTagModal} toggle={toggleNewTagModal}>
                <ModalHeader>{t("words.create_tag")}</ModalHeader>
                <ModalBody>
                    <Title>{t("words.tag_name")}</Title>
                    <NewTagDiv>
                        <Input onChange={(e) => setTagName(e.target.value)} name="tagName" value={tagName} type="text"
                               id="newTag" placeholder={t("words.tag_name")}/>
                    </NewTagDiv>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{root: BtnCancel.root, label: BtnCancel.label}} onClick={toggleNewTagModal}>
                            {t("words.cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button type="submit" classes={{root: BtnSave.root, label: BtnSave.label}}
                                onClick={() => createTag(tagName)}>
                            {t("words.save")}
                        </Button>
                    </div>
                </ModalFooter>

                {nameError === true &&
                    <AlertNotify type="error" time={6000} message={t("words.tag_required")} state={true}/>}
                {doneAlert === true && <AlertNotify type="success" time={3000} message={t("words.done")} state={true}/>}
                {generalError === true &&
                    <AlertNotify type="error" time={3000} message={t("words.unable_complete")} state={true}/>}
            </CustomModal>
        </>
    );
};

export default MemberModal;