import axios_base from "../axios_base";
import axios from "axios";

export const getCampaign = async campaignID => {
    try {
        const {data: campaign} = await axios_base.get(`/campaign/${campaignID}`);
        return campaign;
    } catch (error) {
        console.log(error, "error getting campaign");
    }
};

export const getMembers = async campaignID => {
    const members = await axios_base.get(`/campaign/${campaignID}/member`);
    return members.data;
};

export const getStatistics = async campaignID => {
    const stats = await axios_base.get(`campaign/${campaignID}/statistics`);
    return stats.data;
};

export const archiveCamp = async (campaignID, data) => {
    try {
        await axios_base.patch(`/campaign/${campaignID}/archive`, data);
    } catch (error) {
        console.log(error, "error archiving campaign");
    }
};

export const deleteCamp = async campaignID => {
    try {
        await axios_base.delete(`/campaign/${campaignID}`);
    } catch (error) {
        console.log(error, "error at deleting");
    }
};

export const getMemberDetail = async (campaignID, memberID) => {
    try {
        const detail = await axios_base.get(`campaign/${campaignID}/member/${memberID}`);
        return detail.data;
    } catch (error) {
        console.log(error, "error getting member details");
    }
};

export const createNewTag = async (campaignID, data) => {
    await axios_base.post(`campaign/${campaignID}/tag`, data);
};

export const fetchTags = async campaignID => {
    const tags = await axios_base.get(`/campaign/${campaignID}/tag`);
    return tags.data;
};

export const updateMemberDetail = async (campaignID, memberID, data) => {
    await axios_base.patch(`campaign/${campaignID}/member/${memberID}`, data);
};

export const getMessageList = async campaignID => {
    const msgs = await axios_base.get(`/campaign/${campaignID}/message`);
    return msgs.data;
};

export const createMessage = async (campaignID, data) => {
    const options = {
        method: "POST",
        url: `${process.env.REACT_APP_LINK_API}/campaign/${campaignID}/message`,
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
    };
    const msg = await axios.request(options);
};

export const updateMessage = async (campaignID, messageID, data) => {
    const options = {
        method: "patch",
        url: `${process.env.REACT_APP_LINK_API}/campaign/${campaignID}/message/${messageID}`,
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
    };
    const editMsg = await axios.request(options);
};

export const getMessageDetail = async (campaignID, messageID) => {
    try {
        const details = await axios_base.get(`/campaign/${campaignID}/message/${messageID}`);
        return details.data;
    } catch (error) {
        console.log(error, "error getting message details");
    }
};

export const deleteMessage = async (campaignID, messageID) => {
    try {
        await axios_base.delete(`/campaign/${campaignID}/message/${messageID}`);
    } catch (error) {
        console.log(error, "error deleting message");
    }
};

export const getGroups = async (campaignID) => {
    try {
        const {data: groups} = await axios_base.get(`/campaign/${campaignID}/group`);
        return groups;
    } catch (error) {
        return error;
    }
};

export const deleteGroups = async (campaignId, groupId, jid) => {
    try {
        var data = {
            "gid": jid,
        };
        const {data: groups} = await axios_base.delete(`/campaign/${campaignId}/group/${groupId}`, data);
        return groups;
    } catch (error) {
        return error;
    }
};

export const exportLeadas = async (campaignId) => {
    try {
        const {data: listLeads} = await axios_base.post(`campaign/${campaignId}/member/export`);
        return listLeads;
    } catch (error) {
        return error;
    }
};


export const editCampaignFunction = async (num, campaignName, GruoupName, description, imageGroup, valueGroup, valuePeople, valueInitial, valuePermission, adm, token, id, hasAccessTag, facebookPixelEventName, facebookPixelId) => {
    try {
        var data = new FormData();
        ;
        data.append("number_id", parseInt(num));
        data.append("name", campaignName.trim());
        data.append("group_name", GruoupName.trim());
        data.append("group_description", description);
        data.append("group_image", imageGroup);
        data.append("group_count", valueGroup);
        data.append("member_count_per_group", valuePeople);
        data.append("initial_group_number", valueInitial);
        data.append("enable_member_repeat", valuePermission);
        data.append("skip_group_changes", hasAccessTag);
        data.append("facebook_pixel_event_name", facebookPixelEventName);
        data.append("facebook_pixel_id", facebookPixelId);
        adm.map(contacts => (contacts.value)).forEach(contacts => data.append("administrators", contacts));
        const options = {
            method: "PATCH",
            url: `${process.env.REACT_APP_LINK_API}/campaign/${parseInt(id)}`,
            headers: {
                "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        const {data: editedCampaign} = await axios.request(options);
        return editedCampaign;
    } catch (error) {
        console.log(error.response);
    }
};

export const createCampaignFunction = async (num, campaignName, GruoupName, description, imageGroup, valueGroup, valuePeople, valueInitial, valuePermission, adm, token, facebookPixelEventName, facebookPixelId) => {
    var data = new FormData();
    ;
    data.append("number_id", parseInt(num));
    data.append("name", campaignName.trim());
    data.append("group_name", GruoupName.trim());
    data.append("group_description", description);
    data.append("group_image", imageGroup);
    data.append("group_count", valueGroup);
    data.append("member_count_per_group", valuePeople);
    data.append("initial_group_number", valueInitial);
    data.append("enable_member_repeat", valuePermission);
    data.append("facebook_pixel_event_name", facebookPixelEventName);
    data.append("facebook_pixel_id", facebookPixelId);
    adm.map(contacts => (contacts.value)).forEach(contacts => data.append("administrators", contacts));
    const options = {
        method: "POST",
        url: `${process.env.REACT_APP_LINK_API}/campaign`,
        headers: {
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
            Authorization: `Bearer ${token}`,
        },
        data: data,
    };
    const {data: createdCampaign} = await axios.request(options);
    return createdCampaign;
};