import React from "react";
import {Avatar} from "@material-ui/core";
import TransferContact from "../../../Assets/IconesChat/TransferContact.svg";
import {useHistory, useLocation} from "react-router-dom";
import axios_base_chat from "../../../axios_base_chat";
import Snackbar from "@material-ui/core/Snackbar";
import {BtnBlue2, cancelBtn2} from "../../../Styles/buttons";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {Modal, ModalBody} from "reactstrap";
import MuiAlert from "@material-ui/lab/Alert";
import {useChat} from "../../../Providers/chat";
import isMobile from "../../../Services/isMobile";
import DateFormat from "../../../Common/Components/DateFormat";


import {
    Bottom,
    ChatDate,
    ChatLine,
    ChatLines,
    ChatList,
    ChatMessage,
    ChatName,
    Container,
    Icone,
    Middle,
    Top,
    UseStyle,
} from "./styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const ChatOperatorList = ({name, id, image, origin, logged_at, type}) => {

    const classes = UseStyle();
    const blueB = BtnBlue2();
    const CancelBtn = cancelBtn2();
    const {t} = useTranslation();
    const {chat, setChatOpen} = useChat();

    const [modal, setModal] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [isSransferring, setIsSransferring] = React.useState(false);
    const [deviceIsMobile, setDevice] = React.useState(false);

    const [text, setText] = React.useState();

    const handleClose = () => {
        setDone(false);
        setError(false);
    };
    const handClick = () => {
        setModal(!modal);
        setText(null);
    };

    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        setDevice(isMobile());
    }, [location]);

    const transferAttendance = (id, message) => {
        try {
            setIsSransferring(true);
            var data = null;
            if (origin === "transfer_sector") {
                data = {
                    "department_id": id,
                    "operator_id": null,
                    "message": text != null ? text : t("words.transfered_att"),
                };
            } else {
                data = {
                    "department_id": chat.sectorID,
                    "operator_id": id,
                    "message": text != null ? text : t("words.transfered_att"),
                };
            }
            axios_base_chat.patch(`/chat/department/${chat.sectorID}/attendance/${parseInt(localStorage.getItem("chat"))}/transfer`, data)
                .then(res => {
                    setDone(true);
                    setModal(!modal);
                    setIsSransferring(false);
                    localStorage.setItem("chat", null);
                    history.push("/attendant/chat");
                    setChatOpen(false);

                })
                .catch(err => {
                    console.log(err?.response?.data?.message);
                    setError(true);
                    setIsSransferring(false);
                });
        } catch (error) {

        }
    };

    return (
        <Container>

            <ChatList>
                {origin === "transfer_sector" ? <Icone><img src={image} alt={name}/></Icone> :
                    <Avatar alt={name} src={image} style={{width: "50px", height: "50px", marginLeft: "15px"}}/>}
                <ChatLines>
                    <ChatLine>
                        <ChatName>
                            <p>{name}</p>
                        </ChatName>
                        <ChatDate>
                            {origin === "transfer" || origin === "transfer_sector" ?
                                <img src={TransferContact} alt=" " onClick={handClick}/> : "sice"}
                        </ChatDate>

                    </ChatLine>
                    <ChatLine>
                        {origin !== "transfer_sector" &&
                            <ChatMessage>
                                <p>{type === "common" ? t("words.operator") : t("words.manager")}</p>
                            </ChatMessage>}

                        {origin === "transfer" || origin === "transfer_sector" ? <p></p> :
                            <p>{logged_at === "never_logged" ? t("words.never_logged") :
                                <DateFormat date3L={true} date={logged_at}/>}</p>}
                    </ChatLine>
                </ChatLines>
            </ChatList>


            <Modal isOpen={modal} toggle={handClick} className={classes.modal}>
                <ModalBody style={{backgroundColor: "#F3F7FF", borderRadius: "6px", padding: "25px 30px"}}>
                    <Top>
                        <h3>{t("words.transfer_attendance")}</h3>
                    </Top>
                    <Middle>
                        <div>

                            <p>{t("words.transfer_att_desc")} <b>{name}</b></p>
                        </div>
                        <label for="message">{t("words.message")}</label>
                        <div>
                            <textarea id="message" value={text != null ? text : t("words.transfered_att")}
                                      onChange={e => setText(e.target.value)}
                                      placeholder={t("words.transfer_att_placeholder")} className={classes.textArea}
                                      rows="6"></textarea>
                        </div>
                    </Middle>
                    <Bottom>
                        <div>
                            <Button classes={{root: CancelBtn.root, label: CancelBtn.label}} onClick={handClick}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            {isSransferring === true ?
                                <Button classes={{root: blueB.root, label: blueB.label}}
                                        style={{fontSize: deviceIsMobile && "0.6rem"}} disabled>
                                    {t("words.transfer_attendance")}
                                </Button> :
                                <Button classes={{root: blueB.root, label: blueB.label}}
                                        onClick={() => transferAttendance(id, text)}
                                        style={{fontSize: deviceIsMobile && "0.6rem"}}>
                                    {t("words.transfer_attendance")}
                                </Button>}
                        </div>
                    </Bottom>
                </ModalBody>
            </Modal>

            <Snackbar open={done} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t("words.done")}
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t("words.something_wrong")}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ChatOperatorList;