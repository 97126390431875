import styled from "styled-components";

export const Grid = styled.div`
    display: grid;
    grid-template-columns:68px auto;
    grid-template-rows: auto;
    font-family: Mulish,Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    grid-template-areas:
    'AS MH'
    'AS CT';
    height: 100%;
    width: 100% ;
    @media(min-width: 1920px){
        height: 100%;
        width: 100%;
    }

@media(max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: auto;

    grid-template-areas:
    'MH MH'
    'AS CT';
}
    
    ::-webkit-scrollbar {
       width: 8px;
   }

   ::-webkit-scrollbar-track {
       padding-right: 10px;
   }

   ::-webkit-scrollbar-thumb {
       background-color: #BABABA;
       border-radius: 5px;
   }
`;