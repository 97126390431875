import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {ReactComponent as ImageIcon} from "../../../../Assets/icon/image.svg";
import {ReactComponent as VideoIcon} from "../../../../Assets/icon/video.svg";
import {ReactComponent as AudioIcon} from "../../../../Assets/icon/audio.svg";
import {ReactComponent as DocumentIcon} from "../../../../Assets/icon/document.svg";
import axios_base_chat from "../../../../axios_base_chat";
import {useChat} from "../../../../Providers/chat";

function MediaItem({data}: { data: any }) {
    // @ts-ignore
    const {chat} = useChat();

    const [filename, setFilename] = useState();

    async function download() {
        // @ts-ignore
        const {data: {filename}} = await axios_base_chat.get(`chat/department/${chat.sectorID}/attendance/${parseInt(localStorage.getItem("chat"))}/message/${data.key.id}/download`);
        setFilename(filename);
    }

    useEffect(() => {
        download().finally();
    }, []);

    const [media, icon] = useMemo(() => {
        const url = `${process.env.REACT_APP_LINK_API}/storage/${filename}`;

        if (data.message.imageMessage) return [
            <a href={url} target="_blank">
                <Image src={url} loading="lazy"
                       onError={e => e.currentTarget.src = `data:image/jpeg;base64, ${data.message.imageMessage.jpegThumbnail}`}/>
            </a>,
            <ImageIcon/>,
        ];

        if (data.message.videoMessage) return [
            <Video src={url} controls preload="none"
                   poster={`data:image/jpeg;base64, ${data.message.videoMessage.jpegThumbnail}`}/>,
            <VideoIcon/>,
        ];

        if (data.message.audioMessage) return [
            <Audio src={url} preload="none" controls/>,
            <AudioIcon/>,
        ];

        if (data.message.documentMessage) return [
            <Document href={url} target="_blank">{data.message.documentMessage.fileName}</Document>,
            <DocumentIcon/>,
        ];


        return [<div></div>, <div></div>];
    }, [filename, data]);

    return (
        <div>
            <div>
                {media}
            </div>
            <Footer>
                <Icon fromMe={data.key.fromMe}>
                    {icon}
                </Icon>
            </Footer>
        </div>
    );
}

export default MediaItem;

const Footer = styled.div`
    padding: 8px 8px 0;
`;

const Icon = styled.div`
    svg {
        fill: ${({fromMe}: { fromMe: boolean }) => fromMe ? "#fff" : "#0f7bff"};
        width: 20px;
        height: 20px;
    }
`;

const Image = styled.img`
    border-radius: 10px;
    overflow: hidden;
    min-width: 400px;
    max-width: 400px;
    max-height: 400px;
    background-color: whitesmoke;
`;

const Video = styled.video`
    border-radius: 10px;
    overflow: hidden;
    min-width: 400px;
    max-width: 400px;
    max-height: 400px;
    background-color: whitesmoke;
`;

const Audio = styled.audio`
    display: block;
    background: whitesmoke;
    border-radius: 10px;
`;

const Document = styled.a`
    display: block;
    color: inherit;
    padding: 8px 8px 0;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;