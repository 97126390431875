import * as Sentry from "@sentry/react";

const PRODUCTION_HOSTNAME = "wppg.builderall.com";

if (window.location.hostname === PRODUCTION_HOSTNAME) {
    Sentry.init({
        dsn: "https://6691890d12abfdd401bff288499a634b@o505717.ingest.sentry.io/4506309251694592",
        integrations: [
            new Sentry.Replay(),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
