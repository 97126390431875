import {createContext, useContext, useEffect, useState} from "react";
import axios_base from "../axios_base";
import axios from "axios";

const sessionContext = createContext({
    user: {
        settings: {
            attendance: false,
        },
    },
});

export const SessionProvider = ({children}) => {
    const [session, setSession] = useState(null);

    const auth = axios_base.defaults.headers["Authorization"];

    useEffect(() => (async () => {
        if (!auth || /^\/campaign\/(.*)\/invite\/?$/.test(window.location.pathname)) return;
        const {data: user} = await axios({
            method: "get",
            url: `${process.env.REACT_APP_LINK_API}/user`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        });
        setSession({user});
    })(), [auth, localStorage.getItem("token")]);

    return (
        <sessionContext.Provider value={session}>
            {children}
        </sessionContext.Provider>
    );
};

export const useSession = () => useContext(sessionContext);