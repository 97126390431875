const theme = {
    title: "light",

    color: {
        primary: "#f7f8fa",
        secondary: "#E9EDF2",
        tertiary: "#0294FF",

        white: "#000",
        black: "#FFF",
        gary: "#BFBFBF",

        success: "#48D2A0",
        info: "#F7931B",
        warning: "#F54B5E",
        p: "#5a748b",
    },
};

export default theme;