import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div`
    grid-area: AS;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #26242424;
    opacity: 1;
    width: 68px;
`;

export const Menu = styled.div`
    transition: all  .2s;
    height: calc(100vh - 60px);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 3px rgb(38 36 36 / 14%);
   
`;

export const Btn = styled.div`
     height: 60px;
     display: flex;
     background: #F4F4F4 0% 0% no-repeat padding-box;
     margin-bottom: 1px;
     border-bottom: 1px solid hsla(0,0%,87.1%,.7);
     border-right: 1px solid hsla(0,0%,87.1%,.7);
     
     > img {
        width: 29px;
        height: 15px;
     }
`;
export const UseStyle = makeStyles((theme) => ({
    iten: {
        color: "#A5B9D5",
        "&:hover": {
            color: "#0F7BFF",
        },
    },
}));

export const Iten = styled.div`
     display: flex;
     
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 5%;
    color: #A5B9D5;
    font: normal normal 600 1rem/2rem Mulish;
    letter-spacing: 0px;
    cursor: pointer;
    &:hover{
        background: #F4F7FC;
        color: #0F7BFF;
    }
    >div {
        padding-left: 5%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all ease 0.1s;

        text-align: center;
        font: normal normal 600 0.9rem Mulish;
        letter-spacing: 0px;
    }
`;

export const Line = styled.div`

`;
export const SmallTitle = styled.small`
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    color: #434343;
    opacity: 0.5;
    letter-spacing: 0.45px;
    text-align: left;
    padding: 5px;
`;