import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {LightTooltip} from "../../../../Styles/styles";
import {useTranslation} from "react-i18next";
import SectorActive from "../../../../Assets/attendance/Sector  Active.svg";

import {Container, GlobalCss, TopDiv, useStyles} from "./styled";

const DrawerSectorSelect = ({sectorList, selctFunc}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState({bottom: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({...state, [anchor]: open});
    };
    return (
        <Container>
            <Button onClick={toggleDrawer("bottom", true)}><img alt="config" src={SectorActive}/></Button>

            <Drawer anchor="bottom" open={state["bottom"]} onClose={toggleDrawer("bottom", false)}>
                <GlobalCss/>
                <div className={clsx(classes.list, classes.fullList)} role="presentation"
                     onClick={toggleDrawer("bottom", false)} onKeyDown={toggleDrawer("bottom", false)}>
                    <TopDiv>
                        <div style={{
                            background: "#DCE4EF 0% 0% no-repeat padding-box",
                            borderRadius: "2px",
                            width: "31px",
                            height: "3px",
                            marginBottom: "15px",
                        }}></div>
                        <small>{t("words.sectors")}</small>
                    </TopDiv>
                    <Divider style={{margin: "0 3%"}}/>
                    <List>
                        {sectorList?.map((sec) => (
                            <LightTooltip title={t("words.sectors")} placement="top">
                                <ListItem button onClick={() => selctFunc(sec.id, sec.name)}>
                                    <ListItemIcon><img src={SectorActive} alt=""/></ListItemIcon>
                                    <ListItemText primary={sec.name}/>
                                </ListItem>
                            </LightTooltip>
                        ))}
                    </List>
                </div>
            </Drawer>
        </Container>
    );
};

export default DrawerSectorSelect;