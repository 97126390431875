import React from "react";
import {Redirect, Route} from "react-router-dom";
import WS from "../ws";

const isAuth = () => {
    if (localStorage.getItem("operator_token") !== null) {
        if (!WS.initialized) {
            WS.init(localStorage.getItem("operator_token"), true);
        }
        return true;
    }
    return false;
};


const PrivateRouteChat = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/unauthorized",
                            state: {message: "Usuário não autorizado"},
                        }}
                    />
                )}
        />
    );
};

export default PrivateRouteChat;