import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div`
    width: 100%;
    justify-content: space-between;
    margin-bottom: 25px;
    display: flex;

    @media(max-width: 800px){
        display: block;
    }
`;
export const TitleContainer = styled.div`
    > h1{
            
        };
`;
export const TitleH1 = styled.h1`
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    font-size: 1.7rem;
    color: ${props => props.theme.color.tertiary};
    text-align: left;
    letter-spacing: 0px;
    padding-top: 10px;
    opacity: 1;
    &::after{
        content: '';
        display: block;
        width: 55px;
    }
`;
export const SmallNumber = styled.small`
    font: normal 600 1rem Mulish;
    letter-spacing: 0.45px;
    color: #434343;
    opacity: 0.75;
    text-align: left;
    max-width: 100%;
`;
export const Line = styled.div`
    padding-top: 15px;
    display: flex;
`;
export const LineContent = styled.div`
    height: 40px;
    width: 180px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SmallTitle = styled.small`
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    color: #434343;
    opacity: 0.5;
    letter-spacing: 0.45px;
    text-align: left;
`;

export const SmallStatus = styled.small`
    font: normal 800 1rem Mulish;
    letter-spacing: 0.45px;
    opacity: 1;
`;

export const Controllers = styled.div`
    justify-content: space-between;
    align-items: center;
    display: flex;
`;


export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));
