import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {LightTooltip} from "../../../../Styles/styles";
import {useTranslation} from "react-i18next";
import Tosign from "../../../../Assets/IconesChat/To sign.svg";
import Donotsign from "../../../../Assets/IconesChat/Do not sign.svg";

import {Container, GlobalCss, TopDiv, useStyles} from "./styles";

const DrawerChatArea = ({status, changeFunc}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const [state, setState] = React.useState({bottom: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({...state, [anchor]: open});
    };
    return (
        <Container>
            {status ?
                <Button onClick={toggleDrawer("bottom", true)}><img alt="config" src={Tosign}/></Button> :
                <Button onClick={toggleDrawer("bottom", true)}><img alt="config" src={Donotsign}/></Button>}

            <Drawer anchor="bottom" open={state["bottom"]} onClose={toggleDrawer("bottom", false)}>
                <GlobalCss/>
                <div className={clsx(classes.list, classes.fullList)} role="presentation"
                     onClick={toggleDrawer("bottom", false)} onKeyDown={toggleDrawer("bottom", false)}>
                    <TopDiv>
                        <div style={{
                            background: "#DCE4EF 0% 0% no-repeat padding-box",
                            borderRadius: "2px",
                            width: "31px",
                            height: "3px",
                            marginBottom: "15px",
                        }}></div>
                        <small>{t("words.to_sign")}</small>
                    </TopDiv>
                    <Divider style={{margin: "0 3%"}}/>
                    <List>
                        <LightTooltip title={t("words.to_sign")} placement="top">
                            <ListItem button onClick={() => changeFunc(true)}>
                                <ListItemIcon><img src={Tosign} alt=""/></ListItemIcon>
                                <ListItemText primary={t("words.to_sign")}/>
                            </ListItem>
                        </LightTooltip>
                        <LightTooltip title={t("words.not_sign")} placement="top">
                            <ListItem button onClick={() => changeFunc(false)}>
                                <ListItemIcon><img src={Donotsign} alt=""/></ListItemIcon>
                                <ListItemText primary={t("words.not_sign")}/>
                            </ListItem>
                        </LightTooltip>
                    </List>
                </div>
            </Drawer>
        </Container>
    );
};

export default DrawerChatArea;