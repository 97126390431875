import styled from "styled-components";

export const Container = styled.div`
    background-color: #F3F7FF;
`;
export const Small = styled.small`
   text-align: left;
   font: normal normal 600 16px/24px Muli; 
   letter-spacing: 0px;    
   color: #6E6E6E; 
   opacity: 1;
`;
export const Small2 = styled.small`
   text-align: left;
font: normal normal bold 16px/30px Muli;
letter-spacing: 0px;
color: #333333;
text-transform: capitalize;
opacity: 1;
`;