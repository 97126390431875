import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div``;

export const Body = styled.div`
    background-color: #F3F7FF;
    padding: 0 40px;
    font-family: 'Mulish';

        .css-yk16xz-control {
            background: #FAFBFC 0% 0% no-repeat padding-box;
            border: 1px solid #D0DAED;
            border-radius: 4px;
            opacity: 1;
            font-family: Mulish;
        }

        .css-yk16xz-control:hover {
            border-color: #D0DAED;
        }

        .css-1pahdxg-control {
            background: #FAFBFC 0% 0% no-repeat padding-box;
            border-color: #D0DAED;
            box-shadow: 0 0 0 1px #D0DAED;
            font-family: Mulish;
        }

        .css-1pahdxg-control:hover {
            border-color: #D0DAED;
        }
`;
export const Block = styled.div`
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;
    opacity: 1;
    width: 100%;

    > p {
        font: normal normal 600 15px Mulish;
        color: #6E6E6E;
        padding-left: 20px;
        padding-top: 20px;
    }

    .radioSign {
        padding: 10px;
        padding-left: 20px;
    }

    .MuiTypography-body1 {
        font: normal normal 600 15px Mulish;
        color: #6E6E6E;
    }
`;

export const Titles = styled.div`
    padding-top: 25px;
    padding-bottom: 10px;
    text-align: left;
    font: normal normal 600 16px Mulish;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
`;
export const InputArea = styled.div` 
    height: 185px;
`;

export const QuickAnswerDiv = styled.div`
    padding: 20px;
    padding-top: 0;
    display: flex;

    .selectDiv {
        width: 57%;
        margin-right: 10px;
    }
`;

export const UseStyle = makeStyles((theme) => ({
    modal: {
        backgroundColor: "#F3F7FF ",
        boxShadow: "0px 14px 32px #00000040",
        borderRadius: "10px",
        opacity: "1",
        maxWidth: "649px",
        alignContent: "center",
        alignItems: "center",

        "& .modal-header": {
            borderBottom: "0",
            marginBottom: "-10px",

            "& span": {
                textTransform: "uppercase",
                color: "#434343",
                marginLeft: "23px",
                fontFamily: "Mulish",
                fontWeight: "600",
            },
        },

        "& .modal-footer": {
            borderTop: "0",
            paddingRight: "35px",
            paddingTop: "50px",
        },
    },
    backgroundColor: {
        backgroundColor: "#F3F7FF ",
    },
    textArea: {
        width: "100%",
        height: "100%",
        border: "1px solid #D0DAED",
        outline: 0,
        backgroundColor: "#FFFFFF ",
        fontSize: "14px",
        color: "#4a4a4a",
        padding: "15px",
        overflow: "scroll",
    },

}));
