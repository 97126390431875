import React from "react";
import "./style.css";

import {
    Container,
} from "./styles";

const SoundWave = () => {

    return (
        <Container>
            <div className="div">
                <span className="span"></span>
                <span className="span"></span>
                <span className="span"></span>
                <span className="span"></span>
                <span className="span"></span>
            </div>
        </Container>
    );
};

export default SoundWave;