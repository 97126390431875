import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div`
`;
export const Div = styled.div`
 width: 70vw;
 height: 95vh;
 overflow: auto;

  @media(max-width: 1280px){
    overflow: auto;
  }

  @media(min-width: 1920px){
    width: 60vw;
    height: 75vh;
  }

  .warningDiv {
    font-family: 'Mulish';
    font-weight: bold;
    font-size: 15px;
    margin-top: 20px;
  }
`;

export const Title = styled.div`
  margin-top: 5%;
  text-align: center;

  > h1{
    font-size: 25px;
    font-family: 'Mulish';
    font-weight: 900;
    color: ${props => props.theme.color.tertiary};
    padding-bottom: 55px;

  }
`;

export const SubDiv1 = styled.div`
  padding-top: 10px;
  > pre{
    font-size: 15px;
    font-weight: bold;
    font-family: 'Mulish';
  };

`;

export const SubDiv2 = styled.div`
padding-top: 40px;
  > h1{
    font-size: 22px;
     font-family: 'Mulish';
     padding-bottom: 35px;
  };
  > div{
    font-size: 15px;
    font-weight: bold;
    font-family: 'Mulish';
  };
`;

export const QrCode = styled.div`
  padding: 40px;  
  margin-left: 20px;
  width: 278px;
  height: 283px;
  background-color: #FCFCFC;
  box-shadow: 0px 8px 15px #00000040;
  border-radius: 30px;
  opacity: 1;
  svg {
    cursor: none;
  }
`;


export const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 30,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "none",

    },
    reload: {
        width: "200px",
        height: "200px",
        borderRadius: "100%",
        textAlign: "center",
        backgroundColor: "#0294FF",
    },
    error: {
        width: "200px",
        height: "200px",
        borderRadius: "100%",
        textAlign: "center",
        backgroundColor: "#FD1A5A",

    },
    center: {
        width: "150px",
        height: "100px",
        textAlign: "center",
        color: "white",
        paddingTop: "60px",
        paddingLeft: "50px",
        cursor: "pointer",
    },
}));

export const DivG = styled.div`
@media(max-width: 900px) {
    display: none;
  }
`;