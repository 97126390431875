import styled from "styled-components";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {HiOfficeBuilding} from "react-icons/hi";
import TabList from "@material-ui/lab/TabList";
import {components} from "react-select";
import Tab from "@material-ui/core/Tab";

export const Container = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 6px;
    border-radius: 6px;
    opacity: 1;
    box-sizing: border-box;
    height: 85vh;
    @media(min-width: 1920px){
        height:90vh;
        
    }
    @media(max-width: 600px){
        height: 100vh;
        width: 100vw;
    }
    overflow-y: hidden;
`;

export const Top = styled.div`
    display: flex;
    background: #E9EDF2;
    border-radius: 6px 6px 0px 0px;
    
    align-items: center;
    padding: 2% 2%;
    height: 65px;
    border-bottom: 1px solid #DCE4EF;
`;
export const ButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.25rem;
    padding-left: 0.25rem;

    > h4 {
        font-size: 16px;
        font-weight: bold;
        color: #0072FF;
    }
`;

export const Middle = styled.div`
    padding: 20px 15px;
    align-items: center;
    justify-content: center;
    background-color: #F9FAFC  ;  
    height: 123px;
    border-bottom: 1px solid #DCE4EF;
    box-shadow: 0px 1px 4px #0000001A;
    
    >p {
        letter-spacing: 0px;
        color: #6E6E6E;
        opacity: 1;
        text-align: left;
        
        font-size: 15px;
        font-weight: 600;
    }
`;


export const Search = styled.div`
    padding: 15px 15px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    padding-bottom: 20px ;
`;

export const SearchInput = styled.div`
    background: #E9EDF2 0% 0% no-repeat padding-box;
    height: 46px;
    display: flex;
    align-items: center;
    >input{
        flex:1;
        border: 0;
        outline: 0;
        background: #E9EDF2 0% 0% no-repeat padding-box;
        margin-left: 10px ;
    }
`;
export const Config = styled.div`
    color: #A5B9D5 ;
    &:hover{
        color: #787878 ;
    }
`;
export const Botton = styled.div`
    height: calc(100vh - 12%);
    padding-bottom: 15px;
    overflow-y: scroll;

    @media(min-width:  600px){
        height: 83%;   
        padding-bottom: 20px;
    }
    @media(min-width:  680px){
        height: 85%;   
        padding-bottom: 20px;
    }
    @media(min-width:  780px){
        height: 90%;   
        padding-bottom: 20px;
    }
`;

export const BottonContacts = styled.div`
      overflow-y: scroll;
`;

export const Contacts = styled.div`
    height: 200px;
    overflow-y: hidden;
    transition: all ease 0.3s;
    background: #F7F8FA ;
`;

export const Attendents = styled.div`
    height: 200px;
    overflow-y: hidden;
    transition: all ease 0.3s;
`;

export const Archived = styled.div`
    height: 200px;
    overflow-y: hidden;
    transition: all ease 0.3s;
`;
export const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
        borderBottom: "1px solid rgba(220, 228, 239, .5)",
        color: "#434343",
        fontSize: "1rem",
        fontWeight: "600",
        fontFamily: "Mulish",
    },
    popover: {
        fontWeight: "600",
        fontFamily: "Mulish",
        "& .MuiPaper-rounded": {
            borderRadius: "10px",
        },
        "& .MuiPaper-elevation8": {
            boxShadow: "0px 3px 8px #00000059",
        },
        "& .MuiPopover-paper": {
            width: "19.75rem",
        },
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
    TabPanelStyle: {
        height: "100%",
        overflow: "scroll",
        margin: 0,
        padding: 0,
    },
    AppBarStyle: {
        paddinTop: "0",
        marginBottom: "0",
        backgroundColor: "#FFFFFF",
        textAlign: "left",
        alignItems: "left",
        color: "#000",
        boxShadow: "0 0px 0px 0px",
        borderBottom: "1px solid #D0DAED",
        "& > span": {
            backgroundColor: "#0080FC",
        },
    },
    AppTab: {
        color: "#0080FC",
        fontWeight: "bold",
    },
    heading: {
        textAlign: "left",
        font: "normal normal 600 15px/22px Mulish",
        color: "#0F7BFF",
        opacity: 1,
    },
    aco: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E1E5EB",
        borderRadius: "6px 6px 0px 0px",
        opacity: "1",
    },
    border: {
        borderBottom: "1px solid #E1E5EB",
    },

}));

export const Control = ({children, ...props}) => {
    const style = {cursor: "pointer", padding: "0 10px", fontSize: "large"};
    return (
        <components.Control {...props}>
            <span style={style}>
                <HiOfficeBuilding style={{color: "#0080FC "}}/>
            </span>
            {children}
        </components.Control>
    );
};
export const TabListStyled = withStyles((theme) => ({
    indicator: {
        backgroundColor: "#0080FC",
        borderRadius: "6px",
    },

}))(TabList);

export const TabStyled = withStyles((theme) => ({}))(Tab);

export const SmallSuport = styled.div`
    font: normal normal 600 16px/24px Muli;
    letter-spacing: 0px;
    color: #6E6E6E;
    opacity: 1;
`;