import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";

export const Container = styled.div`
    display: flex;
    justify-content: right;
`;

export const Btn = styled.div`
    width: 20px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: right;
    align-items: right;
    transition: all ease 0.2s;
    cursor: pointer;
    color: #0072FF;
    &:hover{
        color: #0072FF;
    }
`;

export const UseStyle = makeStyles((theme) => ({
    emojiPicker: {
        width: "100%",

    },
    popper: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 8px #00000059",
        borderRadius: "10px",
        opacity: "1",
        zIndex: 9999999999999,
    },

}));
