import React from "react";
import {Redirect, Route} from "react-router-dom";
import WS from "../ws";
/* import axios_base from '../axios_base'; */

const isAuth = () => {
    if (localStorage.getItem("token") !== null) {
        if (!WS.initialized) {
            WS.init(localStorage.getItem("token"));
        }
        return true;
    }
    return false;
};

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/unauthorized",
                            state: {message: "Usuário não autorizado"},
                        }}
                    />
                )}
        />
    );
};

export default PrivateRoute;