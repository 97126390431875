import styled from "styled-components";
import {Modal} from "reactstrap";

export const CustomModal = styled(Modal)`
    .modal-content {
        background: #F3F7FF 0% 0% no-repeat padding-box;
        box-shadow: 0px 14px 32px #00000040;
        border-radius: 10px;
        border: 0;
        padding: 1rem;
        width: 40rem;

        @media(max-width: 800px){
            width: 100%;
        }

        font-family: 'Mulish';
        color: #434343;

        .form-control {
            background: #FFFFFF;
            border: 2px solid #D0DAED;
        }
    }
    
    .modal-title {
        font-size: 18px;
        font-weight: bold;
    }    

    .modal-header{
        border-bottom: 0;
    }

    .modal-footer{
        border-top: 0;

        .Mui-disabled { 
            color: #FFFFFF;
            background-color: #5A5959;
        }
    }

`;

export const Label = styled.h6`
    color: #6E6E6E;
    font-size: 16px;
    font-weight: 600;
`;

export const OutsideDiv = styled.div`
    background: #FFFFFF;
    border: 1px solid #D0DAED;
    border-radius: 3px;
`;

export const InsideDiv = styled.div`
    width: 50%;
    padding: 25px;
`;

export const MsgOptionsDiv = styled.div`
    display: flex;
    border-bottom: 1px solid #CAD4E6;
`;

export const IconDiv = styled.div`
    padding: 0 20px;
    cursor: pointer;
`;

export const StyledDiv = styled.div`
    border-bottom: 3px solid #0080FC;
    color: #0080FC;
    padding-bottom: 15px;
    width: 100%;
    font: normal normal 14px 800 Mulish;
    transition: width 205ms;
`;

export const RegularDiv = styled.div`
    padding-bottom: 15px;
    font: normal normal 14px 800 Mulish;
    color: #434343;
    width: 0;
`;

export const StyledTextArea = styled.textarea`
    width: 100%;
    border: 1px solid #D0DAED;
    outline: 0;
    background-color: #FFFFFF;
    font-size: 14px;
    color: #4a4a4a;
    padding: 15px;
    overflow: auto;
    border-radius: 3px;
`;

export const MediaSelectDiv = styled.div`
    background-color: #FAFBFC80;
    border: 2px dashed #D0DAED;
    border-radius: 3px;
    height: 176px;
    text-align: center;
    margin: auto;
    justify-content: center;
    display: 'flex';
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

export const FileLabel = styled.label`
    padding-top: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    
    > h6, small {
        font-weight: 600;
        color: #6E6E6E;
        margin-bottom: 0;
    }

    > h6 {
        margin-top: 5px;
    }

`;

export const FileLabelVideo = styled.label`
    padding-top: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer; 
    text-align: center;
    
    > h6, small {
        font-weight: 600;
        color: #6E6E6E;
        margin-bottom: 0;
    }

    > h6 {
        margin-top: 5px;
    }

`;

export const DeleteBtnDiv = styled.div`
    display: flex;
    justify-content: right;
    padding-top: 15px;
    padding-right: 15px;

    > img {
        width: 15px;
        cursor: pointer;
    }
`;

export const StartRecordDiv = styled.div`
    margin: 50px auto;
`;

export const RecordingDiv = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;

    > div {
        width: 95px;
        margin: 50px 10px;
    }
`;

export const BtnLabelDiv = styled.div`
    .Mui-disabled { 
            color: #FFFFFF;
            background-color: #434343;
        }
`;

