import React from "react";
import ReactDOM from "react-dom";
import {Input, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import SchedulingMessage from "../../../Assets/attendance/schedule messages.svg";
import AudioActive from "../../../Assets/attendance/AudioActive.svg";
import AudioDesactive from "../../../Assets/attendance/Audio type.svg";
import ImageActive from "../../../Assets/attendance/Image type  Active - Hover.svg";
import ImageDesactive from "../../../Assets/attendance/Image type.svg";
import TrashSvh from "../../../Assets/attendance/Trash  Active - Hover.svg";
import editSvg from "../../../Assets/attendance/edit.svg";
import Bluecheck from "../../../Assets/attendance/Blue check.svg";
import Toupdate from "../../../Assets/attendance/To update.svg";
import Selectvideo from "../../../Assets/attendance/Selectvideo.svg";
import Selectimage from "../../../Assets/attendance/Selectimage.svg";
import VideoActive from "../../../Assets/attendance/VideoActive.svg";
import VideoDesactive from "../../../Assets/attendance/VideoDesactive.svg";
import MessageActive from "../../../Assets/attendance/MessageActive.svg";
import MessageDesactive from "../../../Assets/attendance/Message type.svg";
import TyperecordDesactive from "../../../Assets/attendance/TyperecordDesactive.svg";
import TyperecordActive from "../../../Assets/attendance/TyperecordActive.svg";
import TyperecordWith from "../../../Assets/attendance/TyperecordWith.svg";
import Noschedule from "../../../Assets/attendance/No schedule message.svg";
import StopIcon from "@material-ui/icons/Stop";
import "react-phone-number-input/style.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import {Block, Body, Container, DivLogo, Line, messagList, StyledDiv, StyledDivF, Text, UseStyle} from "./styles";
import SoundWave from "../../../Animations/SoundWaves";
import axios from "axios";
import AlertNotify from "../../../Common/Components/Alert";
import ChatSchedulingMessageList from "../ChatSchedulingMessageList";
import axios_base_chat from "../../../axios_base_chat";
import dayjs from "dayjs";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

const ChatSchedulingMessage = ({who, id, open, from}) => {

    const {t} = useTranslation();
    const history = useHistory();
    const classes = UseStyle();
    const btnSave = saveBtn2();
    const btnCancel = cancelBtn2();

    const [day, setDay] = React.useState();
    const [time, setTime] = React.useState();
    const [message, setMessage] = React.useState();
    const [name, setName] = React.useState();
    const [recordedAudio, setRecordedAudio] = React.useState();
    const [backgroudImage, setBackgroundImage] = React.useState();
    const [type, setType] = React.useState("text");

    const [newChat, setNewChat] = React.useState(false);
    const [openInfor, setInfo] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [empty, setEmpty] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [sizeError, setSizeError] = React.useState(false);

    const [cont1, setcont1] = React.useState(false);
    const [cont2, setcont2] = React.useState(false);
    const [cont3, setcont3] = React.useState(false);

    const [update, setUpdate] = React.useState(false);

    const [aba, setAba] = React.useState(false);

    const [secondStatus, setSecondStatus] = React.useState("waiting");


    const newContactToggle = () => {
        setNewChat(!newChat);
        reset();
    };

    const UploadImage = (event) => {
        var roundTo = null;
        var converted = event?.target?.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        setMessage();
        if (roundTo > 12) {
            event.target.value = null;
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        } else {
            setMessage(event?.target?.files[0]);
            setBackgroundImage(URL.createObjectURL(event?.target?.files[0]));

        }
    };
    const UploadVideo = (event) => {
        var roundTo = null;
        var converted = event?.target?.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo <= 12) {
            setMessage(event.target?.files[0]);
            const video = document.getElementById("video");
            video?.setAttribute("src", URL.createObjectURL(event?.target?.files[0]));
            video?.load();
            video?.play();

        } else {
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        }
    };

    const [recording, setRecording] = React.useState(false);
    const [permission, setpermission] = React.useState();
    let mediaRecorder;

    navigator
        ?.mediaDevices
        ?.getUserMedia({audio: true})
        .then(stream => {
            setpermission("allowed");
            mediaRecorder = new MediaRecorder(stream);
            let chunks = [];
            mediaRecorder.ondataavailable = data => {
                chunks.push(data.data);

            };
            mediaRecorder.onstop = () => {
                const blob = new Blob(chunks, {type: "audio/mp3; codecs=opus"});
                setMessage(blob);
                const reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const audio = document.createElement("audio");
                    audio.src = reader.result;
                    audio.controls = true;
                };
            };
        }, err => {
            setpermission("denied");
            console.log(err);

        });

    const startRecorder = () => {
        setRecording(true);
        if (permission === "denied") {
            setRecording(false);
            alert(`${t("words.erro_microphone_use")}`);
        } else {
            mediaRecorder.start();
            ReactDOM.render(<Button onClick={stopRecorder} variant="contained" className={classes.icomRed}
                                    startIcon={<StopIcon
                                        fontSize="large"/>}>{t("words.stop")}</Button>, document.getElementById("control"));
            ReactDOM.render(<div style={{width: "100%", paddingLeft: "50%"}}><SoundWave/>
            </div>, document.getElementById("audioControls"));
        }
    };
    const stopRecorder = () => {
        if (permission === "denied") {
            alert(`${t("words.erro_microphone_use")}`);
        } else {
            if (mediaRecorder.state !== "recording") {
                return;
            } else {
                mediaRecorder.stop();
                ReactDOM.render(<div></div>, document.getElementById("control"));
                ReactDOM.render(<div></div>, document.getElementById("audioControls"));
            }
        }

    };
    const cancelRecordAudio = () => {
        setRecording(false);
        setMessage();
        setRecordedAudio();
    };

    const [audio, setAudio] = React.useState();
    const UploadAudio = (event) => {
        var roundTo = null;
        var converted = event.target.files[0]?.size / (1024 * 1024);
        roundTo = converted.toFixed(roundTo);
        if (roundTo > 12) {
            setSizeError(true);
            setTimeout(() => {
                setSizeError(false);
            }, 6000);
        } else {
            setMessage(event.target?.files[0]);
            setAudio(URL.createObjectURL(event.target.files[0]));
        }
    };
    const cancelAudio = () => {
        setAudio();
        setMessage();
    };

    const reset = () => {
        setName();
        setDay();
        setTime();
        setMessage();
        setAudio();
        setRecordedAudio();
        setType("text");
        setUpdate(false);
        setcont1(false);
        setAba(false);

    };

    const createMessage = (name, message, day, time, type, messageId) => {
        if (!message || !message || !day || !time || !type) {
            setEmpty(true);
        } else {
            setInfo(true);
            if (type === "recorderAudio") {
                type = "audio";
            }
            try {
                var date = new Date(day + " " + time);
                var dateFormated = date.toISOString();
                var data = new FormData();
                data.append("name", name);
                data.append("send_at", dateFormated);
                data.append("content_type", type);
                data.append("content", message);


                var url = null;
                var method = null;
                if (update === true) {
                    url = `${process.env.REACT_APP_LINK_API}/chat/contact/${parseInt(localStorage.getItem("contact"))}/message/${messageId}`;
                    method = "PATCH";
                } else {
                    url = `${process.env.REACT_APP_LINK_API}/chat/contact/${parseInt(localStorage.getItem("contact"))}/message`;
                    method = "POST";
                }
                const options = {
                    method: method,
                    url: url,
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
                        Authorization: `Bearer ${localStorage.getItem("operator_token")}`,
                    },
                    data: data,
                };

                axios.request(options).then(res => {
                    setInfo(false);
                    setSuccess(true);
                    reset();
                    newContactToggle();
                    history.push(`/attendant/chat`);
                }).catch(function (error) {
                    setInfo(false);
                    setError(true);
                });
            } catch (error) {

            }
        }

    };

    const [editId, setEdditId] = React.useState();
    const setEditing = async (contact, id) => {
        try {
            setEdditId(id);
            const {data: res} = await axios_base_chat.get(`/chat/contact/${contact}/message/${id}`);
            setDay(dayjs(res.send_at).format("YYYY-MM-DD"));
            setTime(dayjs(res.send_at).format("HH:mm"));
            setName(res.name);
            setType(res.type);
            setMessage(res.content);
            setUpdate(true);
            setAba(false);
            if (res.type === "text") {

            } else if (res.type === "recorderAudio" || res.type === "audio") {
                setType("audio");
                setAudio(`${process.env.REACT_APP_LINK_API}/storage/${res.content}`);
            } else if (res.type === "video") {
                const video = document.getElementById("video");
                video?.setAttribute("src", `${process.env.REACT_APP_LINK_API}/storage/${res.content}`);
                video?.load();
                video?.play();
            } else {
                setBackgroundImage(`${process.env.REACT_APP_LINK_API}/storage/${res.content}`);
            }
        } catch (error) {

        }
    };

    const [messageList, setMessageList] = React.useState([]);
    const getMessageList = async () => {
        try {
            const {data: res} = await axios_base_chat(`/chat/contact/${parseInt(localStorage.getItem("contact"))}/message`);
            setMessageList(res);
        } catch (error) {

        }
    };
    const deleMessage = async (contact, message) => {
        try {
            const {data: res} = await axios_base_chat.delete(`chat/contact/${contact}/message/${message}`);
            getMessageList();
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 6000);
        } catch (error) {
            setError2(true);
            setTimeout(() => {
                setError2(false);
            }, 6000);
        }

    };

    return (
        <Container>
            {who === "new" && <img src={SchedulingMessage} alt="message" onClick={newContactToggle}></img>}
            {from === "mobile" && <>
                <ListItem button onClick={newContactToggle}>
                    <ListItemIcon><img src={SchedulingMessage} alt="message"></img></ListItemIcon>
                    <ListItemText primary={t("words.schedule_message")}/>
                </ListItem></>}
            {who === "edit" && <img src={SchedulingMessage} alt="Contact"/>}

            <Modal isOpen={newChat} toggle={newContactToggle} className={classes.modal}>
                <ModalHeader className={classes.backgroundColor}>
                    <div className={classes.title} style={{padding: "15px"}}><h5>{t("words.schedule_message")}</h5>
                    </div>
                </ModalHeader>
                <div style={{padding: "0 40px", backgroundColor: "#F3F7FF "}}>
                    <StyledDivF className={classes.backgroundColor}>
                        <div onClick={() => setAba(false)} className={classes.title}>
                            {from === "mobile" ?
                                <AddCircleIcon style={{color: aba === false ? "#0080FC" : "#434343"}}/> : <h6 style={{
                                    color: aba === false ? "#0080FC" : "#434343",
                                    textTransform: "uppercase",
                                }}>{t("words.to_schedule")}</h6>}
                            <div className={aba === false ? classes.divStyled : classes.regularDiv}
                                 style={{transition: "width 205ms"}}></div>
                        </div>
                        <div onClick={() => {
                            setAba(true);
                            getMessageList();
                        }} className={classes.title}>
                            {from === "mobile" ? <ScheduleIcon style={{color: aba === true ? "#0080FC" : "#434343"}}/> :
                                <h6 style={{
                                    color: aba === true ? "#0080FC" : "#434343",
                                    textTransform: "uppercase",
                                }}>{t("words.schedule_message_list")}</h6>}

                            <div className={aba === true ? classes.divStyled : classes.regularDiv}
                                 style={{transition: "width 205ms"}}></div>
                        </div>
                    </StyledDivF>
                </div>
                {aba === false ?
                    <Body>
                        <Text>
                            {t("words.message_name")}
                        </Text>
                        <Input onChange={(e) => setName(e.target.value)} type="text" value={name} name="nomeDaMensagem"
                               id="nomeDaMensagem" placeholder={t("words.message_name")} required/>
                        <Text>
                            {t("words.date_sending")}
                        </Text>
                        <Block>
                            <Line>
                                <div style={{width: "50%", justifyContent: "center", padding: "25px"}}>
                                    <label className={classes.textLabel}>{t("words.date")}</label>
                                    <Input onChange={(e) => setDay(e.target.value)} value={day} type="date" name="date"
                                           id="Date" placeholder="Date to send" required/>
                                </div>
                                <div style={{width: "50%", justifyContent: "center", padding: "25px"}}>
                                    <label className={classes.textLabel}>{t("words.hour")}</label>
                                    <Input onChange={(e) => setTime(e.target.value)} value={time} type="time"
                                           name="time" id="time" placeholder="Time to send" required/>
                                </div>
                            </Line>
                        </Block>
                        <Text>
                            {t("words.message_type")}
                        </Text>
                        <Block>
                            <div style={{padding: "10px"}}>
                                <div style={{
                                    display: "flex",
                                    borderBottom: "1px solid #CAD4E6",
                                    justifyContent: "center",
                                }}>
                                    <DivLogo onClick={() => {
                                        setType("text");
                                        setMessage();
                                    }} className={classes.icon}>
                                        <img src={type !== "text" ? MessageDesactive : MessageActive} alt="text"/>
                                        <div className={type === "text" ? classes.divStyled : classes.regularDiv}
                                             style={{transition: "width 205ms"}}></div>
                                    </DivLogo>
                                    <DivLogo onClick={() => {
                                        setType("image");
                                        setMessage();
                                    }} className={classes.icon}>
                                        <img src={type !== "image" ? ImageDesactive : ImageActive} alt="file type"/>
                                        <div className={type === "image" ? classes.divStyled : classes.regularDiv}
                                             style={{transition: "width 205ms"}}></div>
                                    </DivLogo>
                                    <DivLogo onClick={() => {
                                        setType("video");
                                        setMessage();
                                    }} className={classes.icon}>
                                        <img src={type !== "video" ? VideoDesactive : VideoActive} alt="video"/>
                                        <div className={type === "video" ? classes.divStyled : classes.regularDiv}
                                             style={{transition: "width 205ms"}}></div>
                                    </DivLogo>
                                    <DivLogo onClick={() => {
                                        setType("recorderAudio");
                                        setMessage();
                                    }} className={classes.icon}>
                                        <img src={type !== "recorderAudio" ? TyperecordDesactive : TyperecordActive}
                                             alt="audio"/>
                                        <div
                                            className={type === "recorderAudio" ? classes.divStyled : classes.regularDiv}
                                            style={{transition: "width 205ms"}}></div>
                                    </DivLogo>
                                    <DivLogo onClick={() => {
                                        setType("audio");
                                        setMessage();
                                    }} className={classes.icon}>
                                        <img src={type !== "audio" ? AudioDesactive : AudioActive} alt="audio"/>
                                        <div className={type === "audio" ? classes.divStyled : classes.regularDiv}
                                             style={{transition: "width 205ms"}}></div>
                                    </DivLogo>
                                </div>
                            </div>
                            <div style={{padding: "15px"}}>
                                {type === "text" &&
                                    <textarea onChange={e => setMessage(e.target.value)} value={message}
                                              placeholder={t("words.message")} id="exampleFormControlTextarea1"
                                              className={classes.textArea} rows="6"></textarea>
                                }
                                {type === "image" &&
                                    <div>
                                        {!message ?
                                            <div className={classes.selectImagefull}>
                                                <label for="fileImage" className={classes.label}>
                                                    <img src={Selectimage} alt="select"/>
                                                    <h6 for="fileImage">{t("words.image_selected")}</h6>
                                                    <small>{t("words.larger_size_alert")}</small>
                                                    <input onChange={UploadImage} accept="image/*"
                                                           style={{opacity: "0", width: "100%", height: "60%"}}
                                                           id="fileImage" /* multiple */ type="file"/>
                                                </label>
                                            </div>
                                            :
                                            <div className={classes.divFileSelect}>
                                                <div style={{width: "50%", height: "100%"}}>
                                                    <img src={backgroudImage} style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "176px",
                                                        borderRadius: "6px",
                                                    }} alt=""/>
                                                </div>
                                                <label for="fileImage" className={classes.label}>
                                                    <div className={classes.selectImagefull}>
                                                        <img src={Selectimage} alt="select"/>
                                                        <h6 for="fileImage">{t("words.image_selected")}</h6>
                                                        <small>{t("words.larger_size_alert")}</small>
                                                        <input onChange={UploadImage} accept="image/*"
                                                               style={{opacity: "0", width: "100%", height: "60%"}}
                                                               id="fileImage" /* multiple */ type="file"/>
                                                    </div>
                                                </label>
                                            </div>}
                                    </div>
                                }
                                {type === "video" &&
                                    <div>
                                        {!message ?
                                            <div className={classes.selectImagefull}>
                                                <label for="fileVideo" className={classes.label}>
                                                    <img src={Selectvideo} alt="select video"/>
                                                    <h6 for="fileVideo">{t("words.selct_video")}</h6>
                                                    <small>{t("words.larger_size_alert")}</small>
                                                    <input onChange={UploadVideo} accept="video/mp4"
                                                           style={{opacity: "0", width: "100%", height: "60%"}}
                                                           id="fileVideo" type="file"/>
                                                </label>
                                            </div>
                                            :
                                            <div className={classes.divFileSelect}>
                                                <div style={{width: "50%", height: "100%"}}>
                                                    <video id="video" style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "176px",
                                                        borderRadius: "6px",
                                                    }} controls></video>
                                                </div>
                                                <label for="fileVideo" className={classes.label}>
                                                    <div className={classes.selectImagefull}>
                                                        <img src={Selectvideo} alt="select video"/>
                                                        <h6 for="fileVideo">{t("words.selct_video")}</h6>
                                                        <small>{t("words.larger_size_alert")}</small>
                                                        <input onChange={UploadVideo} accept="video/mp4"
                                                               style={{opacity: "0", width: "100%", height: "60%"}}
                                                               id="fileVideo" type="file"/>
                                                    </div>
                                                </label>
                                            </div>}
                                    </div>
                                }
                                {type === "recorderAudio" &&
                                    <div className={!recordedAudio && classes.divFileSelect}>
                                        {recording !== true && !recordedAudio &&
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <h6>{t("words.click_recorder")}</h6>
                                                <Button onClick={startRecorder} variant="contained"
                                                        className={classes.icom} startIcon={<img src={TyperecordWith}
                                                                                                 alt="audio"/>}>{t("words.start")}</Button>
                                            </div>
                                        }
                                        <div id="soundBar" style={{
                                            display: "flex",
                                            alignContent: "center",
                                            width: recording !== true && !recordedAudio ? "0" : "100%",
                                        }}>
                                            <div id="control" style={{
                                                width: "30%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                cursor: "pointer",
                                            }}>

                                            </div>
                                            <div id="audioControls" style={{width: "30%"}}>

                                            </div>
                                        </div>
                                        {!recordedAudio ? <div/> : <div
                                            style={{display: "flex", justifyContent: "space-around", width: "90%"}}>
                                            <div><img src={TrashSvh} alt="trash" style={{cursor: "pointer"}}
                                                      onClick={cancelRecordAudio}/></div>
                                            <audio style={{marginTop: "5px"}} controls src={recordedAudio}></audio>
                                        </div>}
                                    </div>
                                }
                                {type === "audio" &&
                                    <div className={classes.selectImagefull}>
                                        {!message ? <label for="fileAudio" className={classes.label}>
                                            <img src={AudioActive} alt="select video"/>
                                            <h6 for="fileAudio"> {t("words.select_audio")}</h6>
                                            <input onChange={UploadAudio} accept="audio/mp3"
                                                   style={{opacity: "0", width: "100%", height: "60%"}} id="fileAudio"
                                                   type="file"/>
                                        </label> : <div
                                            style={{display: "flex", justifyContent: "space-around", width: "90%"}}>
                                            <div><img src={TrashSvh} alt="trash" style={{cursor: "pointer"}}
                                                      onClick={cancelAudio}/></div>
                                            <audio style={{marginTop: "5px"}} controls src={audio}></audio>
                                        </div>}
                                    </div>
                                }
                            </div>

                        </Block>
                    </Body>
                    :
                    <Body>
                        <div style={StyledDiv} className={classes.backgroundColor}>
                            <div style={{paddingTop: "20px"}}>
                                {from === "mobile" ? <HourglassEmptyIcon
                                    style={{color: secondStatus === "waiting" ? "#0080FC" : "#434343"}}
                                    onClick={() => setSecondStatus("waiting")}/> : <h6 className={classes.title}
                                                                                       style={{color: secondStatus === "waiting" ? "#0080FC" : "#434343"}}
                                                                                       onClick={() => setSecondStatus("waiting")}>{t("words.waiting")}</h6>}
                                <div className={secondStatus === "waiting" ? classes.divStyled : classes.regularDiv}
                                     style={{transition: "width 205ms"}}></div>
                            </div>
                            <div style={{paddingTop: "20px"}}>
                                {from === "mobile" ?
                                    <CheckCircleIcon style={{color: secondStatus === "sent" ? "#0080FC" : "#434343"}}
                                                     onClick={() => setSecondStatus("sent")}/> :
                                    <h6 className={classes.title}
                                        style={{color: secondStatus === "sent" ? "#0080FC" : "#434343"}}
                                        onClick={() => setSecondStatus("sent")}>{t("words.sent")}</h6>}
                                <div className={secondStatus === "sent" ? classes.divStyled : classes.regularDiv}
                                     style={{transition: "width 205ms"}}></div>
                            </div>
                            <div style={{paddingTop: "20px"}}>
                                {from === "mobile" ?
                                    <ErrorIcon style={{color: secondStatus === "failure" ? "#0080FC" : "#434343"}}
                                               onClick={() => setSecondStatus("failure")}/> :
                                    <h6 className={classes.title}
                                        style={{color: secondStatus === "failure" ? "#0080FC" : "#434343"}}
                                        onClick={() => setSecondStatus("failure")}>{t("words.shipping_failure")}</h6>}
                                <div className={secondStatus === "failure" ? classes.divStyled : classes.regularDiv}
                                     style={{transition: "width 205ms"}}></div>
                            </div>
                        </div>
                        <Block>
                            {secondStatus === "waiting" &&
                                <div style={messagList}>
                                    {messageList.filter((list) => {
                                        if (list.status === "pending") {
                                            if (cont1 === false) {
                                                setcont1(true);
                                            }
                                            return list;
                                        } else {
                                            return null;
                                        }
                                    }).map((list) =>
                                        < ChatSchedulingMessageList data={list} key={list.id}
                                                                    icon={< img alt="trash" src={editSvg}
                                                                                onClick={() => setEditing(parseInt(localStorage.getItem("contact")), list.id)}/>}
                                                                    icon2={<img alt="trash" src={TrashSvh}
                                                                                onClick={() => deleMessage(parseInt(localStorage.getItem("contact")), list.id)}/>}/>,
                                    )}
                                    {!cont1 && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                        alignItems: "center",
                                    }}><img style={{height: "10%"}} alt="No Schedule Message" src={Noschedule}/>
                                        <Text>{t("words.empty_list")}</Text></div>}
                                </div>
                            }
                            {secondStatus === "sent" &&
                                <div style={messagList}>
                                    {messageList.filter((list) => {
                                        if (list.status === "completed") {
                                            if (cont2 === false) {
                                                setcont2(true);
                                            }
                                            return list;
                                        } else {
                                            return null;
                                        }
                                    }).map((list) =>
                                        <ChatSchedulingMessageList data={list} key={list.id}
                                                                   icon2={<img alt="trash" src={Bluecheck}/>}/>,
                                    )}
                                    {!cont2 && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                        alignItems: "center",
                                    }}><img style={{height: "10%"}} alt="No Schedule Message" src={Noschedule}/>
                                        <Text>{t("words.empty_list")}</Text></div>}
                                </div>
                            }
                            {secondStatus === "failure" &&
                                <div style={messagList}>
                                    {messageList.filter((list) => {
                                        if (list.status === "failure") {
                                            if (cont3 === false) {
                                                setcont3(true);
                                            }
                                            return list;
                                        } else {
                                            return null;
                                        }
                                    }).map((list) =>
                                        <ChatSchedulingMessageList data={list} key={list.id}
                                                                   icon={<img alt="trash" src={Toupdate}
                                                                              onClick={() => setEditing(parseInt(localStorage.getItem("contact")), list.id)}/>}
                                                                   icon3={<img alt="trash" src={TrashSvh}/>}/>,
                                    )}
                                    {!cont3 && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                        alignItems: "center",
                                    }}><img style={{height: "10%"}} alt="No Schedule Message" src={Noschedule}/>
                                        <Text>{t("words.empty_list")}</Text></div>}
                                </div>
                            }
                        </Block>

                    </Body>
                }


                {aba === false ?
                    <ModalFooter className={classes.backgroundColor}>
                        <div>
                            <Button classes={{root: btnCancel.root, label: btnCancel.label}}
                                    onClick={newContactToggle}>
                                {t("words.cancel")}
                            </Button>
                        </div>
                        <div>
                            {update === false ?
                                <Button classes={{root: btnSave.root, label: btnSave.label}}
                                        onClick={() => openInfor === false && createMessage(name, message, day, time, type)}>
                                    {t("words.save")}
                                </Button> :
                                <Button classes={{root: btnSave.root, label: btnSave.label}}
                                        onClick={() => openInfor === false && createMessage(name, message, day, time, type, editId)}>
                                    {t("words.edit")}
                                </Button>}
                        </div>
                    </ModalFooter> :
                    <ModalFooter className={classes.backgroundColor}>

                    </ModalFooter>
                }
            </Modal>
            {empty === true && <AlertNotify type="error" time={6000} message={t("words.msgfield_blank")} state={true}/>}
            {openInfor === true && <AlertNotify type="info" time={6000} message={t("words.creating")} state={true}/>}
            {success === true && <AlertNotify type="success" time={2000} message={t("words.done")} state={true}/>}
            {error === true &&
                <AlertNotify type="error" time={6000} message={t("words.unable_create_message")} state={true}/>}
            {error2 === true &&
                <AlertNotify type="error" time={6000} message={t("words.unable_complete")} state={true}/>}
            {sizeError !== false &&
                <AlertNotify type="error" time={6000} message={t("words.larger_size_alert")} state={true}/>}
        </Container>
    );

};

export default ChatSchedulingMessage;