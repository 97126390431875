import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {InputGroup, InputGroupText, ModalFooter, ModalBody, ModalHeader, Modal, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {FixedSizeList} from "react-window";
import {BsSearch} from "react-icons/bs";
import React from "react";
import * as Groups from "../../../Services/campaignFunctions";
import MuiAlert from "@material-ui/lab/Alert";
import Trash from "../../../Assets/attendance/Trash  Active - Hover.svg";
import {Snackbar} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {cancelBtn2, saveBtn2} from "../../../Styles/buttons";
import ImportGroup from "../../Components/ImportGroup";
import {useLocation} from "react-router-dom";
import exclamation_circle from "../../../Assets/CommunIcons/exclamation-circle.svg";
import Avatar from "@material-ui/core/Avatar";
import ActiveGroup from "../../../Assets/CommunIcons/ActiveGroup.svg";
import NotAllowedGroup from "../../../Assets/CommunIcons/NotAllowedGroup.svg";
import DisconnectdGroup from "../../../Assets/CommunIcons/DisconnectdGroup.svg";
import ErrorGroup from "../../../Assets/CommunIcons/ErrorGroup.svg";
import group404 from "../../../Assets/CommunIcons/404Group.svg";
import {useCommun} from "../../../Providers/commun";
import {LightTooltip} from "../../../Styles/styles";
import DateFormat from "../../../Common/Components/DateFormat";
import {ReactComponent as ButtonTrash} from "../../../Assets/attendance/Trash.svg";
import "./styles.css";

import {
    Container,
    Header,
    Title,
    Search,
    DivStatus,
    DivName,
    DivFlex,
} from "./styles";
import isMobile from "../../../Services/isMobile";
import CardInfo from "../Mobile/CardInfo";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const CampaignGroups = ({campaign, id, image}) => {

    const {isMobileState, setIsMobileState} = useCommun();
    const {t} = useTranslation();
    const locantion = useLocation();

    const [search, setSearch] = React.useState("");
    const [listGroups, setListGroups] = React.useState([]);

    const BtnSave = saveBtn2();
    const CancelBtn = cancelBtn2();

    const [modal, setModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [campaignId, setCampaignId] = React.useState();
    const [groupId, setGroupId] = React.useState();
    const [jid, setJid] = React.useState();


    const handleClose = () => {
        setOpen(!open);
    };
    const handleClose2 = () => {
        setOpen2(!open2);
    };
    const toggle = () => {
        setModal(!modal);
    };


    const getGroups = async (campaignID) => {
        const groupList = await Groups.getGroups(campaignID);
        setListGroups(groupList.sort((a, b) => a.metadata.name ? 1 : -1));
    };

    const groupDelete = async () => {
        const deleted = await Groups.deleteGroups(campaignId, groupId, jid);
        toggle();
        handleClose();
        getGroups(id);

    };

    const filtered = listGroups?.filter(v => v?.metadata?.name ? v?.metadata?.name.includes(search) : v?.gid.split("@")[0].includes(search));

    React.useEffect(() => {
        getGroups(id);
    }, [id, locantion]);

    const groupsOptions = (campaignId, groupId, jid, name) => {
        setCampaignId(campaignId);
        setGroupId(groupId);
        setJid(jid);
        toggle();
    };

    React.useEffect(() => {
        setIsMobileState(isMobile());
    }, []);

    function recarregarPagina() {
        setIsMobileState(isMobile());
    }

    var boraLa;
    window.onresize = function () {
        clearTimeout(boraLa);
        boraLa = setTimeout(recarregarPagina, 100);
    };

    const [isToSearch, setIsToSearch] = React.useState(false);

    const setSearchF = () => {
        setIsToSearch(!isToSearch);
        setTimeout(() => {
            const text = document.getElementById("inputId");
            text?.focus();
        }, 100);
    };

    return (
        <Container>
            <Header>
                {isToSearch ?
                    <InputGroupText>
                        <Input className="form-input" id="inputId" onChange={e => setSearch(e.target.value)}
                               value={search} placeholder={t("words.search")}/>
                    </InputGroupText> :
                    <DivFlex>
                        <Title>{t("words.groups")}</Title>
                        <LightTooltip title={t("words.group_list_description")} placement="top">
                            <img alt="info" src={exclamation_circle} width="21px" style={{cursor: "pointer"}}/>
                        </LightTooltip>
                    </DivFlex>}

                <Search>
                    {isMobileState ? <InputGroupText style={{marginRight: "15px"}}
                                                     onClick={() => setSearchF()}><BsSearch/></InputGroupText> :
                        <InputGroup style={{paddingRight: "15px", flex: "1", width: "170px"}}>
                            <InputGroupText><BsSearch/></InputGroupText>
                            <Input className="form-input" id="input" onChange={e => setSearch(e.target.value)}
                                   value={search} placeholder={t("words.search")}/>
                        </InputGroup>}
                    <LightTooltip title={t("words.import_group_disabled")} placement="top">
                        <img alt="info" src={exclamation_circle} width="21px" style={{cursor: "pointer"}}/>
                    </LightTooltip>
                    {/*  <ImportGroup campaign={campaign} func={() => getGroups(id)}></ImportGroup> */}
                </Search>
            </Header>

            {isMobileState ?
                <TableContainer style={{height: "350px", overflow: "auto"}}>
                    {filtered.map((group) => (
                        <CardInfo translate={t("words.created")} img={image}
                                  name={group?.gid !== "" && group?.metadata?.name && group?.metadata?.name}
                                  createdAt={group.created_at} desc={t("words.leads")}
                                  info={group?.metadata?.participants?.length}/>

                    ))}
                </TableContainer>
                :
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("words.name")}</TableCell>
                                <TableCell>{t("words.created")}</TableCell>
                                <TableCell style={{width: "5%"}}>{t("words.leads")}</TableCell>
                                <TableCell>{t("words.status")}</TableCell>
                                <TableCell style={{width: "5%"}}></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <FixedSizeList
                        height={filtered.length <= 3 ? 200 : 350}
                        itemCount={filtered.length}
                        itemSize={65}
                    >
                        {({index, style}) => {
                            const group = filtered[index];
                            return (
                                <>
                                    <Table style={style} key={group?.id}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{maxWidth: "10px"}}><DivFlex>
                                                    <div><Avatar alt="group_image" src={image}/></div>
                                                    <DivName>{group?.gid !== "" && group?.metadata?.name ? group?.metadata?.name : group?.name}</DivName></DivFlex></TableCell>
                                                <TableCell style={{fontSize: "0.9rem", maxWidth: "10px"}}><DivName
                                                    style={{width: "100%"}}><DateFormat
                                                    date={group.created_at}/></DivName></TableCell>
                                                <TableCell style={{
                                                    fontSize: "0.9rem",
                                                    width: "10%",
                                                    paddingRight: "5px",
                                                }}>{group?.metadata?.participants?.length}</TableCell>
                                                <TableCell style={{fontSize: "0.9rem", maxWidth: "10px"}}>
                                                    <DivStatus>
                                                        <div style={{paddingRight: "5px"}}>
                                                            {typeof group?.metadata !== "string" &&
                                                                <img src={ActiveGroup} alt="active"/>}
                                                            {group?.metadata === "nats: no responders available for request" &&
                                                                <img src={DisconnectdGroup} alt="diconectd"/>}
                                                            {group?.metadata === "whatsapp error: Error: Connection Closed" &&
                                                                <img src={DisconnectdGroup} alt="diconectd"/>}
                                                            {group?.metadata === "whatsapp error: Error: forbidden" &&
                                                                <img src={NotAllowedGroup} alt="forbidden"/>}
                                                            {group?.metadata === "whatsapp error: Error: item-not-found" &&
                                                                <img src={group404} alt="404"/>}
                                                            {group?.metadata === "whatsapp error: Error: rate-overlimit" &&
                                                                <img src={ErrorGroup} alt="error"/>}
                                                        </div>
                                                        {group?.metadata === "nats: no responders available for request" && t("words.disconnected")}
                                                        {group?.metadata === "whatsapp error: Error: Connection Closed" && t("words.disconnected")}
                                                        {group?.metadata === "whatsapp error: Error: forbidden" && t("words.forbidden_group")}
                                                        {typeof group?.metadata !== "string" && t("words.status_active")}
                                                        {group?.metadata === "whatsapp error: Error: item-not-found" && t("words.group_error")}
                                                        {group?.metadata === "whatsapp error: Error: rate-overlimit" && t("words.group_error")}
                                                    </DivStatus>
                                                </TableCell>
                                                <TableCell style={{width: "5%"}}>
                                                    <div style={{cursor: "pointer"}}
                                                         onClick={() => groupsOptions(group.campaign_id, group.id, group.jid, group?.metadata?.name)}>
                                                        <ButtonTrash className="deleteButton"/>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </>
                            );
                        }}
                    </FixedSizeList>
                </TableContainer>
            }
            <Modal isOpen={modal} toggle={toggle} size="lg" style={{maxWidth: "700px", width: "100%"}}>
                <ModalHeader>{t("words.groups")}</ModalHeader>
                <ModalBody>
                    <h6>{t("words.delete_group")}</h6>
                    <h6>{t("words.delete_gp2")}</h6>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button classes={{root: CancelBtn.root, label: CancelBtn.label}}
                                onClick={toggle}>{t("words.cancel")}</Button>
                    </div>
                    <div>
                        <Button classes={{root: BtnSave.root, label: BtnSave.label}}
                                onClick={groupDelete}>{t("words.delete")}</Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose2} severity="success">
                    {t("words.done")}
                </Alert>
            </Snackbar>
            <Snackbar open={open2} autoHideDuration={2000} onClose={handleClose2}>
                <Alert onClose={handleClose2} severity="error">
                    {t("words.unable_complete")}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CampaignGroups;