import axios_base from "../axios_base";

export const fetchStatus = async id => {
    try {
        const {data: status} = await axios_base.get(`/number/${id}/status`);
        return status;
    } catch (error) {
        console.error("fetch status error:", error);
        return null;
    }
};

export const fetchNumber = async id => {
    const {data: number} = await axios_base.get(`/number/${id}`);
    return number;
};

export const fetchNumbers = async () => {
    const {data: numbers} = await axios_base.get(`/number`);
    return numbers;
};

export const removeNumber = async numberID => {
    await axios_base.delete(`/number/${numberID}`);
};

export const checkNumberConnection = async numberID => {
    await axios_base.get(`/number/${numberID}/connected`);
};

export const disconnectNumber = async numberID => {
    try {
        await axios_base.patch(`/number/${numberID}/disconnect`);
    } catch (error) {
        console.error("Disconnect error", error);
    }
};

export const reconnectNumber = async (id) => {
    const {data: number} = await axios_base.patch(`/number/${id}/reconnect`);
    return number;
};