import React from "react";
import {ReactComponent as WelcomeBannerImage} from "../../../Assets/admin_img/Banner.svg";
import isMobile from "../../../Services/isMobile";
import {useTranslation} from "react-i18next";
import ModalComponent from "../Modal";

import {BannerContent, BannerDiv, Button2, Buttons, Container, LeftDiv, RightDiv} from "./styles";

const WelcomeBanner = () => {
    const {t} = useTranslation();

    return (
        <Container>
            <BannerDiv>
                <LeftDiv>
                    <BannerContent>
                        <h5>{t("words.dear_user")}</h5>
                        <p>{t("words.tips_title")}</p>
                        <small>
                            {t("words.initial_text_short")}
                        </small>
                    </BannerContent>
                    <Buttons>
                        <ModalComponent button={<Button2>{t("words.see_more")}</Button2>}>
                            <p>
                                <b>{t("words.before_start")}</b>
                                <li>{t("words.tip_01")}</li>
                                <li>{t("words.tip_03")}</li>
                                <li>{t("words.tip_04")}</li>
                                <li>{t("words.tip_05")}</li>
                                <li>{t("words.tip_07")}</li>
                            </p>
                            <p>
                                <b>{t("words.whats_business_tips")}</b>
                                <li>{t("words.whats_business_tips_2")}</li>
                                <a href="https://faq.whatsapp.com/793641088597363/?locale=pt_BR">{t("words.see_more")}</a>
                            </p>
                        </ModalComponent>
                    </Buttons>
                </LeftDiv>
                {!isMobile() &&
                    <RightDiv>
                        <WelcomeBannerImage/>
                    </RightDiv>}
            </BannerDiv>
        </Container>
    );
};

export default WelcomeBanner;