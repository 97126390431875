import styled from "styled-components";

export const Container = styled.div`
    grid-area: CT;
    color:  ${props => props.theme.color.white};
    background: #E9EDF2 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 40px 0;
    padding-left: 18%;
    padding-right: 18%;
    height: 100vh;
    overflow-y: scroll;
    transition: all .2s;
    padding-bottom: 10%;

    @media(max-width: 1366px){
        padding-left: 8%;
        padding-right: 8%;
    }
    @media(max-width: 780px){
        padding-left: 3%;
        padding-right: 3%;
    }
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        padding-right: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #BABABA;
        border-radius: 5px;
    }
`;