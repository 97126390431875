import styled from "styled-components";

export const Container = styled.div`
    height: 50vh;
    margin-bottom: 25rem;
    margin-top: 2rem;
    color: #333333;
`;

export const CardContainer = styled.div`
    width: 100%;
    padding-bottom: 5%;
`;

export const TopCards = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 1%;
`;

export const BottomCards = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 1%;

`;

export const Card = styled.div`
    height: 180px;
    width: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 6px;
    align-items: center;
    text-align: center;
    margin: 5px;
`;

export const Circle = styled.div`
    background: ${props => `linear-gradient(33deg, ${props.colors[0]} 0%, ${props.colors[1]} 100%)`};
    color: white;
    margin: 20px auto;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    
    > svg {
        font-size: 50px
    }
`;

export const GraphicContainer = styled.div`
    height: 85%;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 6px;

    @media(min-width: 1600px) {
        width: 90%;
    }
`;

export const GraphicTitle = styled.div`
    height: 10%;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    padding: 12px 0px;
    border-bottom: 1px solid #CCCCCC;

    > h4 {
        font-size: 1rem;
        color: #434343;
        font-weight: bold;
    }
`;

export const Title = styled.h5`
    font-size: 18px;
`;

export const SubTitle = styled.h5`
    font-size: 23px;
    font-weight: 900;
`;

