import {makeStyles, withStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import {Menu} from "@material-ui/core";

export const StyledMenu = withStyles({
    paper: {
        borderRadius: "0px 0px 10px 10px;",
        boxShadow: "0px 0px 5px #26242459;",
        margin: "-2px 0",
        height: "fitContent",
        marginRight: "10px",
        overflow: "auto",
        width: "139px",
        "& .MuiButtonBase-root": {
            fontFamily: "Mulish",
            fontWeight: "600",
            fontSize: "16px",
            paddingLeft: "10px",
        },
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: "\"\"",
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);

export const StyledBadgeOff = withStyles((theme) => ({
    badge: {
        backgroundColor: "#898E8D",
        color: "#898E8D",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: "\"\"",
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);

export const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

export const Container = styled.div`

    .MuiMenuItem-root {
        font-family: 'Lato';
    } 

    .MuiIconButton-root {
        color: #0080FC;
    }

    .MuiTableHead-root {
        background-color: #F7F8FC;
        border-radius: 6px 6px 0px 0px;
    }

    .MuiPaper-elevation1 {
        box-shadow: none; 
    }

    .MuiTableCell-root {
        text-align: center;
    }

    .MuiTableCell-head {
        color: #333333;
        font: bold 16px/30px Mulish;
    }

    .MuiTableCell-body {
        color:rgba(51, 51, 51, .7);
        font: 600 16px/30px Mulish;
    }
`;

export const DeleteDiv = styled.div`
    height: fit-content;
    width: fit-content;
    cursor: pointer;

    .deleteButton {
    &, * {
        fill: #a5b9d5;
    }
    
    &:hover {
        &, * {
        fill: #fa4b57;
        }
    }
}

`;



