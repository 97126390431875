import styled from "styled-components";
import StepConnector from "@material-ui/core/StepConnector";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";

export const Container = styled.div`
`;

export const BlueCheckbox = withStyles({
    root: {

        "&$checked": {
            color: "#0294FF",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 18,
    },
    active: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,#20E4B3   30%, #20E4B3   90%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,#20E4B3   30%,#20E4B3  90%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

export const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
    },
    active: {
        color: "#0294FF",
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
    completed: {
        color: "#0294FF",
        zIndex: 1,
        fontSize: 18,
    },
});

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 35,
        height: 35,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage:
            "linear-gradient( 136deg, #20E4B3 30%, #20E4B3 90%)",
    },
    completed: {
        backgroundImage:
            "linear-gradient( 136deg, #20E4B3 30%, #20E4B3 90%)",
    },
});

export const PrettoSlider = withStyles({
    root: {
        color: "#1665D8",
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);


export const Label = styled.div`
    padding-top: 15px;
    padding-bottom: 0.625rem;
    text-align: left;
    font: normal normal 600 0.9rem Mulish;
    letter-spacing: 0;
    color: #6E6E6E;
    opacity: 1;
    display: flex;
    
`;

export const Top = styled.div`
    background: #F3F7FF 0% 0% no-repeat padding-box;
    padding-top: 50px; 
    padding-left: 60px; 
    padding-right: 60px; 
    width: 100%;
    border-radius: 10px;
`;

export const TopBar = styled.div`
    background: #FCFAFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 3px #7E7E7E10;
    border-bottom: 1px solid #E2E4E9;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    display: flex; 
    justify-content: space-between; 
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;

    @media(max-height: 1080px){
        height: 6vh;
    }
    @media(max-height: 768px){
        height: 10vh;
    }
    
`;

export const Div = styled.div`
    >h3 {
    text-align: left;
    font: 800 1.2rem Mulish;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 1;
    }
`;

export const Body = styled.div`
    background: #F3F7FF 0% 0% no-repeat padding-box;
    padding-top: 10px; 
    padding-left: 60px; 
    padding-right: 60px;
    padding-bottom: 25px;
    border-radius: 10px;
    height: 60vh;
`;

export const BodyCard = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 0px 0px 6px 6px;
    opacity: 1;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    overflow-y: auto;
    margin-top: -0.625rem;
`;

export const DivStep = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: 'space-between';
`;


export const Colunm1 = styled.div`
    width: 60%;
    margin-top: 45px;
    justify-content: left;
    align-items: left;

    .form-control {
        background: #FAFBFC 0% 0% no-repeat padding-box;
        border: 1px solid #D0DAED;
        border-radius: 3px;
        opacity: 1;
        font-family: Mulish;
    }

    .css-yk16xz-control {
        background: #FAFBFC 0% 0% no-repeat padding-box;
        border: 1px solid #D0DAED;
        border-radius: 4px;
        opacity: 1;
        font-family: Mulish;
    }

    .css-yk16xz-control:hover {
        border-color: #D0DAED;
    }

    .css-1pahdxg-control {
        background: #FAFBFC 0% 0% no-repeat padding-box;
        border-color: #D0DAED;
        box-shadow: 0 0 0 1px #D0DAED;
        font-family: Mulish;
    }

    .css-1pahdxg-control:hover {
        border-color: #D0DAED;
    }
`;
export const Colunm2 = styled.div`
   width: 40%;
   height: 50%;
   display: flex;
   justify-content: right;
   align-items: right;
   
`;

export const TextTitle = styled.div`
    > h5 {
        text-align: left;
        font: normal normal 600 1.25rem Mulish;
        letter-spacing: 0px;
        color: #4E4E4E;
        opacity: 1;
    }
    > small {
        text-align: left; 
        font: normal normal 600 0.938rem Mulish;
        letter-spacing: 0px;
        color: #4E4E4E;
        opacity: 0.65;
    }
`;

export const Line = styled.div`
   padding: 5px 0;
   font-family: Mulish;

   

    .ImgLabel{
        width: 100px;
        height: 100px;
        cursor: pointer;
        background-color: rgba(232, 240, 254, 0.45);
        background-size: cover;
        text-align: center;
        border-radius: 100%;
        position: relative;

        > svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
     
        .CameraDiv {
            width: 33px;
            height: 33px;
            background: #E8F0FE;
            border-radius: 100%;
            text-align: center;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            bottom: 4px;

            > svg {
                margin-right: -1px;
            }
        }
    }

    .MuiTypography-body1 {
        font-family: Mulish;
        font-size: 15px;
        font-weight: 600;
        color: #6E6E6E;
    }
`;

export const BoxViewNumber = styled.div`
    background: #E0E7FF26;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;

    > input {
        width: 50px;
        height: 100%;
    }
`;