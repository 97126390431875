import {
    checkNumberConnection,
    disconnectNumber,
    removeNumber,
    reconnectNumber,
} from "../../../Services/numberFunctions";
import {ReactComponent as Thrash} from "../../../Assets/attendance/Trash  Active - Hover.svg";
import {Container, DeleteDiv, StyledBadgeOff, StyledBadge} from "./styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRing";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import AuthenticateModal from "../ModalQRCode";
import {useTranslation} from "react-i18next";
import {ReactComponent as QRCodeIcon} from "../../../Assets/icon/QR Code.svg";
import {useCommun} from "../../../Providers/commun";
import Table from "@material-ui/core/Table";
import {LightTooltip} from "../../../Styles/styles";
import Paper from "@material-ui/core/Paper";
import NumberModals from "../NumberModals";
import LogTimeline from "../LogTimeline";
import AlertNotify from "../Alert";
import React from "react";
import CardNumber from "../Mobile/CardNumber";
import {ReactComponent as ReconnectIcon} from "../../../Assets/icon/Reconnect icon.svg";
import InfoTooltip from "../InfoTooltip";
import DateFormat from "../DateFormat";
import {ReactComponent as Trash} from "../../../Assets/attendance/Trash.svg";

const NumbersTable = ({numbers, updateNumbersList}) => {
    const [modalInfoController, setModalInfoController] = React.useState(null);
    const [disconnectSuccess, setDisconnectSuccess] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [numberID, setNumberID] = React.useState();
    const [openLogNumber, setOpenLogNumber] = React.useState(null);
    const {isMobileSate} = useCommun();

    const [reconnectedFailed, setReconnectedFailed] = React.useState(false);
    const [reconnectedSuccess, setReconnectedSuccess] = React.useState(false);
    const [reconnecting, setReconnecting] = React.useState(false);

    const {t} = useTranslation();

    const toggleModal = (numberID, contentController) => {
        setNumberID(numberID);
        setModalInfoController(contentController);
        setModal(!modal);
    };

    const deleteNumber = async numberID => {
        try {
            await removeNumber(numberID);
            setModal(!modal);
            setDeleteSuccess(true);
            await updateNumbersList();
        } catch (error) {
            if (error.response?.data?.message === "ERROR: update or delete on table \"numbers\" violates foreign key constraint \"fk_campaigns_number\" on table \"campaigns\" (SQLSTATE 23503)") {
                setModalInfoController("campaign_exists");
            }
            if (error.response?.data?.message === "ERROR: update or delete on table \"numbers\" violates foreign key constraint \"fk_operators_number\" on table \"operators\" (SQLSTATE 23503)") {
                setModalInfoController("operators_sectors");
            }
            console.log(error);
        }
    };

    const disconnect = async numberID => {
        try {
            await disconnectNumber(numberID);
            setDisconnectSuccess(true);
            await updateNumbersList();
        } catch (error) {
            setModalInfoController("connection_error");
            console.log(error);
        }
    };

    const testConnection = async numberID => {
        try {
            await checkNumberConnection(numberID);
            setModalInfoController("test_connection");
        } catch (error) {
            setModalInfoController("connection_error");
        } finally {
            setModal(!modal);
        }
    };

    const reconnectNumberFunc = async (id) => {
        setReconnecting(true);
        try {
            await reconnectNumber(id);
            setTimeout(() => setReconnectedSuccess(false), 3000);
            setReconnectedSuccess(true);
        } catch (error) {
            setReconnectedFailed(true);
            setTimeout(() => setReconnectedFailed(false), 3000);
        } finally {
            setReconnecting(false);
        }
    };

    return (
        <Container>

            {isMobileSate || window.innerWidth < 800 ?
                numbers.map(num => (
                    <CardNumber phone={formatPhoneNumberIntl("+" + num.number.split("@")[0])} options={
                        <div style={{display: "flex"}}>
                            <DeleteDiv style={{padding: "0 10px"}}>
                                {num.connected ?
                                    <StyledBadge overlap="circular"
                                                 anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                                 variant="dot"/> :
                                    <StyledBadgeOff overlap="circular"
                                                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                                    variant="dot"/>
                                }
                            </DeleteDiv>
                            {num.connected &&
                                <DeleteDiv style={{padding: "0 5px"}}>
                                    <LightTooltip title={t("words.test_connection")}>
                                        <PhonelinkRingIcon onClick={() => testConnection(num.id)}
                                                           style={{color: "#0080FC"}}/>
                                    </LightTooltip>
                                </DeleteDiv>}
                            {num.connected &&
                                <DeleteDiv style={{paddingRight: "10px"}}>
                                    <LightTooltip title={t("words.disconnect")}>
                                        <PhonelinkEraseIcon onClick={() => disconnect(num.id)}
                                                            style={{color: "#0080FC"}}/>
                                    </LightTooltip>
                                </DeleteDiv>}
                            {!num.connected &&
                                <LightTooltip title={t("words.reassign")}>
                                    <DeleteDiv style={{paddingRight: "15px", paddingLeft: "15px"}}>
                                        <AuthenticateModal
                                            title="MyNumbers"
                                            Parameter="authenticate"
                                            IdNumber={num.id}
                                            button={<QRCodeIcon/>}
                                            onClose={() => updateNumbersList()}/>
                                    </DeleteDiv>
                                </LightTooltip>}
                            <LightTooltip title={t("words.delete")}>
                                <DeleteDiv onClick={() => toggleModal(num.id, "delete")}><Thrash/></DeleteDiv>
                            </LightTooltip>
                        </div>
                    }
                                createdAt={<DateFormat date={num.created_at}/>} name="---" info={
                        <div style={{justifyContent: "center"}}>
                            <LogTimeline num={num}/>
                        </div>
                    }/>
                ))
                :
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("words.name")}</TableCell>
                                    <TableCell>{t("words.number")}</TableCell>
                                    <TableCell>{t("words.created")}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {numbers.map(num => (
                        <div style={{margin: "3% 0", width: "100%", background: "#ffffff", borderRadius: "6px"}}
                             key={num.id}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                boxShadow: "0px 2px 3px #0000001A",
                                borderRadius: "6px 6px 0px 0px",
                                alignItems: "center",
                                height: "4rem",
                                padding: "0 2%",
                            }}>
                                <div>
                                    <IconButton aria-label="expand row" size="small"
                                                onClick={() => setOpenLogNumber(openLogNumber === num.id ? null : num.id)}>
                                        {openLogNumber === num.id ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                    </IconButton>
                                </div>
                                <div>---</div>
                                <div>{formatPhoneNumberIntl("+" + num.number.split("@")[0])}</div>
                                <div><DateFormat date3L={true} date={num.created_at}/></div>
                                <div style={{display: "flex"}}>
                                    <DeleteDiv style={{padding: "0 10px"}}>
                                        {num.connected ?
                                            <StyledBadge overlap="circular"
                                                         anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                                         variant="dot"/> :
                                            <StyledBadgeOff overlap="circular"
                                                            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                                            variant="dot"/>
                                        }
                                    </DeleteDiv>
                                    {num.connected &&
                                        <DeleteDiv style={{padding: "0 5px"}}>
                                            <LightTooltip title={t("words.reconnec_number")}>
                                                <ReconnectIcon onClick={() => reconnectNumberFunc(num.id)}/>
                                            </LightTooltip>
                                        </DeleteDiv>}
                                    {num.connected &&
                                        <DeleteDiv style={{padding: "0 5px"}}>
                                            <LightTooltip title={t("words.test_connection")}>
                                                <PhonelinkRingIcon onClick={() => testConnection(num.id)}
                                                                   style={{color: "#0080FC"}}/>
                                            </LightTooltip>
                                        </DeleteDiv>}
                                    {num.connected &&
                                        <DeleteDiv style={{paddingRight: "10px"}}>
                                            <LightTooltip title={t("words.disconnect")}>
                                                <PhonelinkEraseIcon onClick={() => disconnect(num.id)}
                                                                    style={{color: "#0080FC"}}/>
                                            </LightTooltip>
                                        </DeleteDiv>}
                                    {!num.connected &&
                                        <LightTooltip title={t("words.reassign")}>
                                            <DeleteDiv style={{paddingRight: "15px", paddingLeft: "15px"}}>
                                                <AuthenticateModal
                                                    title="MyNumbers"
                                                    Parameter="authenticate"
                                                    IdNumber={num.id}
                                                    button={<QRCodeIcon/>}
                                                    onClose={() => updateNumbersList()}/>
                                            </DeleteDiv>
                                        </LightTooltip>}
                                    <LightTooltip title={t("words.delete")}>
                                        <DeleteDiv onClick={() => toggleModal(num.id, "delete")}><Trash
                                            className="deleteButton"/></DeleteDiv>
                                    </LightTooltip>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div colSpan={7} style={{width: "100%"}}>
                                    <Collapse in={openLogNumber === num.id} timeout="auto" unmountOnExit>
                                        <div style={{paddingTop: "10px"}}><InfoTooltip
                                            text={t("words.connection_history")}/></div>
                                        <LogTimeline num={num}/>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            }

            <NumberModals isOpen={modal} toggle={toggleModal} deleteNumber={deleteNumber}
                          modalInfoController={modalInfoController} numberID={numberID}/>

            {deleteSuccess === true &&
                <AlertNotify message={t("words.deleted_number")} state={true} time={3000} type="success"/>}
            {disconnectSuccess === true &&
                <AlertNotify message={t("words.desconnected_success")} state={true} time={3000} type="success"/>}
            {reconnecting && <AlertNotify message={t("words.reconnecting")} state={true} type="info"/>}
            {reconnectedSuccess &&
                <AlertNotify message={t("words.reconnected_success")} state={true} time={3000} type="success"/>}
            {reconnectedFailed &&
                <AlertNotify message={t("words.reconnected_error")} state={true} time={3000} type="error"/>}

        </Container>
    );
};

export default NumbersTable;