import React from "react";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/eu";
import "dayjs/locale/es";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ne";
import "dayjs/locale/pl";

const DateFormat = ({date, date3L}) => {


    const [dateFormaState, setDateFormatState] = React.useState();

    const changeDataFormat = () => {
        setDateFormatState(localStorage.getItem("i18nextLng"));
    };

    React.useEffect(() => {
        changeDataFormat();
    }, [localStorage.getItem("i18nextLng")]);

    React.useEffect(() => {
        changeDataFormat();
    }, []);

    dayjs.locale(dateFormaState);
    dayjs.extend(LocalizedFormat);

    return (
        <>
            {!date ? <div></div> : !date3L ? dayjs(date).format("LLL") : dayjs(date).format("LLL")}
        </>
    );

};

export default DateFormat;