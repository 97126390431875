import {createGlobalStyle} from "styled-components";
import fontLato400_01 from "../Assets/fonts/Lato/fontLato400_01.woff2";
import fontLato400_latin from "../Assets/fonts/Lato/fontLato400_latin.woff2";
import fontLato700_latin_ext from "../Assets/fonts/Lato/fontLato700_latin_ext.woff2";
import fontLato700_latin from "../Assets/fonts/Lato/fontLato700_latin.woff2";
import fontLato900_latin from "../Assets/fonts/Lato/fontLato900_latin.woff2";
import fontLato900_latin2 from "../Assets/fonts/Lato/fontLato900_latin2.woff2";

import fontMulish400_cyrillic_ext from "../Assets/fonts/Mulish/fontMulish400_cyrillic_ext.woff2";
import fontMulish400_cyrilli from "../Assets/fonts/Mulish/fontMulish400_cyrilli.woff2";
import fontMulish400_vietnamese from "../Assets/fonts/Mulish/fontMulish400_vietnamese.woff2";
import fontMulish400_latin_ext from "../Assets/fonts/Mulish/fontMulish400_latin_ext.woff2";
import fontMulish400_latin from "../Assets/fonts/Mulish/fontMulish400_latin.woff2";
import fontMulish600_cyrillic_ext from "../Assets/fonts/Mulish/fontMulish600_cyrillic_ext.woff2";
import fontMulish600_cyrillic from "../Assets/fonts/Mulish/fontMulish600_cyrillic.woff2";
import fontMulish600_vietnamese from "../Assets/fonts/Mulish/fontMulish600_vietnamese.woff2";
import fontMulish600_latin_ext from "../Assets/fonts/Mulish/fontMulish600_latin_ext.woff2";
import fontMulish600_latin from "../Assets/fonts/Mulish/fontMulish600_latin.woff2";
import fontMulish700_cyrillic_ext from "../Assets/fonts/Mulish/fontMulish700_cyrillic_ext.woff2";
import fontMulish700_cyrillic from "../Assets/fonts/Mulish/fontMulish700_cyrillic.woff2";
import fontMulish700_vietnamese from "../Assets/fonts/Mulish/fontMulish700_vietnamese.woff2";
import fontMulish700_latin_ext from "../Assets/fonts/Mulish/fontMulish700_latin_ext.woff2";
import fontMulish700_latin from "../Assets/fonts/Mulish/fontMulish700_latin.woff2";
import fontMulish800_cyrillic_ext from "../Assets/fonts/Mulish/fontMulish800_cyrillic_ext.woff2";
import fontMulish800_cyrillic from "../Assets/fonts/Mulish/fontMulish800_cyrillic.woff2";
import fontMulish800_vietnamese from "../Assets/fonts/Mulish/fontMulish800_vietnamese.woff2";
import fontMulish800_latin_ext from "../Assets/fonts/Mulish/fontMulish800_latin_ext.woff2";
import fontMulish800_latin from "../Assets/fonts/Mulish/fontMulish800_latin.woff2";
import fontMulish900_cyrillic_ext from "../Assets/fonts/Mulish/fontMulish900_cyrillic_ext.woff2";
import fontMulish900_cyrillic from "../Assets/fonts/Mulish/fontMulish900_cyrillic.woff2";
import fontMulish900_vietnamese from "../Assets/fonts/Mulish/fontMulish900_vietnamese.woff2";
import fontMulish900_latin_ext from "../Assets/fonts/Mulish/fontMulish900_latin_ext.woff2";
import fontMulish900_latin from "../Assets/fonts/Mulish/fontMulish900_latin.woff2";

import fontRoboto400_cyrillic_ext from "../Assets/fonts/Roboto/fontRoboto400_cyrillic_ext.woff2";
import fontRoboto400_cyrillic from "../Assets/fonts/Roboto/fontRoboto400_cyrillic.woff2";
import fontRoboto400_greek_ext from "../Assets/fonts/Roboto/fontRoboto400_greek-ext.woff2";
import fontRoboto400_greek from "../Assets/fonts/Roboto/fontRoboto400_greek.woff2";
import fontRoboto400_vietnamese from "../Assets/fonts/Roboto/fontRoboto400_vietnamese.woff2";
import fontRoboto400_latin_ext from "../Assets/fonts/Roboto/fontRoboto400_latin_ext.woff2";
import fontRoboto400_latin from "../Assets/fonts/Roboto/fontRoboto400_latin.woff2";
import fontRoboto700_cyrillic_ext from "../Assets/fonts/Roboto/fontRoboto700_cyrillic_ext.woff2";
import fontRoboto700_cyrillic from "../Assets/fonts/Roboto/fontRoboto700_cyrillic.woff2";
import fontRoboto700_greek_ext from "../Assets/fonts/Roboto/fontRoboto700_greek_ext.woff2";
import fontRoboto700_greek from "../Assets/fonts/Roboto/fontRoboto700_greek.woff2";
import fontRoboto700_vietnamese from "../Assets/fonts/Roboto/fontRoboto700_vietnamese.woff2";
import fontRoboto700_latin_ext from "../Assets/fonts/Roboto/fontRoboto700_latin_ext.woff2";
import fontRoboto700_latin from "../Assets/fonts/Roboto/fontRoboto700_latin.woff2";
import fontRoboto900_cyrillic_ext from "../Assets/fonts/Roboto/fontRoboto900_cyrillic_ext.woff2";
import fontRoboto900_cyrillic from "../Assets/fonts/Roboto/fontRoboto900_cyrillic.woff2";
import fontRoboto900_greek from "../Assets/fonts/Roboto/fontRoboto900_greek.woff2";
import fontRoboto900_vietnamse from "../Assets/fonts/Roboto/fontRoboto900_vietnamse.woff2";
import fontRoboto900_latin_ext from "../Assets/fonts/Roboto/fontRoboto900_latin_ext.woff2";
import fontRoboto900_latin from "../Assets/fonts/Roboto/fontRoboto900_latin.woff2";
import fontRoboto900_greek_ext from "../Assets/fonts/Roboto/fontRoboto900_greek_ext.woff2";

export default createGlobalStyle`
    *{
        margin: 0;
        padding:0;
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100% 100vh;
    }

    body {
        background-color: #E9EDF2;
    }

    button, input{
        border: 0;
        outline: 0;
        font-family: 'Mulish', sans-serif;
    }
    
    @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontLato400_01}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontLato400_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontLato700_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontLato700_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontLato900_latin}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontLato900_latin2}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontMulish400_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontMulish400_cyrilli}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontMulish400_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontMulish400_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontMulish400_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${fontMulish600_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${fontMulish600_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${fontMulish600_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${fontMulish600_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${fontMulish600_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontMulish700_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontMulish700_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontMulish700_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontMulish700_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontMulish700_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(${fontMulish800_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(${fontMulish800_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(${fontMulish800_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(${fontMulish800_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(${fontMulish800_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontMulish900_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontMulish900_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontMulish900_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontMulish900_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontMulish900_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_greek_ext}) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_greek}) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${fontRoboto400_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_greek_ext}) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_greek}) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_vietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${fontRoboto700_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_cyrillic_ext}) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_cyrillic}) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_greek_ext}) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_greek}) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_vietnamse}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_latin_ext}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${fontRoboto900_latin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`;