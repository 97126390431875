import circled_chevron_right from "../../../Assets/icon/circled-chevron-right.svg";
import circled_chevron_left from "../../../Assets/icon/circled-chevron-left.svg";
import {Container, Body, Image} from "./styles";
import {useTranslation} from "react-i18next";
import React from "react";
import {ReactComponent as CampaignInterrupt} from "../../../Assets/icon/Campaign will be interrupted.svg";
import {ReactComponent as CampaignCorrectly} from "../../../Assets/icon/Campaigns executed correctly.svg";
import {ReactComponent as CheckSession} from "../../../Assets/icon/Logged in session.svg";


const InfoStepCard = () => {
    const {t} = useTranslation();
    const [infoStep, setInfoStep] = React.useState(1);

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{textAlign: "left"}}>
                    {infoStep}/3
                </div>
                <div style={{textAlign: "right"}}>
                    <img alt="" src={circled_chevron_left} style={{cursor: "pointer", paddingRight: "10px"}}
                         onClick={() => infoStep > 1 && setInfoStep(infoStep - 1)}/>
                    <img alt="" src={circled_chevron_right} style={{cursor: "pointer"}}
                         onClick={() => infoStep < 3 && setInfoStep(infoStep + 1)}/>
                </div>
            </div>
            <Image>
                <div>
                    {infoStep === 3 && <CheckSession/>}
                    {infoStep === 2 && <CampaignCorrectly/>}
                    {infoStep === 1 && <CampaignInterrupt/>}
                </div>
            </Image>
            <Body>
                <h4>
                    {infoStep === 3 && t("words.check_sessions")}
                    {infoStep === 2 && t("words.campaign_correctly")}
                    {infoStep === 1 && t("words.multidevice")}
                </h4>
                <small>
                    {infoStep === 3 && t("words.prioritize_wifi")}
                    {infoStep === 2 && t("words.in_order")}
                    {infoStep === 1 && t("words.check_multidevice")}
                </small>
            </Body>
        </Container>
    );
};

export default InfoStepCard;