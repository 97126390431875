import React from "react";
import ContentHeader from "../../Components/ContentHeader";
import Backgroud from "../../Components/Backgroud";
import {Container, Content} from "./styles";
import CampaignsCard from "../../../Launch/Components/CampaignsCard";
import {useTranslation} from "react-i18next";
import CampaignsStepByStep from "../../../Launch/Components/CampaignsStepByStep";
import axios_base from "../../../axios_base";
import WelcomeBanner from "../../Components/WelcomeBanner";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useApp from "../../../Providers/app";
import isMobile from "../../../Services/isMobile";
import back from "../../../Assets/icon/No campaigns.svg";
import megaphone from "../../../Assets/icon/megaphone.svg";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const Dashboard = () => {
    const {t} = useTranslation();
    const app = useApp();

    let {id} = useParams();

    const [campaignAmount, setCampaignAmount] = React.useState();

    function getCampaings() {
        axios_base.get("/campaign")
            .then(res => {
                setCampaignAmount(res.data.length);
            });
    }

    React.useEffect(() => {
        getCampaings();
    }, []);

    return (
        <Container>
            <Helmet>
                <title> WhatsApp Launch Manager | Builderall</title>
            </Helmet>
            {campaignAmount === 0 ? <div>
                <ContentHeader title={t("words.your_campaigns")} subTitle={t("words.welcome_to_launch", {app})}/>

                <WelcomeBanner/>

                <Content height={"40rem"}>
                    <Backgroud text={t("words.primary_message")} title={t("words.create_campaigns")} img={back}
                               icon={megaphone}>
                        {window.innerWidth > 600 && !isMobile() && <CampaignsStepByStep title={t("words.campaigns")}
                                                                                        buttonLabel={
                                                                                            <span><AddCircleIcon
                                                                                                className="addIcon"/> {t("words.create_camp")}</span>}/>}
                    </Backgroud>
                </Content>
            </div> : <div>
                <ContentHeader title={t("words.welcome_to_launch", {app})} subTitle={t("words.your_campaigns")}>
                </ContentHeader>

                <WelcomeBanner/>

                <Content height={"45rem"}>
                    <CampaignsCard id={id}>
                    </CampaignsCard>
                </Content>
            </div>
            }
        </Container>
    );
};

export default Dashboard;