import styled from "styled-components";

export const Container = styled.div`
    height: 43rem;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 6px;
`;

export const TimelineContainer = styled.div`
    height: 38rem;
    overflow: auto;
    
    > ul {
        margin-bottom: 0;
    }

    ::-webkit-scrollbar {
            width: 8px;
    }

    ::-webkit-scrollbar-track {
        padding-right: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #E3E3E3;
        border-radius: 5px;
    }

    .MuiTimeline-root {
        padding: 0 0%;
    }

    .MuiTimelineDot-root {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiTimelineDot-defaultGrey {
        background-color: rgba(190, 209, 234, .38);
        width: 55px;
        height: 55px;
        
        > svg {
            margin: 5px auto;
            font-size: 30px;
        }

        > div {
            > svg {
                margin: 5px auto;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }

    .MuiTimelineContent-root {
        text-align: left;
    }

    .MuiTimelineConnector-root {
        background-color: #BED1EA;
        height: 24px;
        width: 3px;
        opacity: .7;
        margin: -8px 0px;
    }

    
`;

export const NoMessages = styled.div`
    width: 100%;
    display: flex;
`;

export const LeftDiv = styled.div`
    width: 50%;
    margin-left: 8rem;

    > img {
        display: block;
        margin: 10rem auto;

        @media(max-width: 1600px){
            width: 90%;
        }

    }
`;

export const RightDiv = styled.div`
    width: 50%;
    margin-right: 8rem;
    margin-top: 14rem;

    > p {
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: left;
        font: 600 15px/22px Mulish;
        letter-spacing: 0px;
        color: #787878;
        opacity: 1;
    }

    .titleDiv{
        display: flex;
        height: fit-content;

        > h3 { 
            text-align: left;
            font: normal normal 800 25px/19px Mulish;
            letter-spacing: 0px;
            color: #434343;
            opacity: 1;
            margin-left: .9rem;
            margin-top: 0.3rem;
        }

        > img {
            width: 25px;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCCCCC;

    .Mui-disabled { 
        color: #FFFFFF;
        background-color: #434343;
    }
`;

export const Title = styled.h5`
    font: normal normal 800 1.1rem Mulish;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    padding-top: 11px;
    padding-right: 15px;

    @media(max-width: 800px){
        font-size: 1rem;
    }
`;

export const GroupDate = styled.h4`
    font-size: 0.8rem;
    font-weight: 600;
    color: rgba(110, 110, 110, .6);
`;

export const MsgTitle = styled.h4`
    font-size: 1rem;
    font-weight: bold;
    color: #6E6E6E;
    padding-top: 0.313rem;
    display:flex;
    > div {
        display: flex;
        
        max-width: 10rem;
        max-height: 20rem;
        > p {
            overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        }
    }
    > svg {
        margin-left: 7px;
    }
`;

export const MsgContentText = styled.div`
    font-size: 15px;
    color: #6E6E6E;
    line-height: 20px;
    height: calc(3 * 20px);
    position: relative;
    max-width: 15rem;
    max-height: 50rem;
    overflow: hidden;
    display: inline-block;
    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 37px;
        width: 100%;
        background: linear-gradient(180deg, transparent, white);
    }
`;

export const MsgContentImg = styled.div`
    height: 150px;

    > img, video {
        padding: 12px;
        box-shadow: 0px 3px 4px #0000001F;
        border-radius: 5px;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
`;

export const DisabledBtn = styled.div`
    .Mui-disabled { 
        color: #FFFFFF;
        background-color: #434343;
    }
`;

export const MsgContentAudio = styled.div`
    > audio {
        @media(max-width: 900px){
            width: 100px;
        }
    } 
`;