import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-height: 40vh;
    padding: 5% 0;

    overflow: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
            width: 8px;
    }

    ::-webkit-scrollbar-track {
        padding-right: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #E3E3E3;
        border-radius: 5px;
    }

    > ul {
        margin-bottom: 0;
    }

    .MuiTimeline-root {
        padding: 0;
    }

    .MuiTimelineDot-root {
        box-shadow: none;
    }

    .MuiTimelineDot-defaultGrey {
        background-color: rgba(190, 209, 234, .38);
        width: 55px;
        height: 55px;
    
        > img {
            width: 70%;
            margin: 0 auto;
        }

        > svg {
            color: #0F7BFF;
            margin: 7px auto;
            font-size: 30px;
        }
        
    }

    .MuiTimelineContent-root {
        text-align: left;
    }

    .MuiTimelineConnector-root {
        background-color: #BED1EA;
        height: 24px;
        width: 3px;
        opacity: .7;
        margin-top: -5px;
    }
`;

export const LogText = styled.h4`
    font-size: 15px;
    font-weight: 600;
    color: #6E6E6E;
    padding-top: 20px;
`;