import {makeStyles, withStyles} from "@material-ui/core/";
import {Tooltip} from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles({
    root: {
        background: "linear-gradient(45deg, #0294FF 50%, #0294FF  90%)",
        borderRadius: 5,
        border: 0,
        color: "white",
        height: 35,
        padding: "0px 30px",
        boxShadow: "0 3px 5px 2px rgba(25, 28, 26, .3)",
    },
    label: {},

});


export const progressBar = makeStyles({
    root: {
        width: "100%",
        color: "red",
    },
});

export const NotiFy = makeStyles({
    root: {
        background: "linear-gradient(45deg, #EAF4FF 50%, #EAF4FF  90%)",
        borderRadius: 5,
        border: "1px solid #ccc",
        color: "#000",
        height: 50,
        padding: "0px 30px",
        /* boxShadow: '0 3px 5px 2px rgba(25, 28, 26, .3)', */
    },
    label: {},

});

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#F3F7FF",
        color: "#505050",
        boxShadow: theme.shadows[1],
        fontSize: ".9rem",
    },
}))(Tooltip);

export const NavIcon = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: #0F7BFF  ;
    &:hover{
        color: #1EA2F7 ;
    }
`;