import React from "react";
import {useTranslation} from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import DateFormat from "../DateFormat";
import "./style.css";
import {
    Container,
    TitleContainer,
    Controllers,
    SmallTitle,
    Line,
    LineContent,
    SmallNumber,
    SmallStatus,
    TitleH1,
    useStyles,
} from "./styles";

interface IContentHeaderProps {
    title: string;
    subTitle: string;
    children: React.ReactNode;
    num: String;
    status: Boolean;
    origin: String;
    image: String;
    date: String;
}

const ContentHeader: React.FC<IContentHeaderProps> = ({
                                                          title,
                                                          subTitle,
                                                          children,
                                                          num,
                                                          status,
                                                          origin,
                                                          image,
                                                          date,
                                                      }) => {
    /* const options = [
        { value: 'Matheus', label: 'matheus',}] */
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <Container>
            <TitleContainer>
                <div style={{display: "flex", paddingBottom: "10px"}}>
                    {!image ? <></> : <div style={{paddingRight: "15px"}}><Avatar alt={title} src={`${image}`}
                                                                                  className={classes.large}/></div>}
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                    }}><TitleH1>{title}</TitleH1></div>
                </div>
                <div><SmallTitle>{subTitle} <DateFormat date3L={false} date={date}/> </SmallTitle></div>
                {origin === "campaigns" &&
                    <Line><LineContent style={{marginRight: "15px"}}><SmallNumber>{num}</SmallNumber></LineContent>
                        <LineContent style={{
                            background: status !== true ? "#ebdde5" : "#d2e9e8",
                            justifyContent: "center",
                        }}><SmallStatus
                            style={{color: status !== true ? "#FA4B57" : "#00C38E"}}>{status !== true ? t("words.disconnected") : t("words.conected")}</SmallStatus></LineContent></Line>}
            </TitleContainer>
            <Controllers>
                <div>{children}</div>
            </Controllers>
        </Container>
    );
};

export default ContentHeader;