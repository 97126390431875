import styled from "styled-components";

export const Container = styled.div`
  font-family: 'Mulish', sans-serif;
`;

export const BannerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent linear-gradient(180deg, #5E81F4 0%, #1665D8 100%) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  letter-spacing: 0;
  color: #fff;
  opacity: 1;
  padding: 0 35px;
`;

export const Buttons = styled.div`
  font-family: 'Mulish', sans-serif;
  align-items: center;
  display: flex;
  align-content: center;
`;

export const Button1 = styled.button`
  width: 200px;
  height: 35px;
  border-radius: 8px;
  background: #FFFFFF40 0% 0% no-repeat padding-box;
  color: #FFFFFF;;
  font-size: 14px;

  margin-right: 20px;

  transition: ease .3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const Button2 = styled.button`
  width: 150px;
  height: 35px;
  border-radius: 8px;
  background: #ffffff40 0 0 no-repeat padding-box;
  color: #fff;
  font-size: 14px;

  transition: ease .3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const LeftDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0;

  > h5 {
    font-weight: 600;
    color: #FFFFFF;
  }

  > p {
    font-size: 0.9rem;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }

  > small {
    color: #FFFFFF;
  }
`;

export const RightDiv = styled.div`
  width: 40%;
  display: flex;
  align-items: end;
  padding-top: 25px;

  svg {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const BannerContent = styled.div`
  margin-bottom: 35px;
`;