import React from "react";


export const ChatContext = React.createContext({});

export const ChatProvider = (props) => {

    const [chat, setChat] = React.useState({
        sectorName: "",
        sectorID: Number(localStorage.getItem("sectorActive")),
        contacID: null,
        operatorId: null,
        operator: null,
        phoneNumber: null,
        operatorToken: null,

    });
    const [listSectors, setListSectors] = React.useState([]);

    const [chatOpen, setChatOpen] = React.useState(false);


    return (
        <ChatContext.Provider value={{chat, setChat, chatOpen, setChatOpen, listSectors, setListSectors}}>
            {props.children}
        </ChatContext.Provider>
    );
};

export const useChat = () => React.useContext(ChatContext);