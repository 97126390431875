import React from "react";
import {ButtonBlue} from "../../../Styles/buttons";
import {useTranslation} from "react-i18next";

export const InstallButton = ({deferredPrompt, afterInstall, origen}) => {

    const {t} = useTranslation();

    const installButton = () => {
        deferredPrompt.prompt();

        deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    /*  console.log('O usuário aceitou instalar o PWA.'); */
                } else {
                    /* console.log('O usuário recusou a instalação do PWA.'); */
                }
                deferredPrompt = null;
                afterInstall();
            });
    };

    return (
        <div>
            {origen === "menu" ? <div onClick={installButton}>{t("words.install_app")}</div> :
                <ButtonBlue onClick={installButton}>{t("words.install")}</ButtonBlue>}
        </div>
    );
};

