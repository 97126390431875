import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {ReactComponent as MsgSentIcon} from "../../../Assets/icon/Message Sent.svg";
import {ReactComponent as MsgFailedIcon} from "../../../Assets/icon/Sending Error.svg";
import GroupIcon from "@material-ui/icons/Group";
import {ReactComponent as MsgPendingIcon} from "../../../Assets/icon/Programmed Message.svg";
import {useTranslation} from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import {
    Container,
} from "./styles";
import {getMessageDetail} from "../../../Services/campaignFunctions";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const MessageLogs = ({message}) => {

    const {t} = useTranslation();

    const getMsgDetail = async () => {
        const response = await getMessageDetail(message?.campaign_id, message?.id);
        setLogList(response);
    };

    const [logList, setLogList] = React.useState([]);

    const [open2, setOpen2] = React.useState(false);

    const handleClick2 = () => {
        setOpen2(!open2);
    };
    const [open3, setOpen3] = React.useState(false);

    const handleClick3 = () => {
        setOpen3(!open3);
    };

    const [open4, setOpen4] = React.useState(false);

    const handleClick4 = () => {
        setOpen4(!open4);
    };

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        getMsgDetail();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const {completed, pending, failed} = React.useMemo(() => {
        return logList?.groups?.reduce((a, m) => ({
            ...a,
            [m.status]: [...a[m.status] || [], m],
        }), {}) || {};
    }, [logList]);

    const status = React.useMemo(() => {
        if (failed?.length > 0) {
            return "failed";
        }
        if (pending?.length > 0) {
            return "pending";
        }
        return logList?.status;
    }, [logList, pending, failed]);


    return (
        <Container>
            <div aria-describedby={id} onClick={handleClick} style={{paddingLeft: "10px", cursor: "pointer"}}>
                {message?.status === "pending" ? <MsgPendingIcon/> : <InfoIcon style={{color: "#0294FF"}}/>}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Typography className={classes.typography}> <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {t("words.status_send_message")}
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {completed?.length > 0 &&
                        <>
                            <ListItem button onClick={handleClick2}>
                                <ListItemIcon>
                                    <MsgSentIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t("words.sent")}/>
                                {open2 ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={open2} timeout="auto" unmountOnExit>
                                {completed.map((g, i) => (
                                    <List key={i} component="div" disablePadding>
                                        <ListItem button className={classes.nested}>
                                            <ListItemIcon>
                                                <GroupIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={g.group.name}/>
                                        </ListItem>
                                    </List>
                                ))}

                            </Collapse>
                        </>
                    }

                    {pending?.length > 0 && <>
                        <ListItem button onClick={handleClick4}>
                            <ListItemIcon>
                                <MsgPendingIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("words.shipping_pending")}/>
                            {open4 ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={open4} timeout="auto" unmountOnExit>
                            {pending.map((g, i) => (
                                <List key={i} component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <GroupIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={g.group.name}/>
                                    </ListItem>
                                </List>
                            ))}

                        </Collapse>
                    </>}

                    {failed?.length > 0 && <>
                        <ListItem button onClick={handleClick3}>
                            <ListItemIcon>
                                <MsgFailedIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("words.shipping_failure")}/>
                            {open3 ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={open3} timeout="auto" unmountOnExit>
                            {failed.map((g, i) => (
                                <List key={i} component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <GroupIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={g.group.name}/>
                                    </ListItem>
                                </List>
                            ))}
                        </Collapse>
                    </>}
                </List>
                </Typography>
            </Popover>
        </Container>
    );
};

export default MessageLogs;