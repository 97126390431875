import React from "react";
import {XAxis, Tooltip, YAxis, BarChart, Bar, ResponsiveContainer} from "recharts";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import axios_base from "../../../axios_base";
import moment from "moment";
import {useTranslation} from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Divider from "@material-ui/core/Divider";
import {InputGroup, InputGroupText, Input, Button} from "reactstrap";
import {BsSearch} from "react-icons/bs";
import CampaignsStepByStep from "../../../Launch/Components/CampaignsStepByStep";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArchivedCampaignImg from "../../../Assets/CommunIcons/archived campaign.svg";
import ArchivedIcon from "../../../Assets/CommunIcons/Archived.svg";
import Avatar from "@material-ui/core/Avatar";
import Options from "../../../Assets/icon/Campaign - Options.svg";
import {Modal} from "reactstrap";

import {
    Container,
    Title,
    Graphic,
    Card,
    useStyles,
    TabArea,
    SearchAddButtons,
    SearchInput,
    BtnAdd,
    NoArchivedCampaign,
    SubTitle,
    H2,
    Name,
    CampaingOptionsButton,
    TitleCampaigH2,
    OptionsButton,
    ModalDuplicate,
    LabelDate,
    OptionsMenu,
} from "./styles";
import Backgroud from "../../../Common/Components/Backgroud";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Menu, MenuItem} from "@material-ui/core";

const CampaignsCard = ({id}) => {

    const [search, setSearch] = React.useState("");

    const {t} = useTranslation();
    const classes = useStyles();
    var language = navigator.language.substring(0, 2);
    const [listCampaing, setCampaing] = React.useState([]);
    const [campaignDuplicateId, setCampaignDuplicateId] = React.useState(null);
    const [campaignDuplicateDate, setCampaignDuplicateDate] = React.useState("");
    const getCampaings = () => {
        axios_base.get("/campaign")
            .then(res => {
                setCampaing(res.data);
            });
    };
    React.useEffect(() => {
        getCampaings();
    }, []);

    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    var dateFormat = null;
    if (language === "pt") {
        dateFormat = "DD/MM";
    } else {
        dateFormat = "MM/D";
    }
    var windowWidth = window.innerWidth;

    const [modal, setModal] = React.useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    const handleClickDuplicateId = (id) => {
        setCampaignDuplicateId(id);
        setModal(true);
    };

    const handleClickDuplicate = () => {
        if (campaignDuplicateId && campaignDuplicateDate) {
            const formattedDate = new Date(campaignDuplicateDate).toISOString();
            axios_base.patch(`/campaign/${campaignDuplicateId}/duplicate`, {StartAt: formattedDate})
                .then(res => {
                    setModal(false);
                    getCampaings();
                })
                .catch(err => {
                    console.log("error", err);
                });
        }
    };

    return (
        <Container style={{height: listCampaing.length <= 3 ? "900px" : "1308px"}}>
            <div className={classes.root}>
                <div className={classes.title}>
                    <h5 className={classes.title_h5}>{t("words.your_campaigns")}</h5>
                </div>
                <Divider className="divider"/>
                <TabContext value={value}>
                    <AppBar position="static" className={classes.AppBarStyle}>
                        <TabList TabIndicatorProps={{style: {background: "#0080FC"}}} onChange={handleChange}
                                 aria-label="Campaings" className={classes.SelectedTab} style={{color: "red~["}}>
                            <Tab label={t("words.active")} value="1" className={classes.TabFont}/>
                            <Tab label={t("words.archived")} value="2" className={classes.TabFont}/>
                        </TabList>
                    </AppBar>
                    <Divider className="divider"/>
                    <SearchAddButtons>
                        <SubTitle>
                            {t("words.selec_camp")}
                        </SubTitle>
                        {windowWidth > 600 &&
                            <div style={{display: "flex"}}>
                                <SearchInput>
                                    <InputGroup style={{paddingRight: "15px", flex: "1", width: "170px"}}>
                                        <InputGroupText><BsSearch/></InputGroupText>
                                        <Input className="form-input" onChange={e => setSearch(e.target.value)}
                                               value={search} id="input-table" placeholder={t("words.search")}/>
                                    </InputGroup>
                                </SearchInput>
                                <BtnAdd>
                                    <CampaignsStepByStep title={t("words.campaigns")} buttonLabel={<span><AddCircleIcon
                                        className="addIcon"/> {t("words.create_camp")}</span>}/>
                                </BtnAdd>
                            </div>
                        }
                    </SearchAddButtons>
                    <TabArea>
                        <TabPanel value="1" className={classes.TabPanelStyle}>
                            <Grid container spacing={3}>
                                {listCampaing.filter((campaign) => {
                                    if (id) {
                                        if (campaign.number_id.toString().includes(id.toString())) {
                                            return campaign;
                                        }
                                    } else {
                                        if (search === "") {
                                            return campaign;
                                        } else if (campaign.name.toLowerCase().includes(search.toLowerCase())) {
                                            return campaign;
                                        } else {
                                            return null;
                                        }
                                    }
                                }).map((num) => (!num.archived &&
                                    <Grid item xs={windowWidth <= 1200 ? 6 : 4} key={num.id}
                                          className={classes.container}>
                                        <Card>
                                            <Title>
                                                <Link to={`/campaign/${num.id}/detail`}
                                                      style={{textDecoration: "none"}}>
                                                    <Name>
                                                        <div style={{width: "20%", paddingRight: "10px"}}>
                                                            <Avatar alt="group_img"
                                                                    src={`${process.env.REACT_APP_LINK_API}/storage/${num.group_image}`}/>
                                                        </div>
                                                        <TitleCampaigH2>{num.name.toUpperCase()}</TitleCampaigH2>
                                                    </Name>
                                                </Link>
                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <CampaingOptionsButton
                                                                variant="contained" {...bindTrigger(popupState)}>
                                                                <img src={Options} alt="Campaign options"/>
                                                            </CampaingOptionsButton>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <Link to={`/campaign/${num.id}/detail`}
                                                                      style={{textDecoration: "none", color: "#000"}}>
                                                                    <OptionsMenu>
                                                                        <MenuItem onClick={popupState.close}>
                                                                            <OptionsButton>
                                                                                {t("words.view")}
                                                                            </OptionsButton>
                                                                        </MenuItem>
                                                                    </OptionsMenu>
                                                                </Link>
                                                                <OptionsMenu>
                                                                    <MenuItem onClick={popupState.close}
                                                                              className="menuItem">
                                                                        <OptionsButton
                                                                            onClick={() => handleClickDuplicateId(num.id)}>
                                                                            {t("words.duplicate")}
                                                                        </OptionsButton>
                                                                    </MenuItem>
                                                                </OptionsMenu>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </Title>
                                            <Graphic>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        width={600}
                                                        height={300}
                                                        data={num?.statistics.map(statistics => ({
                                                            name: moment().subtract(statistics.index, "days").format(dateFormat),
                                                            entradas: statistics.count,
                                                        })).reverse()}
                                                        margin={{
                                                            top: 5,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <XAxis dataKey="name" padding={{left: 10, right: 10}}
                                                               minTickGap={20}/>
                                                        <YAxis hide="true"/>
                                                        <Tooltip/>
                                                        <Bar dataKey="entradas" fill="#24A3FA " stroke="#24A3FA "
                                                             barSize={15} radius={[4, 4, 0, 0]}
                                                        />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </Graphic>
                                        </Card>
                                        <Modal isOpen={modal} toggle={toggle} style={{paddingTop: "6rem"}}>
                                            <ModalDuplicate>
                                                <div className="containerModal">
                                                    <h6>{t("words.campaign_copy")}</h6>
                                                    <p>{t("words.campaign_copy_description")}</p>
                                                    <div className="contentInputDate">
                                                        <LabelDate>{t("words.date")}</LabelDate>
                                                        <Input
                                                            onChange={(e) => setCampaignDuplicateDate(e.target.value)}
                                                            value={campaignDuplicateDate} type="date" name="date"
                                                            required/>
                                                    </div>
                                                    <div className="contentButtons">
                                                        <Button className="buttonCancel"
                                                                onClick={toggle}>{t("words.cancel")}</Button>
                                                        <Button onClick={handleClickDuplicate}
                                                                className="buttonConfirm">{t("words.confirm")}</Button>
                                                    </div>
                                                </div>
                                            </ModalDuplicate>
                                        </Modal>
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2" className={classes.TabPanelStyle}>
                            <Grid container spacing={3}>
                                {listCampaing.filter((campaign) => {
                                    if (search === "") {
                                        return campaign;
                                    } else if (campaign.name.toLowerCase().includes(search.toLowerCase())) {
                                        return campaign;
                                    } else {
                                        return null;
                                    }
                                }).map((num) => (num.archived &&
                                    <Grid item xs={windowWidth <= 1200 ? 6 : 4} key={num.id}
                                          className={classes.container}>
                                        <Link to={`/campaign/${num.id}/detail`} style={{textDecoration: "none"}}>
                                            <Card>
                                                <Title>
                                                    <Name>
                                                        <div style={{width: "20%", paddingRight: "10px"}}>
                                                            <Avatar alt="group_img"
                                                                    src={`${process.env.REACT_APP_LINK_API}/storage/${num.group_image}`}/>
                                                        </div>
                                                        <H2>{num.name.toUpperCase()}</H2>
                                                    </Name>
                                                    <div style={{flex: "1", paddingLeft: "5px"}}>
                                                        <H2>{t("words.view")}</H2></div>
                                                </Title>
                                                <Graphic>
                                                    <ResponsiveContainer width="100%" height="100%">
                                                        <BarChart
                                                            width={600}
                                                            height={300}
                                                            data={num?.statistics.map(statistics => ({
                                                                name: moment().subtract(statistics.index, "days").format(dateFormat),
                                                                entradas: statistics.count,
                                                            })).reverse()}
                                                            margin={{
                                                                top: 5,
                                                                right: 30,
                                                                left: 20,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <XAxis dataKey="name" padding={{left: 10, right: 10}}/>
                                                            <YAxis hide="true"/>
                                                            <Tooltip/>
                                                            <Bar dataKey="entradas" fill="#24A3FA " stroke="#24A3FA "
                                                                 barSize={15} radius={[4, 4, 0, 0]}/>
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </Graphic>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ))}
                                {!listCampaing.find(c => c.archived) &&
                                    <NoArchivedCampaign>
                                        <Backgroud title={t("words.no_archived_camp")}
                                                   text={t("words.your_archived_camp")} img={ArchivedCampaignImg}
                                                   icon={ArchivedIcon}>

                                        </Backgroud>
                                    </NoArchivedCampaign>
                                }
                            </Grid>
                        </TabPanel>
                    </TabArea>
                </TabContext>
            </div>
        </Container>
    );
};

export default CampaignsCard;
