import styled from "styled-components";
import {makeStyles, withStyles} from "@material-ui/core/styles";

export const Container = styled.div`
  
`;
export const useStyles = makeStyles({
    list: {},
    fullList: {
        width: "auto",
        background: "#ffffff",
        boxShadow: "0px 10px 16px #D4D4D466",
        borderRadius: "20px 20px 0px 0px",
    },

});

export const GlobalCss = withStyles({

    "@global": {
        //estiliza a div aplicada no DOM pelo MUI
        ".MuiDrawer-paperAnchorBottom": {
            borderRadius: "20px 20px 0px 0px",
        },
    },
})(() => null);

export const TopDiv = styled.div`
  /*   width: 100%; */
    text-align: "center";
    font: normal normal 600 1.5rem Mulish;
    letter-spacing: 0px;
    color: #0F7BFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3% 0;
`;