import {ReactComponent as TextMsgIcon} from "../../../Assets/attendance/MessageActive.svg";
import {ReactComponent as ImgMsgIcon} from "../../../Assets/attendance/Image type  Active - Hover.svg";
import {ReactComponent as VideoMsgIcon} from "../../../Assets/attendance/VideoActive.svg";
import {ReactComponent as AudioMsgIcon} from "../../../Assets/attendance/TyperecordActive.svg";
import {getCampaign, getMessageList} from "../../../Services/campaignFunctions";
import SequenceImg from "../../../Assets/icon/Sent - Message Send Sequence.svg";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import NoMsgImg from "../../../Assets/icon/Message Send Sequence.svg";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {ButtonBlue} from "../../../Styles/buttons";
import Timeline from "@material-ui/lab/Timeline";
import {useTranslation} from "react-i18next";
import MessageModal from "../MessageModal";
import exclamation_circle from "../../../Assets/CommunIcons/exclamation-circle.svg";
import {useCommun} from "../../../Providers/commun";
import React from "react";
import {LightTooltip} from "../../../Styles/styles";
import DateFormat from "../../../Common/Components/DateFormat";
import {
    Container,
    DisabledBtn,
    GroupDate,
    Header,
    LeftDiv,
    MsgContentAudio,
    MsgContentImg,
    MsgContentText,
    MsgTitle,
    NoMessages,
    RightDiv,
    TimelineContainer,
    Title,
} from "./styles";
import MessageLogs from "../MessageLogs";

const STEP = 10;

const MessageTimeline = ({campaignID, campaign}) => {
    const {isMobileState} = useCommun();
    const {t} = useTranslation();

    const [group, setGroup] = React.useState([]);
    const [message, setMessage] = React.useState([]);
    const [limit, setLimit] = React.useState(STEP);

    const getGroups = async (campaignID) => {
        const groups = await getCampaign(campaignID);
        setGroup(groups);
    };

    const getMessages = async (campaignID) => {
        const msgs = await getMessageList(campaignID);
        setMessage(msgs);
    };

    const onScrollTimeline = e => {
        if (limit >= message?.length) return;

        const {scrollTop, clientHeight, scrollHeight} = e.target;

        if (scrollTop + clientHeight >= scrollHeight * .8) {
            setLimit(limit => limit + STEP);
        }
    };

    React.useEffect(() => {
        getGroups(campaignID);
        getMessages(campaignID);
    }, [campaignID]);

    return (
        <Container>
            {message?.length === 0 || !message ?
                <NoMessages>
                    <LeftDiv>
                        <img src={NoMsgImg} alt="no messages"/>
                    </LeftDiv>
                    <RightDiv>
                        <div className="titleDiv">
                            <img src={SequenceImg} alt="sequence icon"/>
                            <h3>{t("words.msg_send_sequence")}</h3>
                        </div>
                        <p>{t("words.no_msgs")}</p>
                        {campaign.archived ?
                            <DisabledBtn>
                                <ButtonBlue disabled>
                                    <AddCircleIcon style={{paddingRight: "5px"}}/>
                                    {t("words.create_msg")}
                                </ButtonBlue>
                            </DisabledBtn>
                            :
                            <MessageModal
                                buttonLabel={
                                    <ButtonBlue>
                                        {isMobileState ?
                                            <AddCircleIcon style={{paddingRight: "5px"}}/> :
                                            <>
                                                <AddCircleIcon style={{paddingRight: "5px"}}/>
                                                {t("words.create_msg")}
                                            </>
                                        }
                                    </ButtonBlue>
                                }
                                getMessages={getMessages}
                                campaignID={campaignID}
                            />
                        }

                    </RightDiv>
                </NoMessages>
                :
                <>
                    <Header>
                        <div style={{display: "flex"}}>
                            <Title>{isMobileState ? t("words.message") : t("words.msg_send_sequence")}</Title>
                            <LightTooltip title={t("words.msg_sequence_description")} placement="top">
                                <img alt="info" src={exclamation_circle} width="21px" style={{cursor: "pointer"}}/>
                            </LightTooltip>
                        </div>
                        {campaign.archived ?
                            <ButtonBlue disabled>
                                {isMobileState ?
                                    <AddCircleIcon style={{paddingRight: "5px"}}/> :
                                    <>
                                        <AddCircleIcon style={{paddingRight: "5px"}}/>
                                        {t("words.create_msg")}
                                    </>
                                }
                            </ButtonBlue>
                            :
                            <MessageModal
                                buttonLabel={
                                    <ButtonBlue>
                                        {isMobileState ?
                                            <AddCircleIcon style={{paddingRight: "5px"}}/> :
                                            <>
                                                <AddCircleIcon style={{paddingRight: "5px"}}/>
                                                {t("words.create_msg")}
                                            </>
                                        }
                                    </ButtonBlue>
                                }
                                campaignID={campaignID}
                                getMessages={getMessages}
                            />
                        }
                    </Header>
                    <TimelineContainer onScroll={onScrollTimeline}>

                        <Timeline align="alternate">
                            {message?.slice(0, limit).map((m, i) => (
                                <TimelineItem key={m?.id}>
                                    <TimelineSeparator>
                                        <TimelineDot>
                                            {m?.type === "text" &&
                                                <MessageModal buttonLabel={<TextMsgIcon/>} messageID={m?.id}
                                                              campaignID={campaignID} origin={"timeline"}
                                                              getMessages={getMessages} groups={m.groups}/>}
                                            {m?.type === "image" &&
                                                <MessageModal buttonLabel={<ImgMsgIcon/>} messageID={m?.id}
                                                              campaignID={campaignID} origin={"timeline"}
                                                              getMessages={getMessages} groups={m.groups}/>}
                                            {m?.type === "video" &&
                                                <MessageModal buttonLabel={<VideoMsgIcon/>} messageID={m?.id}
                                                              campaignID={campaignID} origin={"timeline"}
                                                              getMessages={getMessages} groups={m.groups}/>}
                                            {m?.type === "audio" &&
                                                <MessageModal buttonLabel={<AudioMsgIcon/>} messageID={m?.id}
                                                              campaignID={campaignID} origin={"timeline"}
                                                              getMessages={getMessages} groups={m.groups}/>}
                                        </TimelineDot>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent align="alternate">
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: i % 2 === 0 ? "flex-start" : "flex-end",
                                        }}>
                                            <MsgTitle>
                                                <div><p>{m?.name}</p></div>
                                            </MsgTitle>
                                            <MsgTitle>
                                                <MessageLogs message={m}/>
                                            </MsgTitle>
                                        </div>
                                        <GroupDate><DateFormat date={m?.send_at} date3L={true}/></GroupDate>
                                        {m?.type === "text" && <MsgContentText
                                            style={{textAlign: i % 2 === 0 ? "left" : "right"}}>{m?.content}</MsgContentText>}
                                        {m?.type === "image" && <MsgContentImg><img alt="img"
                                                                                    src={`${process.env.REACT_APP_LINK_API}/storage/${m?.content}`}/></MsgContentImg>}
                                        {m?.type === "video" && <MsgContentImg>
                                            <video controls
                                                   src={`${process.env.REACT_APP_LINK_API}/storage/${m?.content}`}/>
                                        </MsgContentImg>}
                                        {m?.type === "audio" && <MsgContentAudio>
                                            <audio controls
                                                   src={`${process.env.REACT_APP_LINK_API}/storage/${m?.content}`}/>
                                        </MsgContentAudio>}
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>

                        <Timeline align="alternate">
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot style={{backgroundColor: "#0F7BFF"}}>
                                        <PlayCircleOutlineIcon style={{color: "#FFFFFF"}}/>
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <MsgTitle>{group?.name}</MsgTitle>
                                    <GroupDate>{t("words.created")} <DateFormat date={group?.created_at} date3L={true}/>
                                    </GroupDate>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </TimelineContainer>
                </>
            }
        </Container>
    );
};

export default MessageTimeline;