import React from "react";
import {Btn, Container, UseStyle} from "./styles";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import Popper from "@material-ui/core/Popper";
import CancelIcon from "@material-ui/icons/Cancel";
import EmojiPicker from "emoji-picker-react";


const MessageEmoji = ({onChange}) => {

    const [anchorElPopperEmoji, setAnchorElPopperEmoji] = React.useState(null);

    const classes = UseStyle();

    const openEmoji = Boolean(anchorElPopperEmoji);
    const idEmoji = openEmoji ? "simple-popper" : undefined;

    const handlePopperEmoji = (event) => {
        setAnchorElPopperEmoji(anchorElPopperEmoji ? null : event.currentTarget);
    };

    const handleEmojiClick = (e, emojiObject) => {
        const txt = document.querySelector("#text_mensagem");
        const first = txt.value.substring(0, txt.selectionStart);
        const last = txt.value.substring(txt.selectionStart);
        const content = emojiObject.emoji;
        const value = first + content + last;
        document.getElementById("text_mensagem").value = value;
        if (onChange) {
            onChange(value);
        }
        txt.focus();
    };


    return (
        <>
            <Container>
                <Btn>
                    <InsertEmoticonIcon onClick={handlePopperEmoji}/>
                </Btn>
            </Container>

            <Popper id={idEmoji} open={openEmoji} anchorEl={anchorElPopperEmoji} onClose={handlePopperEmoji}
                    className={classes.popper}>
                <div style={{margin: "10px"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <CancelIcon fontSize="small" style={{cursor: "pointer", color: "#F54B5E"}}
                                    onClick={handlePopperEmoji}/>
                    </div>
                    <hr style={{color: "#CEDDF0"}}></hr>
                    <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                        disableSearchBar
                        disableSkinTonePicker
                    />
                </div>
            </Popper>
        </>
    );
};

export default MessageEmoji;